import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, TextField, Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ButtonWithCircularProgress from 'components/ButtonWithCircularProgress';
import { useResetPasswordMutation } from 'generated/graphql';
import { LoggedInContext } from 'context';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    card: {
        width: 400
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const PasswordReset = () => {
    const classes = useStyles();
    const { dispatch } = useContext(LoggedInContext);
    const history = useHistory();
    const { token } = useParams();
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [resetPassword, { loading }] = useResetPasswordMutation({
        onCompleted: () => {
            dispatch({ type: "login" });
            history.push('/');
        },
        onError: () => {
            history.push('/');
        }
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        resetPassword({ variables: { input: { token: token, password: password } } });
    };

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5">Changer mon mot de passe</Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField fullWidth label="Nouveau mot de passe" name="password" type="password"
                            value={password} onChange={e => setPassword(e.target.value)}
                            error={password.length < 8} helperText={password.length < 8 ? "Le mot de passe doit faire minimum 8 caractères." : null} />
                        <TextField fullWidth label="Confirmer le mot de passe" name="passwordConfirm" type="password"
                            value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)}
                            error={password !== passwordConfirm} helperText={password !== passwordConfirm ? "Le mot de passe ne correspond pas." : null} />
                        <Box marginTop={2}>
                            <ButtonWithCircularProgress type="submit" loading={loading} disabled={password === "" || passwordConfirm === "" || loading || password.length < 8 || password !== passwordConfirm}
                                label="Changer mon mot de passe" fullWidth />
                        </Box>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default PasswordReset;