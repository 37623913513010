import React from 'react';
import { Typography } from '@material-ui/core';
import { TrainingTrainerRoute, Maybe, LeaguePrices } from 'generated/graphql';
import { TrainingFormData } from '.';

type Props = {
    prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm'>>
    trainingResponse: TrainingFormData
    trainingTrainerRoute: Pick<TrainingTrainerRoute, "id" | "from" | "to">
}
const KmFees = ({ prices, trainingResponse, trainingTrainerRoute }: Props) => {

    let kmFees = 0;
    const pricePerKm = (prices?.pricePerKm || 0) / 1000;

    if (trainingResponse.accepted && trainingResponse.trainingDates.filter(d => d.accepted).length !== 0 && !trainingResponse.noKmsFees) {
        if (trainingResponse.freeHosting) {
            kmFees += pricePerKm * trainingTrainerRoute.to;
            kmFees += pricePerKm * trainingTrainerRoute.from;
        } else {
            let atHome = true;

            if (trainingResponse.eveHotel) {
                kmFees += pricePerKm * trainingTrainerRoute.to;
                atHome = false;
            }

            let eveHotel = false
            trainingResponse.trainingDates.forEach(trainingDate => {
                if (trainingDate.accepted) {
                    if (atHome) {
                        kmFees += pricePerKm * trainingTrainerRoute.to;
                        atHome = false;
                    }

                    if (!trainingDate.hotel) {
                        atHome = true;
                        kmFees += pricePerKm * trainingTrainerRoute.from;
                    } else {
                        eveHotel = true;
                    }
                } else {
                    if (!atHome && eveHotel) {
                        eveHotel = false;
                        atHome = true;
                        kmFees += pricePerKm * trainingTrainerRoute.from;
                    }
                }
            });

            if (!atHome)
                kmFees += pricePerKm * trainingTrainerRoute.from;
        }
    }

    return (
        <Typography variant="body1">
            {`Estimation frais kilométrique: ${kmFees.toFixed(2)}€`}
        </Typography>
    );
};

export default KmFees;