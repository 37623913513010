import React, { useContext } from 'react';
import { Container, Box, ButtonBase, makeStyles, Typography, fade, Link } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SportsIcon from '@material-ui/icons/Sports';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import { UserContext } from 'context';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    button: {
        [theme.breakpoints.down('sm')]: {
            width: '120px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '150px',
        },
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3),
        marginBottom: theme.spacing(4),
        background: 'white',
        boxShadow: theme.shadows[1],
        borderRadius: 4,
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
            '&$disabled': {
                backgroundColor: 'transparent',
            },
        },
    }
}));

const Home = () => {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width:600px)');
    const user = useContext(UserContext);

    return (
        <Container>
            <Box marginY={4} display="flex" flexDirection={mobile ? "column" : "row"} justifyContent="space-evenly">
                {(user.admin || user.permissions.includes('match_read')) &&
                    <Box display="flex" justifyContent="space-evenly" flexDirection={mobile ? "row" : "column"}>
                        <Link color="textPrimary" component={RouterLink} underline="none" to="/matches">
                            <ButtonBase className={classes.button}>
                                <SportsIcon fontSize="large" />
                                <Typography variant={mobile ? "h6" : "h5"}>Matches</Typography>
                            </ButtonBase>
                        </Link>
                        <Link color="textPrimary" component={RouterLink} underline="none" to="/referees">
                            <ButtonBase className={classes.button}>
                                <PersonIcon fontSize="large" />
                                <Typography variant={mobile ? "h6" : "h5"}>Arbitres</Typography>
                            </ButtonBase>
                        </Link>
                    </Box>
                }
                {(user.admin || user.permissions.includes('training_read')) &&
                    <Box display="flex" justifyContent="space-evenly" flexDirection={mobile ? "row" : "column"}>
                        <Link color="textPrimary" component={RouterLink} underline="none" to="/trainings">
                            <ButtonBase className={classes.button}>
                                <SchoolIcon fontSize="large" />
                                <Typography variant={mobile ? "h6" : "h5"}>Formations</Typography>
                            </ButtonBase>
                        </Link>
                        <Link color="textPrimary" component={RouterLink} underline="none" to="/trainers">
                            <ButtonBase className={classes.button}>
                                <PersonIcon fontSize="large" />
                                <Typography variant={mobile ? "h6" : "h5"}>Formateurs</Typography>
                            </ButtonBase>
                        </Link>
                    </Box>
                }
                {(user.admin || user.permissions.includes('meeting_read')) &&
                    <Box display="flex" justifyContent="space-evenly" flexDirection={mobile ? "row" : "column"}>
                        <Link color="textPrimary" component={RouterLink} underline="none" to="/meetings">
                            <ButtonBase className={classes.button}>
                                <PeopleIcon fontSize="large" />
                                <Typography variant={mobile ? "h6" : "h5"}>Réunions</Typography>
                            </ButtonBase>
                        </Link>
                        <Link color="textPrimary" component={RouterLink} underline="none" to="/members">
                            <ButtonBase className={classes.button}>
                                <PersonIcon fontSize="large" />
                                <Typography variant={mobile ? "h6" : "h5"}>Membres</Typography>
                            </ButtonBase>
                        </Link>
                    </Box>
                }
            </Box>
        </Container>
    );
};

export default Home;