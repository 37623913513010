import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, TextField, Button, Link, Box } from '@material-ui/core';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useLoginMutation } from 'generated/graphql';
import { LoggedInContext } from 'context';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    card: {
        width: 400
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const Home = () => {
    const classes = useStyles();
    const { dispatch } = useContext(LoggedInContext);
    const history = useHistory();
    const [submit, setSubmit] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [login, { loading, error }] = useLoginMutation({
        onError: () => {},
        onCompleted: data => {
            dispatch({ type: "login" });
            history.push('/');
        }
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmit(true);
        login({ variables: { input: { email: email, password: password } } });
    };

    const hasErrorEmail = () => {
        return submit && error && (
            error.message.includes('not found')
        );
    };

    const getErrorEmail = () => {
        if (!submit) return null;
        if (error && error.message.includes('not found')) return 'Cette adresse mail n\'est pas enregistrée.';
        return null;
    };

    const hasErrorPassword = () => {
        return submit && error && error.message.includes('invalid password');
    };

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5">Ligue</Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField fullWidth label="Adresse mail" name="email" variant="standard"
                            value={email} onChange={e => { setSubmit(false); setEmail(e.target.value); }}
                            error={hasErrorEmail()} helperText={getErrorEmail()} />
                        <TextField fullWidth label="Mot de passe" name="password" type="password" variant="standard"
                            value={password} onChange={e => { setSubmit(false); setPassword(e.target.value); }}
                            error={hasErrorPassword()} helperText={hasErrorPassword() ? "Le mot de passe est incorrect." : null} />
                        <Box marginY={2}>
                            <Button fullWidth variant="contained" color="primary" disabled={email === "" || password === "" || loading} 
                                type="submit">Se connecter</Button>
                        </Box>
                    </form>
                    <Link align="right" color="secondary" component={RouterLink} to="/password/recovery" underline="always" variant="body2">
                        {'Mot de passe oublié?'}
                    </Link>
                </CardContent>
            </Card>
        </div>
    );
};

export default Home;