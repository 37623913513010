import React from 'react';
import { FormControlLabel, Switch, Checkbox, Collapse, TextField, Box } from '@material-ui/core';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { MeetingDate } from 'generated/graphql';
import { MeetingFormData } from '.';

type MeetingDateFormData = {
    meetingDateId: string
    accepted: boolean
    lunch: boolean
    dinner: boolean
    hotel: boolean
    informations: string
}

type Props = {
    meetingDate: Pick<MeetingDate, "id" | "date" | "lunch" | "startHour" | "hotel" | "endHour" | "dinner">
    meetingResponses: Array<MeetingFormData>
    setMeetingResponses: React.Dispatch<React.SetStateAction<MeetingFormData[]>>
    indexMeeting: number
    indexMeetingDate: number
}
const MeetingDateForm = ({ meetingDate, meetingResponses, setMeetingResponses, indexMeeting, indexMeetingDate }: Props) => {
    const meetingDateResponse = meetingResponses[indexMeeting].meetingDates[indexMeetingDate];

    const handleChangeChecked = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const lens = R.lensPath([indexMeeting, 'meetingDates', indexMeetingDate, name]);
        setMeetingResponses(R.set(lens, e.target.checked, meetingResponses));
    }

    const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const lens = R.lensPath([indexMeeting, 'meetingDates', indexMeetingDate, name]);
        setMeetingResponses(R.set(lens, e.target.value, meetingResponses));
    }

    return (
        <Box marginBottom={4}>
            <FormControlLabel
                control={
                    <Switch
                        checked={meetingDateResponse.accepted}
                        onChange={handleChangeChecked('accepted')}
                        color="primary"
                    />
                }
                label={DateTime.fromSQL(meetingDate.date).toLocaleString(DateTime.DATE_MED)}
            />
            <Collapse in={meetingDateResponse.accepted} timeout="auto" unmountOnExit>
                <Box paddingX={6}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={meetingDateResponse.lunch} disabled={!meetingDate.lunch}
                                onChange={handleChangeChecked('lunch')} />
                        }
                        label="Repas midi"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={meetingDateResponse.dinner} disabled={!meetingDate.dinner}
                                onChange={handleChangeChecked('dinner')} />
                        }
                        label="Repas soir"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={meetingDateResponse.hotel} disabled={!meetingDate.hotel}
                                onChange={handleChangeChecked('hotel')} />
                        }
                        label="Hotel"
                    />
                    <TextField fullWidth label="Informations pour ce jour uniquement" value={meetingDateResponse.informations} onChange={handleChange('informations')} />
                </Box>
            </Collapse>
        </Box>
    );
};

MeetingDateForm.whyDidYouRender = true

export default MeetingDateForm;
export type { MeetingDateFormData };