import React from 'react';
import { Card, Typography, Collapse, Box, FormControlLabel, Switch, TextField, Checkbox } from '@material-ui/core';
import TrainingDateForm, { TrainingDateFormData } from './TrainingDateForm';
import KmFees from './KmFees';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { TrainingTrainerRoute, TrainerRequestTraining, Training, Maybe, TrainingDate, TrainingDateResponse, TrainingResponse, LeaguePrices } from 'generated/graphql';
import AppEditor from 'components/Editor';

type TrainingFormData = {
    trainingId: string
    accepted: boolean
    eveHotel: boolean
    noKmsFees: boolean
    freeHosting: boolean
    informations: string
    trainingDates: Array<TrainingDateFormData>
}

type Props = {
    requestTraining: (
        Pick<TrainerRequestTraining, 'id'>
        & {
            training: (
                Pick<Training, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
                & { prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm'>>, trainingDates: Array<Pick<TrainingDate, 'date' | 'id' | 'lunch' | 'startHour' | 'hotel' | 'endHour' | 'dinner'>> }
            ), trainingTrainerRoute: Pick<TrainingTrainerRoute, 'id' | 'from' | 'to'>, lastResponse?: Maybe<(
                Pick<TrainingResponse, 'accepted' | 'createdAt' | 'eveHotel' | 'id' | 'informations'>
                & {
                    trainingDateResponses: Array<(
                        Pick<TrainingDateResponse, 'dinner' | 'hotel' | 'id' | 'informations' | 'lunch'>
                        & { trainingDate: Pick<TrainingDate, 'id'> }
                    )>
                }
            )>
        }
    )
    trainingResponses: Array<TrainingFormData>
    setTrainingResponses: React.Dispatch<React.SetStateAction<TrainingFormData[]>>
    indexTraining: number
    trainingTrainerRoute: Pick<TrainingTrainerRoute, 'id' | 'from' | 'to'>
}
const TrainingForm = ({ requestTraining, trainingResponses, setTrainingResponses, indexTraining, trainingTrainerRoute }: Props) => {
    const { training } = requestTraining;
    const trainingResponse = trainingResponses[indexTraining];

    const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const lens = R.lensPath([indexTraining, name]);
        setTrainingResponses(R.set(lens, e.target.value, trainingResponses));
    }

    const handleChangeChecked = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const lens = R.lensPath([indexTraining, name]);
        setTrainingResponses(R.set(lens, e.target.checked, trainingResponses));
    }

    return (
        <Box marginBottom={3}>
            <Card>
                <Box padding={2}>
                    <Typography variant="h6">
                        {training.title}
                    </Typography>
                    <Typography variant="body1">
                        {`Du ${DateTime.fromSQL(training.startDate).toLocaleString(DateTime.DATE_MED)} au ${DateTime.fromSQL(training.endDate).toLocaleString(DateTime.DATE_MED)}`}
                    </Typography>
                    <Typography variant="body1">
                        {`${training.address} ${training.zipCode} ${training.city}`}
                    </Typography>
                    <AppEditor initialValue={JSON.parse(training.informations)} readOnly />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={trainingResponse.accepted}
                                onChange={handleChangeChecked('accepted')}
                                color="primary"
                            />
                        }
                        label="Je suis disponible pour participer"
                    />
                    <Collapse in={trainingResponse.accepted} timeout="auto">
                        <Box paddingX={2}>
                            <KmFees prices={training.prices} trainingResponse={trainingResponse} trainingTrainerRoute={trainingTrainerRoute} />
                            <TextField fullWidth label="Informations" value={trainingResponse.informations} onChange={handleChange('informations')} />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={trainingResponse.eveHotel} disabled={!training.eveHotel}
                                        onChange={handleChangeChecked('eveHotel')} />
                                }
                                label="Hotel la veille"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={trainingResponse.noKmsFees}
                                        onChange={handleChangeChecked('noKmsFees')} />
                                }
                                label="Sans frais kilométriques"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={trainingResponse.freeHosting}
                                        onChange={handleChangeChecked('freeHosting')} />
                                }
                                label="Héberger gratuitement"
                            />
                            {training.trainingDates.map((trainingDate, indexTrainingDate) => <TrainingDateForm key={trainingDate.id} trainingDate={trainingDate}
                                trainingResponses={trainingResponses} setTrainingResponses={setTrainingResponses} indexTraining={indexTraining} indexTrainingDate={indexTrainingDate} />)}
                        </Box>
                    </Collapse>
                </Box>
            </Card>
        </Box>
    );
};

TrainingForm.whyDidYouRender = true

export default TrainingForm;
export type { TrainingFormData };