import {  createMuiTheme } from '@material-ui/core';

import { teal, amber } from '@material-ui/core/colors';

const theme = createMuiTheme({
    typography: {
        overline: {
            lineHeight: 1
        }
    },
    palette: {
        primary: teal,
        secondary: amber
    }
});

export default theme;