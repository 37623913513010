import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './scenes/Home';
import Password from './scenes/Password';
import Invitation from './scenes/Invitation';
import InviteUser from 'scenes/UnloggedApp/scenes/InviteUser';
import ErrorBoundary from 'components/ErrorBoundary';

const UnloggedApp = () => {
    return (
        <Switch>
            <Route exact path='/'>
                <ErrorBoundary key="1">
                    <Home />
                </ErrorBoundary>
            </Route>
            <Route path='/invite/user/:token'>
                <ErrorBoundary key="2">
                    <InviteUser />
                </ErrorBoundary>
            </Route>
            <Route path='/password'>
                <ErrorBoundary key="3">
                    <Password />
                </ErrorBoundary>
            </Route>
            <Route path='/invitation'>
                <ErrorBoundary key="4">
                    <Invitation />
                </ErrorBoundary>
            </Route>
            <Route path='/'>
                <ErrorBoundary key="5">
                    <Home />
                </ErrorBoundary>
            </Route>
        </Switch>
    );
};

export default UnloggedApp;