import React from 'react';
import { FormControlLabel, Switch, Checkbox, Collapse, TextField, Box } from '@material-ui/core';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { VolunteerFormData } from '.';
import { MatchDate } from 'generated/graphql';

type VolunteerDateFormData = {
    matchDateId: string
    accepted: boolean
    lunch: boolean
    dinner: boolean
    hotel: boolean
    informations: string
}

type Props = {
    matchDate: Pick<MatchDate, "id" | "date" | "lunch" | "startHour" | "hotel" | "endHour" | "dinner">
    matchResponses: Array<VolunteerFormData>
    setMatchResponses: React.Dispatch<React.SetStateAction<VolunteerFormData[]>>
    indexMatch: number
    indexMatchDate: number
}
const VolunteerDateForm = ({ matchDate, matchResponses, setMatchResponses, indexMatch, indexMatchDate }: Props) => {
    const matchDateResponse = matchResponses[indexMatch].matchDates[indexMatchDate];

    const handleChangeChecked = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const lens = R.lensPath([indexMatch, 'matchDates', indexMatchDate, name]);
        setMatchResponses(R.set(lens, e.target.checked, matchResponses));
    }

    const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const lens = R.lensPath([indexMatch, 'matchDates', indexMatchDate, name]);
        setMatchResponses(R.set(lens, e.target.value, matchResponses));
    }

    return (
        <Box marginBottom={4}>
            <FormControlLabel
                control={
                    <Switch
                        checked={matchDateResponse.accepted}
                        onChange={handleChangeChecked('accepted')}
                        color="primary"
                    />
                }
                label={DateTime.fromSQL(matchDate.date).toLocaleString(DateTime.DATE_MED)}
            />
            <Collapse in={matchDateResponse.accepted} timeout="auto" unmountOnExit>
                <Box paddingX={6}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={matchDateResponse.lunch} disabled={!matchDate.lunch}
                                onChange={handleChangeChecked('lunch')} />
                        }
                        label="Repas midi"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={matchDateResponse.dinner} disabled={!matchDate.dinner}
                                onChange={handleChangeChecked('dinner')} />
                        }
                        label="Repas soir"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={matchDateResponse.hotel} disabled={!matchDate.hotel}
                                onChange={handleChangeChecked('hotel')} />
                        }
                        label="Hotel"
                    />
                    <TextField fullWidth label="Informations pour ce jour uniquement" value={matchDateResponse.informations} onChange={handleChange('informations')} />
                </Box>
            </Collapse>
        </Box>
    );
};

VolunteerDateForm.whyDidYouRender = true

export default VolunteerDateForm;
export type { VolunteerDateFormData };