import React, { useContext } from 'react';
import LoggedApp from 'scenes/LoggedApp';
import UnloggedApp from 'scenes/UnloggedApp';
import { LoggedInContext } from './context';

const App = () => {
    const { state } = useContext(LoggedInContext);

    return state.isLoggedIn ? <LoggedApp /> : <UnloggedApp />;
};

App.whyDidYouRender = true;

export default App;
