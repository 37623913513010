import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Match from './scenes/Match';
import Volunteer from './scenes/Volunteer';
import Training from './scenes/Training';
import Meeting from './scenes/Meeting';
import TrainingTrainee from './scenes/TrainingTrainee';

const Invitation = () => {
    return (
        <Switch>
            <Route exact path='/invitation/match/:token'>
                <Match />
            </Route>
            <Route exact path='/invitation/volunteer/:token'>
                <Volunteer />
            </Route>
            <Route exact path='/invitation/trainer/:token'>
                <Training />
            </Route>
            <Route exact path='/invitation/trainee/:token'>
                <TrainingTrainee />
            </Route>
            <Route exact path='/invitation/meeting/:token'>
                <Meeting />
            </Route>
        </Switch>
    );
};

export default Invitation;