import React from 'react';
import Cookies from 'universal-cookie';
import { User, League, Department, Maybe } from './generated/graphql';

const cookies = new Cookies();

const createContext = <StateType, ActionType>(
    reducer: React.Reducer<StateType, ActionType>,
    initialState: StateType,
) => {
    const defaultDispatch: React.Dispatch<ActionType> = () => initialState;
    const ctx = React.createContext({
        state: initialState,
        dispatch: defaultDispatch,
    })
    function Provider(props: React.PropsWithChildren<{}>) {
        const [state, dispatch] = React.useReducer<React.Reducer<StateType, ActionType>>(reducer, initialState)
        return <ctx.Provider value={{ state, dispatch }} {...props} />
    }
    return [ctx, Provider] as const;
}

type AppStateUser = (
    Pick<User, 'id' | 'firstName' | 'lastName' | 'admin' | 'permissions' | 'email'>
    & {
        league: (
            Pick<League, 'id' | 'name' | 'logo'>
            & { departments: Array<Pick<Department, 'id' | 'name' | 'number'>> }
        ), department?: Maybe<Pick<Department, 'id' | 'name' | 'logo'>>
    }
)

type AppState = {
    isLoggedIn: boolean
}
type GlobalContextAction =
    | { type: "login" }

const initialState: AppState = {
    isLoggedIn: cookies.get('id') ? true : false
};

const reducer = (state: AppState, action: GlobalContextAction): AppState => {
    switch (action.type) {
        case "login":

            return {
                ...state,
                isLoggedIn: true
            };
        default:
            throw new Error();
    }
}
const [LoggedInContext, LoggedInProvider] = createContext(reducer, initialState);
const UserContext = React.createContext<AppStateUser>({} as AppStateUser);
export { UserContext, LoggedInContext, LoggedInProvider };