import React, { Fragment } from 'react';
import { Divider, Box, IconButton } from '@material-ui/core';
import { useSlate } from 'slate-react';
import { Transforms, Editor } from 'slate';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';

const Toolbar = () => {

    return (
        <Fragment>
            <Box padding={1}>
                <MarkButton format="bold" icon={<FormatBoldIcon />} />
                <MarkButton format="italic" icon={<FormatItalicIcon />} />
                <MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
                <BlockButton format="left-align" icon={<FormatAlignLeftIcon />} />
                <BlockButton format="center-align" icon={<FormatAlignCenterIcon />} />
            </Box>
            <Divider />
        </Fragment>
    );
};

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const toggleBlock = (editor: Editor, format: string) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true,
    });

    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
};

const toggleMark = (editor: Editor, format: string) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
};

const isBlockActive = (editor: Editor, format: string) => {
    /*const [match] = Editor.nodes(editor, {
        match: n => n.type === format,
    });*/ // TODO
    //@ts-ignore
    const [match] = Editor.nodes(editor, {
        match: n => n.type === format,
    });

    return !!match;
};

const isMarkActive = (editor: Editor, format: string) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

type BlockButtonProps = {
    format: string
    icon: JSX.Element
}
const BlockButton = ({ format, icon }: BlockButtonProps) => {
    const editor = useSlate();
    const active = isBlockActive(editor, format);
    return (
        <IconButton
            size="small"
            color={active ? "secondary" : "default"}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            {icon}
        </IconButton>
    );
};

type MarkButtonProps = {
    format: string
    icon: JSX.Element
}
const MarkButton = ({ format, icon }: MarkButtonProps) => {
    const editor = useSlate();
    const active = isMarkActive(editor, format);
    return (
        <IconButton
            size="small"
            color={active ? "secondary" : "default"}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            {icon}
        </IconButton>
    );
};

export default Toolbar;