import React, { Fragment, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Hidden, Drawer, Divider, Paper, Typography, List, ListItem, Button, colors, makeStyles, SvgIconTypeMap } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SportsIcon from '@material-ui/icons/Sports';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { formatFirstName, formatLastName } from 'utils/formatName';
import { CurrentUserQuery } from 'generated/graphql';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        overflowY: 'auto',
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: '0 0 auto',
        '@media print ': {
            display: 'none'
        }
    },
    content: {
        padding: theme.spacing(2)
    },
    name: {
        marginTop: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1)
    },
    navigation: {
        marginTop: theme.spacing(2)
    },
    button: {
        color: colors.blueGrey[800],
        padding: '8px 6px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    icon: {
        color: theme.palette.action.active,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main
        }
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    },
    l: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 0,
        flexShrink: 0,
        lineHeight: '10px',
        fontSize: '10px',
        height: 20,
        minWidth: 20,
        whiteSpace: 'nowrap',
        borderRadius: 10,
        padding: theme.spacing(0.5),
        backgroundColor: colors.red[500],
        color: '#FFF'
    },
    logo: {
        display: 'block',
        width: '128px',
        height: '128px',
        margin: 'auto',
        objectFit: 'contain'
    }
}));

type LinkProps = {
    title: string
    to: string
    exact?: boolean
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}
const Link = ({ title, to, exact, icon: Icon }: LinkProps) => {
    const classes = useStyles();

    return (
        <ListItem className={classes.item} disableGutters>
            <Button activeClassName={classes.active} className={classes.button} component={NavLink} exact={exact} to={to}>
                <Icon className={classes.icon} />
                {title}
            </Button>
        </ListItem>
    );
};

type Props = {
    openMobile: boolean
    onMobileClose: () => void
} & CurrentUserQuery
const NavBar = ({ user, openMobile, onMobileClose }: Props) => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (openMobile) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const navbarContent = (
        <div className={classes.content}>
            <img className={classes.logo} alt="" src={user.department ? user.department.logo || "" : user.league.logo || ""} />
            <Typography className={classes.name} variant="h6" align="center">{formatFirstName(user)}</Typography>
            <Typography variant="h6" align="center">{formatLastName(user)}</Typography>
            <List onClick={() => onMobileClose()}>
                <Link title="Mon compte" exact to="/account" icon={SettingsIcon} />
                <Link title="Se déconnecter" exact to="/logout" icon={ExitToAppIcon} />
            </List>
            <Divider className={classes.divider} />
            <nav className={classes.navigation}>
                {(user.admin || user.permissions.includes('match_read')) &&
                    <Fragment>
                        <Typography variant="overline">{'Arbitrage'}</Typography>
                        <List onClick={() => onMobileClose()}>
                            <Link title="Matchs" to="/matches" icon={SportsIcon} />
                            <Link title="Arbitres" to="/referees" icon={PersonIcon} />
                            <Link title="Bénévoles" to="/volunteers" icon={PersonIcon} />
                            <Link title="Statistiques" to="/refereesStats" icon={ShowChartIcon} />
                        </List>
                    </Fragment>
                }
                {(user.admin || user.permissions.includes('training_read')) &&
                    <Fragment>
                        <Typography variant="overline">{'Formation'}</Typography>
                        <List onClick={() => onMobileClose()}>
                            <Link title="Formations" to="/trainings" icon={SchoolIcon} />
                            <Link title="Formateurs" to="/trainers" icon={PersonIcon} />
                            <Link title="Stagiaires" to="/trainees" icon={PersonIcon} />
                            <Link title="Statistiques" to="/trainersStats" icon={ShowChartIcon} />
                        </List>
                    </Fragment>
                }
                {(user.admin || user.permissions.includes('meeting_read')) &&
                    <Fragment>
                        <Typography variant="overline">{'Réunion'}</Typography>
                        <List onClick={() => onMobileClose()}>
                            <Link title="Réunions" to="/meetings" icon={PeopleIcon} />
                            <Link title="Membres" to="/members" icon={PersonIcon} />
                            <Link title="Statistiques" to="/membersStats" icon={ShowChartIcon} />
                        </List>
                    </Fragment>
                }
                {user.admin &&
                    <Fragment>
                        <Typography variant="overline">{'Gestion'}</Typography>
                        <List onClick={() => onMobileClose()}>
                            {!user.department &&
                                <Link title="Ligue" exact to="/league" icon={PersonOutlineIcon} />
                            }
                            {!user.department &&
                                <Link title="Administrateurs" exact to="/admins" icon={PersonOutlineIcon} />
                            }
                            <Link title="Utilisateurs" exact to="/users" icon={PersonOutlineIcon} />
                        </List>
                    </Fragment>
                }
            </nav>
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
                    <div className={classes.root}>
                        {navbarContent}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Paper className={classes.root} elevation={1} square>
                    {navbarContent}
                </Paper>
            </Hidden>
        </Fragment>
    );
};

export default NavBar;