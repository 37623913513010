import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddAdminInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};

export type AddAdminPayload = {
  user: User;
};

export type AddCompetitionInput = {
  name: Scalars['String'];
};

export type AddCompetitionPayload = {
  success: Scalars['Boolean'];
};

export type AddMatchDateInput = {
  date: Scalars['String'];
  requiredCount: Scalars['Int'];
  startHour: Scalars['String'];
  endHour: Scalars['String'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
};

export type AddMatchDateRefereeInput = {
  matchDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type AddMatchDateVolunteerInput = {
  matchDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type AddMatchInput = {
  title: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  competitions: Array<Scalars['ID']>;
  dates: Array<AddMatchDateInput>;
};

export type AddMatchPayload = {
  match: Match;
};

export type AddMatchRefereeInput = {
  matchId: Scalars['ID'];
  refereeId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  toDistance: Scalars['Float'];
  fromDistance: Scalars['Float'];
  informations: Scalars['String'];
  matchDates: Array<AddMatchDateRefereeInput>;
  refereeDisciplineId?: Maybe<Scalars['ID']>;
};

export type AddMatchRefereePayload = {
  matchReferee: MatchReferee;
};

export type AddMatchVolunteerInput = {
  matchId: Scalars['ID'];
  volunteerId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  matchDates: Array<AddMatchDateVolunteerInput>;
};

export type AddMatchVolunteerPayload = {
  matchVolunteer: MatchVolunteer;
};

export type AddMeetingDateInput = {
  date: Scalars['String'];
  requiredCount: Scalars['Int'];
  startHour: Scalars['String'];
  endHour: Scalars['String'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
};

export type AddMeetingDateMemberInput = {
  meetingDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type AddMeetingInput = {
  title: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  dates: Array<AddMeetingDateInput>;
};

export type AddMeetingMemberInput = {
  meetingId: Scalars['ID'];
  memberId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  toDistance: Scalars['Float'];
  fromDistance: Scalars['Float'];
  informations: Scalars['String'];
  meetingDates: Array<AddMeetingDateMemberInput>;
};

export type AddMeetingMemberPayload = {
  meetingMember: MeetingMember;
};

export type AddMeetingPayload = {
  meeting: Meeting;
};

export type AddTraineeCategoryInput = {
  name: Scalars['String'];
};

export type AddTraineeCategoryPayload = {
  success: Scalars['Boolean'];
};

export type AddTraineeInput = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  traineeCategoryId?: Maybe<Scalars['ID']>;
};

export type AddTraineePayload = {
  success: Scalars['Boolean'];
};

export type AddTrainingDateInput = {
  date: Scalars['String'];
  requiredCount: Scalars['Int'];
  startHour: Scalars['String'];
  endHour: Scalars['String'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
};

export type AddTrainingDateTraineeInput = {
  trainingDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type AddTrainingDateTrainerInput = {
  trainingDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type AddTrainingInput = {
  title: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  dates: Array<AddTrainingDateInput>;
};

export type AddTrainingPayload = {
  training: Training;
};

export type AddTrainingTraineeInput = {
  trainingId: Scalars['ID'];
  traineeId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  trainingDates: Array<AddTrainingDateTraineeInput>;
};

export type AddTrainingTraineePayload = {
  trainingTrainee: TrainingTrainee;
};

export type AddTrainingTrainerInput = {
  trainingId: Scalars['ID'];
  trainerId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  toDistance: Scalars['Float'];
  fromDistance: Scalars['Float'];
  informations: Scalars['String'];
  trainingDates: Array<AddTrainingDateTrainerInput>;
};

export type AddTrainingTrainerPayload = {
  trainingTrainer: TrainingTrainer;
};

export type AddUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export type AddUserPayload = {
  user: User;
};

export type AddVolunteerInput = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type AddVolunteerPayload = {
  success: Scalars['Boolean'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Competition = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Department = {
  id: Scalars['ID'];
  number: Scalars['Int'];
  name: Scalars['String'];
  league: League;
  logo?: Maybe<Scalars['String']>;
};

export type DisableTraineeInput = {
  traineeId: Scalars['ID'];
};

export type DisableTraineePayload = {
  trainee: Trainee;
};

export type DisableVolunteerInput = {
  volunteerId: Scalars['ID'];
};

export type DisableVolunteerPayload = {
  volunteer: Volunteer;
};

export type Discipline = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EnableTraineeInput = {
  traineeId: Scalars['ID'];
};

export type EnableTraineePayload = {
  trainee: Trainee;
};

export type EnableVolunteerInput = {
  volunteerId: Scalars['ID'];
};

export type EnableVolunteerPayload = {
  volunteer: Volunteer;
};

export type GenerateMatchsToRefereesInput = {
  refereeIds: Array<Scalars['ID']>;
  matchIds: Array<Scalars['ID']>;
};

export type GenerateMatchsToRefereesPayload = {
  data: Array<MatchsToRefereesPayload>;
};

export type GenerateMatchsToVolunteersInput = {
  volunteerIds: Array<Scalars['ID']>;
  matchIds: Array<Scalars['ID']>;
};

export type GenerateMatchsToVolunteersPayload = {
  data: Array<MatchsToVolunteersPayload>;
};

export type GenerateMeetingsToMembersInput = {
  memberIds: Array<Scalars['ID']>;
  meetingIds: Array<Scalars['ID']>;
};

export type GenerateMeetingsToMembersPayload = {
  data: Array<MeetingsToMembersPayload>;
};

export type GenerateTrainingsToTraineesInput = {
  traineeIds: Array<Scalars['ID']>;
  trainingIds: Array<Scalars['ID']>;
};

export type GenerateTrainingsToTraineesPayload = {
  data: Array<TrainingsToTraineesPayload>;
};

export type GenerateTrainingsToTrainersInput = {
  trainerIds: Array<Scalars['ID']>;
  trainingIds: Array<Scalars['ID']>;
};

export type GenerateTrainingsToTrainersPayload = {
  data: Array<TrainingsToTrainersPayload>;
};

export type ImportMembersInput = {
  file: Scalars['Upload'];
  validateOnly?: Maybe<Scalars['Boolean']>;
};

export type ImportMembersPayload = {
  addedCount: Scalars['Int'];
  updatedCount: Scalars['Int'];
  removedCount: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type ImporTrainersPayload = {
  addedCount: Scalars['Int'];
  updatedCount: Scalars['Int'];
  removedCount: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type ImportRefereesInput = {
  file: Scalars['Upload'];
  validateOnly?: Maybe<Scalars['Boolean']>;
};

export type ImportRefereesPayload = {
  addedCount: Scalars['Int'];
  updatedCount: Scalars['Int'];
  removedCount: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type ImportTrainersInput = {
  file: Scalars['Upload'];
  validateOnly?: Maybe<Scalars['Boolean']>;
};

export type League = {
  id: Scalars['ID'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  departments: Array<Department>;
  prices: LeaguePrices;
};


export type LeaguePricesArgs = {
  year: Scalars['Int'];
};

export type LeaguePrices = {
  id: Scalars['ID'];
  year: Scalars['Int'];
  pricePerKm: Scalars['Float'];
  pricePerLunch: Scalars['Float'];
  pricePerDinner: Scalars['Float'];
  pricePerHotel: Scalars['Float'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  success: Scalars['Boolean'];
};

export type LogoutPayload = {
  success: Scalars['Boolean'];
};

export type Match = {
  id: Scalars['ID'];
  league: League;
  department?: Maybe<Department>;
  title: Scalars['String'];
  level: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  matchDates: Array<MatchDate>;
  lastTimeRequest?: Maybe<Scalars['String']>;
  matchReferees: Array<MatchReferee>;
  matchResponses: Array<MatchResponse>;
  matchVolunteers: Array<MatchVolunteer>;
  matchVolunteerResponses: Array<MatchVolunteerResponse>;
  competitions: Array<Competition>;
  prices?: Maybe<LeaguePrices>;
  refereesRequest: Array<RefereeRequestMatch>;
  volunteersRequest: Array<VolunteerRequestMatch>;
};

export type MatchDate = {
  id: Scalars['ID'];
  date: Scalars['String'];
  requiredCount: Scalars['Int'];
  startHour: Scalars['String'];
  endHour: Scalars['String'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  responsible?: Maybe<MatchDateReferee>;
};

export type MatchDateReferee = {
  id: Scalars['ID'];
  matchDate: MatchDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  role: Scalars['String'];
  informations: Scalars['String'];
};

export type MatchDateResponse = {
  id: Scalars['ID'];
  matchDate: MatchDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type MatchDateResponseInput = {
  matchDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type MatchDateVolunteer = {
  id: Scalars['ID'];
  matchDate: MatchDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  role: Scalars['String'];
  informations: Scalars['String'];
};

export type MatchDateVolunteerResponse = {
  id: Scalars['ID'];
  matchDate: MatchDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type MatchReferee = {
  id: Scalars['ID'];
  match: Match;
  referee: Referee;
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  informations: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  matchDateReferees: Array<MatchDateReferee>;
  matchRefereeRoute: MatchRefereeRoute;
  discipline?: Maybe<RefereeDiscipline>;
};

export type MatchRefereeRoute = {
  id: Scalars['ID'];
  match: Match;
  referee: Referee;
  to: Scalars['Float'];
  from: Scalars['Float'];
};

export type MatchResponse = {
  id: Scalars['ID'];
  match: Match;
  referee: Referee;
  accepted: Scalars['Boolean'];
  informations: Scalars['String'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  createdAt: Scalars['String'];
  matchDateResponses: Array<MatchDateResponse>;
  matchRefereeRoute: MatchRefereeRoute;
};

export type MatchResponseInput = {
  matchId: Scalars['ID'];
  accepted: Scalars['Boolean'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  informations: Scalars['String'];
  matchDates: Array<MatchDateResponseInput>;
};

export type MatchsToRefereesPayload = {
  referee: Referee;
  doc: Scalars['String'];
};

export type MatchsToVolunteersPayload = {
  volunteer: Referee;
  doc: Scalars['String'];
};

export type MatchVolunteer = {
  id: Scalars['ID'];
  match: Match;
  volunteer: Volunteer;
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  matchDateVolunteers: Array<MatchDateVolunteer>;
};

export type MatchVolunteerDateResponseInput = {
  matchDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type MatchVolunteerResponse = {
  id: Scalars['ID'];
  match: Match;
  volunteer: Volunteer;
  accepted: Scalars['Boolean'];
  informations: Scalars['String'];
  eveHotel: Scalars['Boolean'];
  createdAt: Scalars['String'];
  matchDateResponses: Array<MatchDateVolunteerResponse>;
};

export type MatchVolunteerResponseInput = {
  matchId: Scalars['ID'];
  accepted: Scalars['Boolean'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  matchDates: Array<MatchVolunteerDateResponseInput>;
};

export type Meeting = {
  id: Scalars['ID'];
  league: League;
  department?: Maybe<Department>;
  title: Scalars['String'];
  level: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  meetingDates: Array<MeetingDate>;
  lastTimeRequest?: Maybe<Scalars['String']>;
  meetingMembers: Array<MeetingMember>;
  meetingResponses: Array<MeetingResponse>;
  prices?: Maybe<LeaguePrices>;
  membersRequest: Array<MemberRequestMeeting>;
};

export type MeetingDate = {
  id: Scalars['ID'];
  date: Scalars['String'];
  requiredCount: Scalars['Int'];
  startHour: Scalars['String'];
  endHour: Scalars['String'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
};

export type MeetingDateMember = {
  id: Scalars['ID'];
  meetingDate: MeetingDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  role: Scalars['String'];
  informations: Scalars['String'];
};

export type MeetingDateResponse = {
  id: Scalars['ID'];
  meetingDate: MeetingDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type MeetingDateResponseInput = {
  meetingDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type MeetingMember = {
  id: Scalars['ID'];
  meeting: Meeting;
  member: Member;
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  informations: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  meetingDateMembers: Array<MeetingDateMember>;
  meetingMemberRoute: MeetingMemberRoute;
};

export type MeetingMemberRoute = {
  id: Scalars['ID'];
  meeting: Meeting;
  member: Member;
  to: Scalars['Float'];
  from: Scalars['Float'];
};

export type MeetingResponse = {
  id: Scalars['ID'];
  meeting: Meeting;
  member: Member;
  accepted: Scalars['Boolean'];
  informations: Scalars['String'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  createdAt: Scalars['String'];
  meetingDateResponses: Array<MeetingDateResponse>;
  meetingMemberRoute: MeetingMemberRoute;
};

export type MeetingResponseInput = {
  meetingId: Scalars['ID'];
  accepted: Scalars['Boolean'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  informations: Scalars['String'];
  meetingDates: Array<MeetingDateResponseInput>;
};

export type MeetingsToMembersPayload = {
  member: Member;
  doc: Scalars['String'];
};

export type Member = {
  id: Scalars['ID'];
  league: League;
  department?: Maybe<Department>;
  licence: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  sexe: Scalars['String'];
  clubNumber: Scalars['String'];
  clubName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  infos: Array<MemberInfo>;
  meetings: Array<MeetingMember>;
  enabled: Scalars['Boolean'];
  totalInvitationCount: Scalars['Int'];
  invitationWithoutResponseCount: Scalars['Int'];
};


export type MemberMeetingsArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type MemberTotalInvitationCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type MemberInvitationWithoutResponseCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};

export type MemberInfo = {
  id: Scalars['ID'];
  accessRight: Scalars['String'];
  title: Scalars['String'];
  groupe: Scalars['String'];
};

export type MemberRequest = {
  id: Scalars['ID'];
  requestMeetings: Array<MemberRequestMeeting>;
  member: Member;
  message: RequestMessage;
  responded: Scalars['Boolean'];
};

export type MemberRequestMeeting = {
  id: Scalars['ID'];
  meeting: Meeting;
  member: Member;
  meetingMemberRoute: MeetingMemberRoute;
  lastResponse?: Maybe<MeetingResponse>;
  createdAt: Scalars['String'];
};

export type Mutation = {
  addAdmin: AddAdminPayload;
  addUser: AddUserPayload;
  login: LoginPayload;
  logout: LogoutPayload;
  removeAdmin: RemoveAdminPayload;
  removeUser: RemoveUserPayload;
  requestResetPassword: RequestResetPasswordPayload;
  resetPassword: ResetPasswordPayload;
  updateAdmin: UpdateAdminPayload;
  updateEmail: UpdateEmailPayload;
  updateUser: UpdateUserPayload;
  updateLeaguePrices: UpdateLeaguePricesPayload;
  addMatch: AddMatchPayload;
  addMatchReferee: AddMatchRefereePayload;
  removeMatch: RemoveMatchPayload;
  removeMatchReferee: RemoveMatchRefereePayload;
  respondToMatchRequest: RespondToMatchRequestPayload;
  sendMatchRefereeEmail: SendMatchRefereeEmailPayload;
  sendMatchRequest: SendMatchRequestPayload;
  sendMatchsToReferees: SendMatchsToRefereesPayload;
  updateMatch: UpdateMatchPayload;
  updateMatchReferee: UpdateMatchRefereePayload;
  updateMatchRefereeRoute: UpdateMatchRefereeRoutePayload;
  updateMatchDateResponsible: UpdateMatchDateResponsiblePayload;
  updateMatchRefereeDiscipline: UpdateMatchRefereeDisciplinePayload;
  addMatchVolunteer: AddMatchVolunteerPayload;
  removeMatchVolunteer: RemoveMatchVolunteerPayload;
  respondToMatchVolunteerRequest: RespondToMatchVolunteerRequestPayload;
  sendMatchsToVolunteers: SendMatchsToVolunteersPayload;
  sendMatchVolunteerEmail: SendMatchVolunteerEmailPayload;
  sendMatchVolunteerRequest: SendMatchVolunteerRequestPayload;
  updateMatchVolunteer: UpdateMatchVolunteerPayload;
  relaunchVolunteerRequest: RelaunchVolunteerRequestPayload;
  relaunchRefereeRequest: RelaunchRefereeRequestPayload;
  updateMatchLocation: UpdateMatchLocationPayload;
  generateMatchsToReferees: GenerateMatchsToRefereesPayload;
  generateMatchsToVolunteers: GenerateMatchsToVolunteersPayload;
  importReferees: ImportRefereesPayload;
  addTraining: AddTrainingPayload;
  addTrainingTrainer: AddTrainingTrainerPayload;
  removeTraining: RemoveTrainingPayload;
  removeTrainingTrainer: RemoveTrainingTrainerPayload;
  respondToTrainingRequest: RespondToTrainingRequestPayload;
  sendTrainingTrainerEmail: SendTrainingTrainerEmailPayload;
  sendTrainingRequest: SendTrainingRequestPayload;
  sendTrainingsToTrainers: SendTrainingsToTrainersPayload;
  updateTraining: UpdateTrainingPayload;
  updateTrainingTrainer: UpdateTrainingTrainerPayload;
  updateTrainingTrainerRoute: UpdateTrainingTrainerRoutePayload;
  sendTrainingTraineeRequest: SendTrainingTraineeRequestPayload;
  respondToTrainingTraineeRequest: RespondToTrainingTraineeRequestPayload;
  addTrainingTrainee: AddTrainingTraineePayload;
  updateTrainingTrainee: UpdateTrainingTraineePayload;
  removeTrainingTrainee: RemoveTrainingTraineePayload;
  sendTrainingTraineeEmail: SendTrainingTraineeEmailPayload;
  sendTrainingsToTrainees: SendTrainingsToTraineesPayload;
  relaunchTrainerRequest: RelaunchTrainerRequestPayload;
  relaunchTraineeRequest: RelaunchTraineeRequestPayload;
  updateTrainingLocation: UpdateTrainingLocationPayload;
  generateTrainingsToTrainers: GenerateTrainingsToTrainersPayload;
  generateTrainingsToTrainees: GenerateTrainingsToTraineesPayload;
  importTrainers: ImporTrainersPayload;
  addTrainee: AddTraineePayload;
  disableTrainee: DisableTraineePayload;
  enableTrainee: EnableTraineePayload;
  updateTrainee: UpdateTraineePayload;
  addTraineeCategory: AddTraineeCategoryPayload;
  removeTraineeCategory: RemoveTraineeCategoryPayload;
  addMeeting: AddMeetingPayload;
  addMeetingMember: AddMeetingMemberPayload;
  removeMeeting: RemoveMeetingPayload;
  removeMeetingMember: RemoveMeetingMemberPayload;
  respondToMeetingRequest: RespondToMeetingRequestPayload;
  sendMeetingMemberEmail: SendMeetingMemberEmailPayload;
  sendMeetingRequest: SendMeetingRequestPayload;
  sendMeetingsToMembers: SendMeetingsToMembersPayload;
  updateMeeting: UpdateMeetingPayload;
  updateMeetingMember: UpdateMeetingMemberPayload;
  updateMeetingMemberRoute: UpdateMeetingMemberRoutePayload;
  relaunchMemberRequest: RelaunchMemberRequestPayload;
  updateMeetingLocation: UpdateMeetingLocationPayload;
  generateMeetingsToMembers: GenerateMeetingsToMembersPayload;
  importMembers: ImportMembersPayload;
  addCompetition: AddCompetitionPayload;
  removeCompetition: RemoveCompetitionPayload;
  addVolunteer: AddVolunteerPayload;
  disableVolunteer: DisableVolunteerPayload;
  enableVolunteer: EnableVolunteerPayload;
  updateVolunteer: UpdateVolunteerPayload;
};


export type MutationAddAdminArgs = {
  input: AddAdminInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRemoveAdminArgs = {
  input: RemoveAdminInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationUpdateEmailArgs = {
  input: UpdateEmailInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateLeaguePricesArgs = {
  input: UpdateLeaguePricesInput;
};


export type MutationAddMatchArgs = {
  input: AddMatchInput;
};


export type MutationAddMatchRefereeArgs = {
  input: AddMatchRefereeInput;
};


export type MutationRemoveMatchArgs = {
  input: RemoveMatchInput;
};


export type MutationRemoveMatchRefereeArgs = {
  input: RemoveMatchRefereeInput;
};


export type MutationRespondToMatchRequestArgs = {
  input: RespondToMatchRequestInput;
};


export type MutationSendMatchRefereeEmailArgs = {
  input: SendMatchRefereeEmailInput;
};


export type MutationSendMatchRequestArgs = {
  input: SendMatchRequestInput;
};


export type MutationSendMatchsToRefereesArgs = {
  input: SendMatchsToRefereesInput;
};


export type MutationUpdateMatchArgs = {
  input: UpdateMatchInput;
};


export type MutationUpdateMatchRefereeArgs = {
  input: UpdateMatchRefereeInput;
};


export type MutationUpdateMatchRefereeRouteArgs = {
  input: UpdateMatchRefereeRouteInput;
};


export type MutationUpdateMatchDateResponsibleArgs = {
  input: UpdateMatchDateResponsibleInput;
};


export type MutationUpdateMatchRefereeDisciplineArgs = {
  input: UpdateMatchRefereeDisciplineInput;
};


export type MutationAddMatchVolunteerArgs = {
  input: AddMatchVolunteerInput;
};


export type MutationRemoveMatchVolunteerArgs = {
  input: RemoveMatchVolunteerInput;
};


export type MutationRespondToMatchVolunteerRequestArgs = {
  input: RespondToMatchVolunteerRequestInput;
};


export type MutationSendMatchsToVolunteersArgs = {
  input: SendMatchsToVolunteersInput;
};


export type MutationSendMatchVolunteerEmailArgs = {
  input: SendMatchVolunteerEmailInput;
};


export type MutationSendMatchVolunteerRequestArgs = {
  input: SendMatchVolunteerRequestInput;
};


export type MutationUpdateMatchVolunteerArgs = {
  input: UpdateMatchVolunteerInput;
};


export type MutationRelaunchVolunteerRequestArgs = {
  input: RelaunchVolunteerRequestInput;
};


export type MutationRelaunchRefereeRequestArgs = {
  input: RelaunchRefereeRequestInput;
};


export type MutationUpdateMatchLocationArgs = {
  input: UpdateMatchLocationInput;
};


export type MutationGenerateMatchsToRefereesArgs = {
  input: GenerateMatchsToRefereesInput;
};


export type MutationGenerateMatchsToVolunteersArgs = {
  input: GenerateMatchsToVolunteersInput;
};


export type MutationImportRefereesArgs = {
  input: ImportRefereesInput;
};


export type MutationAddTrainingArgs = {
  input: AddTrainingInput;
};


export type MutationAddTrainingTrainerArgs = {
  input: AddTrainingTrainerInput;
};


export type MutationRemoveTrainingArgs = {
  input: RemoveTrainingInput;
};


export type MutationRemoveTrainingTrainerArgs = {
  input: RemoveTrainingTrainerInput;
};


export type MutationRespondToTrainingRequestArgs = {
  input: RespondToTrainingRequestInput;
};


export type MutationSendTrainingTrainerEmailArgs = {
  input: SendTrainingTrainerEmailInput;
};


export type MutationSendTrainingRequestArgs = {
  input: SendTrainingRequestInput;
};


export type MutationSendTrainingsToTrainersArgs = {
  input: SendTrainingsToTrainersInput;
};


export type MutationUpdateTrainingArgs = {
  input: UpdateTrainingInput;
};


export type MutationUpdateTrainingTrainerArgs = {
  input: UpdateTrainingTrainerInput;
};


export type MutationUpdateTrainingTrainerRouteArgs = {
  input: UpdateTrainingTrainerRouteInput;
};


export type MutationSendTrainingTraineeRequestArgs = {
  input: SendTrainingTraineeRequestInput;
};


export type MutationRespondToTrainingTraineeRequestArgs = {
  input: RespondToTrainingTraineeRequestInput;
};


export type MutationAddTrainingTraineeArgs = {
  input: AddTrainingTraineeInput;
};


export type MutationUpdateTrainingTraineeArgs = {
  input: UpdateTrainingTraineeInput;
};


export type MutationRemoveTrainingTraineeArgs = {
  input: RemoveTrainingTraineeInput;
};


export type MutationSendTrainingTraineeEmailArgs = {
  input: SendTrainingTraineeEmailInput;
};


export type MutationSendTrainingsToTraineesArgs = {
  input: SendTrainingsToTraineesInput;
};


export type MutationRelaunchTrainerRequestArgs = {
  input: RelaunchTrainerRequestInput;
};


export type MutationRelaunchTraineeRequestArgs = {
  input: RelaunchTraineeRequestInput;
};


export type MutationUpdateTrainingLocationArgs = {
  input: UpdateTrainingLocationInput;
};


export type MutationGenerateTrainingsToTrainersArgs = {
  input: GenerateTrainingsToTrainersInput;
};


export type MutationGenerateTrainingsToTraineesArgs = {
  input: GenerateTrainingsToTraineesInput;
};


export type MutationImportTrainersArgs = {
  input: ImportTrainersInput;
};


export type MutationAddTraineeArgs = {
  input: AddTraineeInput;
};


export type MutationDisableTraineeArgs = {
  input: DisableTraineeInput;
};


export type MutationEnableTraineeArgs = {
  input: EnableTraineeInput;
};


export type MutationUpdateTraineeArgs = {
  input: UpdateTraineeInput;
};


export type MutationAddTraineeCategoryArgs = {
  input: AddTraineeCategoryInput;
};


export type MutationRemoveTraineeCategoryArgs = {
  input: RemoveTraineeCategoryInput;
};


export type MutationAddMeetingArgs = {
  input: AddMeetingInput;
};


export type MutationAddMeetingMemberArgs = {
  input: AddMeetingMemberInput;
};


export type MutationRemoveMeetingArgs = {
  input: RemoveMeetingInput;
};


export type MutationRemoveMeetingMemberArgs = {
  input: RemoveMeetingMemberInput;
};


export type MutationRespondToMeetingRequestArgs = {
  input: RespondToMeetingRequestInput;
};


export type MutationSendMeetingMemberEmailArgs = {
  input: SendMeetingMemberEmailInput;
};


export type MutationSendMeetingRequestArgs = {
  input: SendMeetingRequestInput;
};


export type MutationSendMeetingsToMembersArgs = {
  input: SendMeetingsToMembersInput;
};


export type MutationUpdateMeetingArgs = {
  input: UpdateMeetingInput;
};


export type MutationUpdateMeetingMemberArgs = {
  input: UpdateMeetingMemberInput;
};


export type MutationUpdateMeetingMemberRouteArgs = {
  input: UpdateMeetingMemberRouteInput;
};


export type MutationRelaunchMemberRequestArgs = {
  input: RelaunchMemberRequestInput;
};


export type MutationUpdateMeetingLocationArgs = {
  input: UpdateMeetingLocationInput;
};


export type MutationGenerateMeetingsToMembersArgs = {
  input: GenerateMeetingsToMembersInput;
};


export type MutationImportMembersArgs = {
  input: ImportMembersInput;
};


export type MutationAddCompetitionArgs = {
  input: AddCompetitionInput;
};


export type MutationRemoveCompetitionArgs = {
  input: RemoveCompetitionInput;
};


export type MutationAddVolunteerArgs = {
  input: AddVolunteerInput;
};


export type MutationDisableVolunteerArgs = {
  input: DisableVolunteerInput;
};


export type MutationEnableVolunteerArgs = {
  input: EnableVolunteerInput;
};


export type MutationUpdateVolunteerArgs = {
  input: UpdateVolunteerInput;
};

export type Query = {
  user: User;
  users: Array<User>;
  admins: Array<User>;
  match: Match;
  matches: Array<Match>;
  matchesByIds: Array<Match>;
  refereeRequest: RefereeRequest;
  volunteerRequest: VolunteerRequest;
  referee?: Maybe<Referee>;
  refereesForStats: Array<Referee>;
  referees: Array<Referee>;
  searchReferees: Array<Referee>;
  disciplines: Array<Discipline>;
  training: Training;
  trainings: Array<Training>;
  trainingsByIds: Array<Training>;
  trainerRequest: TrainerRequest;
  traineeRequest: TraineeRequest;
  trainer?: Maybe<Trainer>;
  trainersForStats: Array<Trainer>;
  trainers: Array<Trainer>;
  searchTrainers: Array<Trainer>;
  trainee?: Maybe<Trainee>;
  trainees: Array<Trainee>;
  traineesForStats: Array<Trainee>;
  searchTrainees: Array<Trainee>;
  traineeCategories: Array<TraineeCategory>;
  meeting: Meeting;
  meetings: Array<Meeting>;
  meetingsByIds: Array<Meeting>;
  memberRequest: MemberRequest;
  member?: Maybe<Member>;
  membersForStats: Array<Member>;
  members: Array<Member>;
  searchMembers: Array<Member>;
  competitions: Array<Competition>;
  volunteer?: Maybe<Volunteer>;
  volunteersForStats: Array<Volunteer>;
  volunteers: Array<Volunteer>;
  searchVolunteers: Array<Volunteer>;
};


export type QueryUserArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type QueryMatchArgs = {
  matchId: Scalars['ID'];
};


export type QueryMatchesArgs = {
  year: Scalars['Int'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type QueryMatchesByIdsArgs = {
  matchIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryRefereeRequestArgs = {
  token: Scalars['String'];
};


export type QueryVolunteerRequestArgs = {
  token: Scalars['String'];
};


export type QueryRefereeArgs = {
  refereeId: Scalars['ID'];
};


export type QueryRefereesForStatsArgs = {
  departmentId?: Maybe<Scalars['ID']>;
};


export type QuerySearchRefereesArgs = {
  search: Scalars['String'];
  matchId: Scalars['ID'];
};


export type QueryTrainingArgs = {
  trainingId: Scalars['ID'];
};


export type QueryTrainingsArgs = {
  year: Scalars['Int'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type QueryTrainingsByIdsArgs = {
  trainingIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryTrainerRequestArgs = {
  token: Scalars['String'];
};


export type QueryTraineeRequestArgs = {
  token: Scalars['String'];
};


export type QueryTrainerArgs = {
  trainerId: Scalars['ID'];
};


export type QueryTrainersForStatsArgs = {
  departmentId?: Maybe<Scalars['ID']>;
};


export type QuerySearchTrainersArgs = {
  search: Scalars['String'];
  trainingId: Scalars['ID'];
};


export type QueryTraineeArgs = {
  traineeId: Scalars['ID'];
};


export type QueryTraineesForStatsArgs = {
  departmentId?: Maybe<Scalars['ID']>;
};


export type QuerySearchTraineesArgs = {
  search: Scalars['String'];
  trainingId: Scalars['ID'];
};


export type QueryMeetingArgs = {
  meetingId: Scalars['ID'];
};


export type QueryMeetingsArgs = {
  year: Scalars['Int'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type QueryMeetingsByIdsArgs = {
  meetingIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryMemberRequestArgs = {
  token: Scalars['String'];
};


export type QueryMemberArgs = {
  memberId: Scalars['ID'];
};


export type QueryMembersForStatsArgs = {
  departmentId?: Maybe<Scalars['ID']>;
};


export type QueryMembersArgs = {
  groupe?: Maybe<Scalars['String']>;
};


export type QuerySearchMembersArgs = {
  search: Scalars['String'];
  meetingId: Scalars['ID'];
};


export type QueryVolunteerArgs = {
  volunteerId: Scalars['ID'];
};


export type QueryVolunteersForStatsArgs = {
  departmentId?: Maybe<Scalars['ID']>;
};


export type QuerySearchVolunteersArgs = {
  search: Scalars['String'];
  matchId: Scalars['ID'];
};

export type Referee = {
  id: Scalars['ID'];
  league: League;
  department?: Maybe<Department>;
  licence: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  sexe: Scalars['String'];
  clubNumber: Scalars['String'];
  clubName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  disciplines: Array<RefereeDiscipline>;
  matches: Array<MatchReferee>;
  enabled: Scalars['Boolean'];
  totalInvitationCount: Scalars['Int'];
  invitationWithoutResponseCount: Scalars['Int'];
};


export type RefereeMatchesArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type RefereeTotalInvitationCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type RefereeInvitationWithoutResponseCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};

export type RefereeDiscipline = {
  id: Scalars['ID'];
  discipline: Discipline;
  level: Scalars['String'];
  obtentionDate: Scalars['String'];
  validityDate: Scalars['String'];
};

export type RefereeRequest = {
  id: Scalars['ID'];
  requestMatches: Array<RefereeRequestMatch>;
  referee: Referee;
  message: RequestMessage;
  responded: Scalars['Boolean'];
};

export type RefereeRequestMatch = {
  id: Scalars['ID'];
  match: Match;
  referee: Referee;
  matchRefereeRoute: MatchRefereeRoute;
  lastResponse?: Maybe<MatchResponse>;
  createdAt: Scalars['String'];
};

export type RelaunchMemberRequestInput = {
  memberRequestMeetingId: Scalars['ID'];
};

export type RelaunchMemberRequestPayload = {
  success: Scalars['Boolean'];
};

export type RelaunchRefereeRequestInput = {
  refereeRequestMatchId: Scalars['ID'];
};

export type RelaunchRefereeRequestPayload = {
  success: Scalars['Boolean'];
};

export type RelaunchTraineeRequestInput = {
  traineeRequestTrainingId: Scalars['ID'];
};

export type RelaunchTraineeRequestPayload = {
  success: Scalars['Boolean'];
};

export type RelaunchTrainerRequestInput = {
  trainerRequestTrainingId: Scalars['ID'];
};

export type RelaunchTrainerRequestPayload = {
  success: Scalars['Boolean'];
};

export type RelaunchVolunteerRequestInput = {
  volunteerRequestMatchId: Scalars['ID'];
};

export type RelaunchVolunteerRequestPayload = {
  success: Scalars['Boolean'];
};

export type RemoveAdminInput = {
  userId: Scalars['ID'];
};

export type RemoveAdminPayload = {
  success: Scalars['Boolean'];
};

export type RemoveCompetitionInput = {
  competitionId: Scalars['ID'];
};

export type RemoveCompetitionPayload = {
  success: Scalars['Boolean'];
};

export type RemoveMatchInput = {
  matchId: Scalars['ID'];
};

export type RemoveMatchPayload = {
  success: Scalars['Boolean'];
};

export type RemoveMatchRefereeInput = {
  matchRefereeId: Scalars['ID'];
};

export type RemoveMatchRefereePayload = {
  success: Scalars['Boolean'];
};

export type RemoveMatchVolunteerInput = {
  matchVolunteerId: Scalars['ID'];
};

export type RemoveMatchVolunteerPayload = {
  success: Scalars['Boolean'];
};

export type RemoveMeetingInput = {
  meetingId: Scalars['ID'];
};

export type RemoveMeetingMemberInput = {
  meetingMemberId: Scalars['ID'];
};

export type RemoveMeetingMemberPayload = {
  success: Scalars['Boolean'];
};

export type RemoveMeetingPayload = {
  success: Scalars['Boolean'];
};

export type RemoveTraineeCategoryInput = {
  traineeCategoryId: Scalars['ID'];
};

export type RemoveTraineeCategoryPayload = {
  success: Scalars['Boolean'];
};

export type RemoveTrainingInput = {
  trainingId: Scalars['ID'];
};

export type RemoveTrainingPayload = {
  success: Scalars['Boolean'];
};

export type RemoveTrainingTraineeInput = {
  trainingTraineeId: Scalars['ID'];
};

export type RemoveTrainingTraineePayload = {
  success: Scalars['Boolean'];
};

export type RemoveTrainingTrainerInput = {
  trainingTrainerId: Scalars['ID'];
};

export type RemoveTrainingTrainerPayload = {
  success: Scalars['Boolean'];
};

export type RemoveUserInput = {
  userId: Scalars['ID'];
};

export type RemoveUserPayload = {
  success: Scalars['Boolean'];
};

export type RequestMessage = {
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type RequestResetPasswordInput = {
  email: Scalars['String'];
};

export type RequestResetPasswordPayload = {
  success: Scalars['Boolean'];
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type ResetPasswordPayload = {
  success: Scalars['Boolean'];
};

export type RespondToMatchRequestInput = {
  token: Scalars['String'];
  responses: Array<MatchResponseInput>;
};

export type RespondToMatchRequestPayload = {
  success: Scalars['Boolean'];
};

export type RespondToMatchVolunteerRequestInput = {
  token: Scalars['String'];
  responses: Array<MatchVolunteerResponseInput>;
};

export type RespondToMatchVolunteerRequestPayload = {
  success: Scalars['Boolean'];
};

export type RespondToMeetingRequestInput = {
  token: Scalars['String'];
  responses: Array<MeetingResponseInput>;
};

export type RespondToMeetingRequestPayload = {
  success: Scalars['Boolean'];
};

export type RespondToTrainingRequestInput = {
  token: Scalars['String'];
  responses: Array<TrainingResponseInput>;
};

export type RespondToTrainingRequestPayload = {
  success: Scalars['Boolean'];
};

export type RespondToTrainingTraineeRequestInput = {
  token: Scalars['String'];
  responses: Array<TrainingTraineeResponseInput>;
};

export type RespondToTrainingTraineeRequestPayload = {
  success: Scalars['Boolean'];
};

export type SendMatchRefereeEmailInput = {
  matchId: Scalars['ID'];
  message: Scalars['String'];
  includeRefereeResume: Scalars['Boolean'];
  file?: Maybe<Scalars['Upload']>;
};

export type SendMatchRefereeEmailPayload = {
  success: Scalars['Boolean'];
};

export type SendMatchRequestInput = {
  message: Scalars['String'];
  matchIds: Array<Scalars['ID']>;
  refereeIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendMatchRequestPayload = {
  success: Scalars['Boolean'];
};

export type SendMatchsToRefereesInput = {
  message: Scalars['String'];
  matchIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendMatchsToRefereesPayload = {
  success: Scalars['Boolean'];
};

export type SendMatchsToVolunteersInput = {
  message: Scalars['String'];
  matchIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendMatchsToVolunteersPayload = {
  success: Scalars['Boolean'];
};

export type SendMatchVolunteerEmailInput = {
  matchId: Scalars['ID'];
  message: Scalars['String'];
  includeVolunteerResume: Scalars['Boolean'];
  file?: Maybe<Scalars['Upload']>;
};

export type SendMatchVolunteerEmailPayload = {
  success: Scalars['Boolean'];
};

export type SendMatchVolunteerRequestInput = {
  message: Scalars['String'];
  matchIds: Array<Scalars['ID']>;
  volunteerIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendMatchVolunteerRequestPayload = {
  success: Scalars['Boolean'];
};

export type SendMeetingMemberEmailInput = {
  meetingId: Scalars['ID'];
  message: Scalars['String'];
  includeMemberResume: Scalars['Boolean'];
  file?: Maybe<Scalars['Upload']>;
};

export type SendMeetingMemberEmailPayload = {
  success: Scalars['Boolean'];
};

export type SendMeetingRequestInput = {
  message: Scalars['String'];
  meetingIds: Array<Scalars['ID']>;
  memberIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendMeetingRequestPayload = {
  success: Scalars['Boolean'];
};

export type SendMeetingsToMembersInput = {
  message: Scalars['String'];
  meetingIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendMeetingsToMembersPayload = {
  success: Scalars['Boolean'];
};

export type SendTrainingRequestInput = {
  message: Scalars['String'];
  trainingIds: Array<Scalars['ID']>;
  trainerIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendTrainingRequestPayload = {
  success: Scalars['Boolean'];
};

export type SendTrainingsToTraineesInput = {
  message: Scalars['String'];
  trainingIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendTrainingsToTraineesPayload = {
  success: Scalars['Boolean'];
};

export type SendTrainingsToTrainersInput = {
  message: Scalars['String'];
  trainingIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendTrainingsToTrainersPayload = {
  success: Scalars['Boolean'];
};

export type SendTrainingTraineeEmailInput = {
  trainingId: Scalars['ID'];
  message: Scalars['String'];
  includeTraineeResume: Scalars['Boolean'];
  file?: Maybe<Scalars['Upload']>;
};

export type SendTrainingTraineeEmailPayload = {
  success: Scalars['Boolean'];
};

export type SendTrainingTraineeRequestInput = {
  message: Scalars['String'];
  trainingIds: Array<Scalars['ID']>;
  traineeIds: Array<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
};

export type SendTrainingTraineeRequestPayload = {
  success: Scalars['Boolean'];
};

export type SendTrainingTrainerEmailInput = {
  trainingId: Scalars['ID'];
  message: Scalars['String'];
  includeTrainerResume: Scalars['Boolean'];
  file?: Maybe<Scalars['Upload']>;
};

export type SendTrainingTrainerEmailPayload = {
  success: Scalars['Boolean'];
};

export type Trainee = {
  id: Scalars['ID'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  category?: Maybe<TraineeCategory>;
  enabled: Scalars['Boolean'];
  trainings: Array<TrainingTrainee>;
  totalInvitationCount: Scalars['Int'];
  invitationWithoutResponseCount: Scalars['Int'];
};


export type TraineeTrainingsArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type TraineeTotalInvitationCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type TraineeInvitationWithoutResponseCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};

export type TraineeCategory = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TraineeRequest = {
  id: Scalars['ID'];
  requestTrainings: Array<TraineeRequestTraining>;
  trainee: Trainee;
  message: RequestMessage;
  responded: Scalars['Boolean'];
};

export type TraineeRequestTraining = {
  id: Scalars['ID'];
  training: Training;
  trainee: Trainee;
  lastResponse?: Maybe<TrainingTraineeResponse>;
  createdAt: Scalars['String'];
};

export type Trainer = {
  id: Scalars['ID'];
  league: League;
  department?: Maybe<Department>;
  licence: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  sexe: Scalars['String'];
  clubNumber: Scalars['String'];
  clubName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  trainings: Array<TrainingTrainer>;
  enabled: Scalars['Boolean'];
  totalInvitationCount: Scalars['Int'];
  invitationWithoutResponseCount: Scalars['Int'];
};


export type TrainerTrainingsArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type TrainerTotalInvitationCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type TrainerInvitationWithoutResponseCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};

export type TrainerRequest = {
  id: Scalars['ID'];
  requestTrainings: Array<TrainerRequestTraining>;
  trainer: Trainer;
  message: RequestMessage;
  responded: Scalars['Boolean'];
};

export type TrainerRequestTraining = {
  id: Scalars['ID'];
  training: Training;
  trainer: Trainer;
  trainingTrainerRoute: TrainingTrainerRoute;
  lastResponse?: Maybe<TrainingResponse>;
  createdAt: Scalars['String'];
};

export type Training = {
  id: Scalars['ID'];
  league: League;
  department?: Maybe<Department>;
  title: Scalars['String'];
  level: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  trainingDates: Array<TrainingDate>;
  lastTimeRequest?: Maybe<Scalars['String']>;
  trainingTrainers: Array<TrainingTrainer>;
  trainingResponses: Array<TrainingResponse>;
  trainingTrainees: Array<TrainingTrainee>;
  trainingTraineeResponses: Array<TrainingTraineeResponse>;
  prices?: Maybe<LeaguePrices>;
  trainersRequest: Array<TrainerRequestTraining>;
  traineesRequest: Array<TraineeRequestTraining>;
};

export type TrainingDate = {
  id: Scalars['ID'];
  date: Scalars['String'];
  requiredCount: Scalars['Int'];
  startHour: Scalars['String'];
  endHour: Scalars['String'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
};

export type TrainingDateResponse = {
  id: Scalars['ID'];
  trainingDate: TrainingDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type TrainingDateResponseInput = {
  trainingDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type TrainingDateTrainee = {
  id: Scalars['ID'];
  trainingDate: TrainingDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  role: Scalars['String'];
  informations: Scalars['String'];
};

export type TrainingDateTraineeResponse = {
  id: Scalars['ID'];
  trainingDate: TrainingDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type TrainingDateTrainer = {
  id: Scalars['ID'];
  trainingDate: TrainingDate;
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  role: Scalars['String'];
  informations: Scalars['String'];
};

export type TrainingResponse = {
  id: Scalars['ID'];
  training: Training;
  trainer: Trainer;
  accepted: Scalars['Boolean'];
  informations: Scalars['String'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  createdAt: Scalars['String'];
  trainingDateResponses: Array<TrainingDateResponse>;
  trainingTrainerRoute: TrainingTrainerRoute;
};

export type TrainingResponseInput = {
  trainingId: Scalars['ID'];
  accepted: Scalars['Boolean'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  informations: Scalars['String'];
  trainingDates: Array<TrainingDateResponseInput>;
};

export type TrainingsToTraineesPayload = {
  trainee: Trainee;
  doc: Scalars['String'];
};

export type TrainingsToTrainersPayload = {
  trainer: Trainer;
  doc: Scalars['String'];
};

export type TrainingTrainee = {
  id: Scalars['ID'];
  training: Training;
  trainee: Trainee;
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  trainingDateTrainees: Array<TrainingDateTrainee>;
};

export type TrainingTraineeDateResponseInput = {
  trainingDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
};

export type TrainingTraineeResponse = {
  id: Scalars['ID'];
  training: Training;
  trainee: Trainee;
  accepted: Scalars['Boolean'];
  informations: Scalars['String'];
  eveHotel: Scalars['Boolean'];
  createdAt: Scalars['String'];
  trainingDateResponses: Array<TrainingDateTraineeResponse>;
};

export type TrainingTraineeResponseInput = {
  trainingId: Scalars['ID'];
  accepted: Scalars['Boolean'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  trainingDates: Array<TrainingTraineeDateResponseInput>;
};

export type TrainingTrainer = {
  id: Scalars['ID'];
  training: Training;
  trainer: Trainer;
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  informations: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  trainingDateTrainers: Array<TrainingDateTrainer>;
  trainingTrainerRoute: TrainingTrainerRoute;
};

export type TrainingTrainerRoute = {
  id: Scalars['ID'];
  training: Training;
  trainer: Trainer;
  to: Scalars['Float'];
  from: Scalars['Float'];
};

export type UpdateAdminInput = {
  userId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export type UpdateAdminPayload = {
  user: User;
};

export type UpdateEmailInput = {
  email: Scalars['String'];
};

export type UpdateEmailPayload = {
  user: User;
};

export type UpdateLeaguePricesInput = {
  year: Scalars['Int'];
  pricePerKm: Scalars['Float'];
  pricePerLunch: Scalars['Float'];
  pricePerDinner: Scalars['Float'];
  pricePerHotel: Scalars['Float'];
};

export type UpdateLeaguePricesPayload = {
  leaguePrices: LeaguePrices;
};

export type UpdateMatchDateInput = {
  date: Scalars['String'];
  requiredCount: Scalars['Int'];
  startHour: Scalars['String'];
  endHour: Scalars['String'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
};

export type UpdateMatchDateRefereeInput = {
  matchDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type UpdateMatchDateResponsibleInput = {
  matchDateId: Scalars['ID'];
  matchDateRefereeId?: Maybe<Scalars['ID']>;
};

export type UpdateMatchDateResponsiblePayload = {
  matchDate: MatchDate;
};

export type UpdateMatchDateVolunteerInput = {
  matchDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type UpdateMatchInput = {
  matchId: Scalars['ID'];
  title: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  competitions: Array<Scalars['ID']>;
  dates: Array<UpdateMatchDateInput>;
};

export type UpdateMatchLocationInput = {
  matchId: Scalars['ID'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type UpdateMatchLocationPayload = {
  match: Match;
};

export type UpdateMatchPayload = {
  match: Match;
};

export type UpdateMatchRefereeDisciplineInput = {
  matchRefereeId: Scalars['ID'];
  refereeDisciplineId?: Maybe<Scalars['ID']>;
};

export type UpdateMatchRefereeDisciplinePayload = {
  matchReferee: MatchReferee;
};

export type UpdateMatchRefereeInput = {
  matchRefereeId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  informations: Scalars['String'];
  matchDates: Array<UpdateMatchDateRefereeInput>;
};

export type UpdateMatchRefereePayload = {
  matchReferee: MatchReferee;
};

export type UpdateMatchRefereeRouteInput = {
  matchRefereeRouteId: Scalars['ID'];
  toDistance: Scalars['Float'];
  fromDistance: Scalars['Float'];
};

export type UpdateMatchRefereeRoutePayload = {
  matchRefereeRoute: MatchRefereeRoute;
};

export type UpdateMatchVolunteerInput = {
  matchVolunteerId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  matchDates: Array<UpdateMatchDateVolunteerInput>;
};

export type UpdateMatchVolunteerPayload = {
  matchVolunteer: MatchVolunteer;
};

export type UpdateMeetingDateInput = {
  date: Scalars['String'];
  requiredCount: Scalars['Int'];
  startHour: Scalars['String'];
  endHour: Scalars['String'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
};

export type UpdateMeetingDateMemberInput = {
  meetingDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type UpdateMeetingInput = {
  meetingId: Scalars['ID'];
  title: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  dates: Array<UpdateMeetingDateInput>;
};

export type UpdateMeetingLocationInput = {
  meetingId: Scalars['ID'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type UpdateMeetingLocationPayload = {
  meeting: Meeting;
};

export type UpdateMeetingMemberInput = {
  meetingMemberId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  informations: Scalars['String'];
  meetingDates: Array<UpdateMeetingDateMemberInput>;
};

export type UpdateMeetingMemberPayload = {
  meetingMember: MeetingMember;
};

export type UpdateMeetingMemberRouteInput = {
  meetingMemberRouteId: Scalars['ID'];
  toDistance: Scalars['Float'];
  fromDistance: Scalars['Float'];
};

export type UpdateMeetingMemberRoutePayload = {
  meetingMemberRoute: MeetingMemberRoute;
};

export type UpdateMeetingPayload = {
  meeting: Meeting;
};

export type UpdateTraineeInput = {
  traineeId: Scalars['ID'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  traineeCategoryId?: Maybe<Scalars['ID']>;
};

export type UpdateTraineePayload = {
  trainee: Trainee;
};

export type UpdateTrainingDateInput = {
  date: Scalars['String'];
  requiredCount: Scalars['Int'];
  startHour: Scalars['String'];
  endHour: Scalars['String'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
};

export type UpdateTrainingDateTraineeInput = {
  trainingDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type UpdateTrainingDateTrainerInput = {
  trainingDateId: Scalars['ID'];
  lunch: Scalars['Boolean'];
  dinner: Scalars['Boolean'];
  hotel: Scalars['Boolean'];
  informations: Scalars['String'];
  role: Scalars['String'];
};

export type UpdateTrainingInput = {
  trainingId: Scalars['ID'];
  title: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  dates: Array<UpdateTrainingDateInput>;
};

export type UpdateTrainingLocationInput = {
  trainingId: Scalars['ID'];
  address: Scalars['String'];
  zipCode: Scalars['String'];
  city: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type UpdateTrainingLocationPayload = {
  training: Training;
};

export type UpdateTrainingPayload = {
  training: Training;
};

export type UpdateTrainingTraineeInput = {
  trainingTraineeId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  informations: Scalars['String'];
  trainingDates: Array<UpdateTrainingDateTraineeInput>;
};

export type UpdateTrainingTraineePayload = {
  trainingTrainee: TrainingTrainee;
};

export type UpdateTrainingTrainerInput = {
  trainingTrainerId: Scalars['ID'];
  eveHotel: Scalars['Boolean'];
  noKmsFees: Scalars['Boolean'];
  freeHosting: Scalars['Boolean'];
  informations: Scalars['String'];
  trainingDates: Array<UpdateTrainingDateTrainerInput>;
};

export type UpdateTrainingTrainerPayload = {
  trainingTrainer: TrainingTrainer;
};

export type UpdateTrainingTrainerRouteInput = {
  trainingTrainerRouteId: Scalars['ID'];
  toDistance: Scalars['Float'];
  fromDistance: Scalars['Float'];
};

export type UpdateTrainingTrainerRoutePayload = {
  trainingTrainerRoute: TrainingTrainerRoute;
};

export type UpdateUserInput = {
  userId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  permissions: UpdateUserPermissionsInput;
};

export type UpdateUserPayload = {
  user: User;
};

export type UpdateUserPermissionsInput = {
  matchRead: Scalars['Boolean'];
  matchAdd: Scalars['Boolean'];
  matchUpdate: Scalars['Boolean'];
  matchManageReferee: Scalars['Boolean'];
  trainingRead: Scalars['Boolean'];
  trainingAdd: Scalars['Boolean'];
  trainingUpdate: Scalars['Boolean'];
  trainingManageTrainer: Scalars['Boolean'];
  meetingRead: Scalars['Boolean'];
  meetingAdd: Scalars['Boolean'];
  meetingUpdate: Scalars['Boolean'];
  meetingManageMember: Scalars['Boolean'];
};

export type UpdateVolunteerInput = {
  volunteerId: Scalars['ID'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type UpdateVolunteerPayload = {
  volunteer: Volunteer;
};


export type User = {
  id: Scalars['ID'];
  league: League;
  department?: Maybe<Department>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  admin: Scalars['Boolean'];
  permissions: Array<Scalars['String']>;
  email: Scalars['String'];
};

export type Volunteer = {
  id: Scalars['ID'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  enabled: Scalars['Boolean'];
  matches: Array<MatchVolunteer>;
  totalInvitationCount: Scalars['Int'];
  invitationWithoutResponseCount: Scalars['Int'];
};


export type VolunteerMatchesArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type VolunteerTotalInvitationCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type VolunteerInvitationWithoutResponseCountArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};

export type VolunteerRequest = {
  id: Scalars['ID'];
  requestMatches: Array<VolunteerRequestMatch>;
  volunteer: Volunteer;
  message: RequestMessage;
  responded: Scalars['Boolean'];
};

export type VolunteerRequestMatch = {
  id: Scalars['ID'];
  match: Match;
  volunteer: Volunteer;
  lastResponse?: Maybe<MatchVolunteerResponse>;
  createdAt: Scalars['String'];
};

export type AddAdminMutationVariables = {
  input: AddAdminInput;
};


export type AddAdminMutation = { addAdmin: { user: Pick<User, 'id'> } };

export type AddCompetitionMutationVariables = {
  input: AddCompetitionInput;
};


export type AddCompetitionMutation = { addCompetition: Pick<AddCompetitionPayload, 'success'> };

export type AddMatchMutationVariables = {
  input: AddMatchInput;
};


export type AddMatchMutation = { addMatch: { match: Pick<Match, 'id'> } };

export type AddMatchRefereeMutationVariables = {
  input: AddMatchRefereeInput;
};


export type AddMatchRefereeMutation = { addMatchReferee: { matchReferee: Pick<MatchReferee, 'id'> } };

export type AddMatchVolunteerMutationVariables = {
  input: AddMatchVolunteerInput;
};


export type AddMatchVolunteerMutation = { addMatchVolunteer: { matchVolunteer: Pick<MatchVolunteer, 'id'> } };

export type AddMeetingMutationVariables = {
  input: AddMeetingInput;
};


export type AddMeetingMutation = { addMeeting: { meeting: Pick<Meeting, 'id'> } };

export type AddMeetingMemberMutationVariables = {
  input: AddMeetingMemberInput;
};


export type AddMeetingMemberMutation = { addMeetingMember: { meetingMember: Pick<MeetingMember, 'id'> } };

export type AddTraineeMutationVariables = {
  input: AddTraineeInput;
};


export type AddTraineeMutation = { addTrainee: Pick<AddTraineePayload, 'success'> };

export type AddTraineeCategoryMutationVariables = {
  input: AddTraineeCategoryInput;
};


export type AddTraineeCategoryMutation = { addTraineeCategory: Pick<AddTraineeCategoryPayload, 'success'> };

export type AddTrainingMutationVariables = {
  input: AddTrainingInput;
};


export type AddTrainingMutation = { addTraining: { training: Pick<Training, 'id'> } };

export type AddTrainingTraineeMutationVariables = {
  input: AddTrainingTraineeInput;
};


export type AddTrainingTraineeMutation = { addTrainingTrainee: { trainingTrainee: Pick<TrainingTrainee, 'id'> } };

export type AddTrainingTrainerMutationVariables = {
  input: AddTrainingTrainerInput;
};


export type AddTrainingTrainerMutation = { addTrainingTrainer: { trainingTrainer: Pick<TrainingTrainer, 'id'> } };

export type AddUserMutationVariables = {
  input: AddUserInput;
};


export type AddUserMutation = { addUser: { user: Pick<User, 'id'> } };

export type AddVolunteerMutationVariables = {
  input: AddVolunteerInput;
};


export type AddVolunteerMutation = { addVolunteer: Pick<AddVolunteerPayload, 'success'> };

export type DisableTraineeMutationVariables = {
  input: DisableTraineeInput;
};


export type DisableTraineeMutation = { disableTrainee: { trainee: Pick<Trainee, 'id' | 'enabled'> } };

export type DisableVolunteerMutationVariables = {
  input: DisableVolunteerInput;
};


export type DisableVolunteerMutation = { disableVolunteer: { volunteer: Pick<Volunteer, 'id' | 'enabled'> } };

export type EnableTraineeMutationVariables = {
  input: EnableTraineeInput;
};


export type EnableTraineeMutation = { enableTrainee: { trainee: Pick<Trainee, 'id' | 'enabled'> } };

export type EnableVolunteerMutationVariables = {
  input: EnableVolunteerInput;
};


export type EnableVolunteerMutation = { enableVolunteer: { volunteer: Pick<Volunteer, 'id' | 'enabled'> } };

export type GenerateMatchsToRefereesMutationVariables = {
  input: GenerateMatchsToRefereesInput;
};


export type GenerateMatchsToRefereesMutation = { generateMatchsToReferees: { data: Array<(
      Pick<MatchsToRefereesPayload, 'doc'>
      & { referee: Pick<Referee, 'id' | 'firstName' | 'lastName'> }
    )> } };

export type GenerateMatchsToVolunteersMutationVariables = {
  input: GenerateMatchsToVolunteersInput;
};


export type GenerateMatchsToVolunteersMutation = { generateMatchsToVolunteers: { data: Array<(
      Pick<MatchsToVolunteersPayload, 'doc'>
      & { volunteer: Pick<Referee, 'id' | 'firstName' | 'lastName'> }
    )> } };

export type GenerateMeetingsToMembersMutationVariables = {
  input: GenerateMeetingsToMembersInput;
};


export type GenerateMeetingsToMembersMutation = { generateMeetingsToMembers: { data: Array<(
      Pick<MeetingsToMembersPayload, 'doc'>
      & { member: Pick<Member, 'id' | 'firstName' | 'lastName'> }
    )> } };

export type GenerateTrainingsToTraineesMutationVariables = {
  input: GenerateTrainingsToTraineesInput;
};


export type GenerateTrainingsToTraineesMutation = { generateTrainingsToTrainees: { data: Array<(
      Pick<TrainingsToTraineesPayload, 'doc'>
      & { trainee: Pick<Trainee, 'id' | 'firstName' | 'lastName'> }
    )> } };

export type GenerateTrainingsToTrainersMutationVariables = {
  input: GenerateTrainingsToTrainersInput;
};


export type GenerateTrainingsToTrainersMutation = { generateTrainingsToTrainers: { data: Array<(
      Pick<TrainingsToTrainersPayload, 'doc'>
      & { trainer: Pick<Trainer, 'id' | 'firstName' | 'lastName'> }
    )> } };

export type ImportMembersMutationVariables = {
  input: ImportMembersInput;
};


export type ImportMembersMutation = { importMembers: Pick<ImportMembersPayload, 'success' | 'addedCount' | 'updatedCount' | 'removedCount'> };

export type ImportRefereesMutationVariables = {
  input: ImportRefereesInput;
};


export type ImportRefereesMutation = { importReferees: Pick<ImportRefereesPayload, 'success' | 'addedCount' | 'updatedCount' | 'removedCount'> };

export type ImportTrainersMutationVariables = {
  input: ImportTrainersInput;
};


export type ImportTrainersMutation = { importTrainers: Pick<ImporTrainersPayload, 'success' | 'addedCount' | 'updatedCount' | 'removedCount'> };

export type LoginMutationVariables = {
  input: LoginInput;
};


export type LoginMutation = { login: Pick<LoginPayload, 'success'> };

export type LogoutMutationVariables = {};


export type LogoutMutation = { logout: Pick<LogoutPayload, 'success'> };

export type RelaunchMemberRequestMutationVariables = {
  input: RelaunchMemberRequestInput;
};


export type RelaunchMemberRequestMutation = { relaunchMemberRequest: Pick<RelaunchMemberRequestPayload, 'success'> };

export type RelaunchRefereeRequestMutationVariables = {
  input: RelaunchRefereeRequestInput;
};


export type RelaunchRefereeRequestMutation = { relaunchRefereeRequest: Pick<RelaunchRefereeRequestPayload, 'success'> };

export type RelaunchTraineeRequestMutationVariables = {
  input: RelaunchTraineeRequestInput;
};


export type RelaunchTraineeRequestMutation = { relaunchTraineeRequest: Pick<RelaunchTraineeRequestPayload, 'success'> };

export type RelaunchTrainerRequestMutationVariables = {
  input: RelaunchTrainerRequestInput;
};


export type RelaunchTrainerRequestMutation = { relaunchTrainerRequest: Pick<RelaunchTrainerRequestPayload, 'success'> };

export type RelaunchVolunteerRequestMutationVariables = {
  input: RelaunchVolunteerRequestInput;
};


export type RelaunchVolunteerRequestMutation = { relaunchVolunteerRequest: Pick<RelaunchVolunteerRequestPayload, 'success'> };

export type RemoveAdminMutationVariables = {
  input: RemoveAdminInput;
};


export type RemoveAdminMutation = { removeAdmin: Pick<RemoveAdminPayload, 'success'> };

export type RemoveCompetitionMutationVariables = {
  input: RemoveCompetitionInput;
};


export type RemoveCompetitionMutation = { removeCompetition: Pick<RemoveCompetitionPayload, 'success'> };

export type RemoveMatchMutationVariables = {
  input: RemoveMatchInput;
};


export type RemoveMatchMutation = { removeMatch: Pick<RemoveMatchPayload, 'success'> };

export type RemoveMatchRefereeMutationVariables = {
  input: RemoveMatchRefereeInput;
};


export type RemoveMatchRefereeMutation = { removeMatchReferee: Pick<RemoveMatchRefereePayload, 'success'> };

export type RemoveMatchVolunteerMutationVariables = {
  input: RemoveMatchVolunteerInput;
};


export type RemoveMatchVolunteerMutation = { removeMatchVolunteer: Pick<RemoveMatchVolunteerPayload, 'success'> };

export type RemoveMeetingMutationVariables = {
  input: RemoveMeetingInput;
};


export type RemoveMeetingMutation = { removeMeeting: Pick<RemoveMeetingPayload, 'success'> };

export type RemoveMeetingMemberMutationVariables = {
  input: RemoveMeetingMemberInput;
};


export type RemoveMeetingMemberMutation = { removeMeetingMember: Pick<RemoveMeetingMemberPayload, 'success'> };

export type RemoveTraineeCategoryMutationVariables = {
  input: RemoveTraineeCategoryInput;
};


export type RemoveTraineeCategoryMutation = { removeTraineeCategory: Pick<RemoveTraineeCategoryPayload, 'success'> };

export type RemoveTrainingMutationVariables = {
  input: RemoveTrainingInput;
};


export type RemoveTrainingMutation = { removeTraining: Pick<RemoveTrainingPayload, 'success'> };

export type RemoveTrainingTraineeMutationVariables = {
  input: RemoveTrainingTraineeInput;
};


export type RemoveTrainingTraineeMutation = { removeTrainingTrainee: Pick<RemoveTrainingTraineePayload, 'success'> };

export type RemoveTrainingTrainerMutationVariables = {
  input: RemoveTrainingTrainerInput;
};


export type RemoveTrainingTrainerMutation = { removeTrainingTrainer: Pick<RemoveTrainingTrainerPayload, 'success'> };

export type RemoveUserMutationVariables = {
  input: RemoveUserInput;
};


export type RemoveUserMutation = { removeUser: Pick<RemoveUserPayload, 'success'> };

export type RequestResetPasswordMutationVariables = {
  input: RequestResetPasswordInput;
};


export type RequestResetPasswordMutation = { requestResetPassword: Pick<RequestResetPasswordPayload, 'success'> };

export type ResetPasswordMutationVariables = {
  input: ResetPasswordInput;
};


export type ResetPasswordMutation = { resetPassword: Pick<ResetPasswordPayload, 'success'> };

export type RespondToMatchRequestMutationVariables = {
  input: RespondToMatchRequestInput;
};


export type RespondToMatchRequestMutation = { respondToMatchRequest: Pick<RespondToMatchRequestPayload, 'success'> };

export type RespondToMatchVolunteerRequestMutationVariables = {
  input: RespondToMatchVolunteerRequestInput;
};


export type RespondToMatchVolunteerRequestMutation = { respondToMatchVolunteerRequest: Pick<RespondToMatchVolunteerRequestPayload, 'success'> };

export type RespondToMeetingRequestMutationVariables = {
  input: RespondToMeetingRequestInput;
};


export type RespondToMeetingRequestMutation = { respondToMeetingRequest: Pick<RespondToMeetingRequestPayload, 'success'> };

export type RespondToTrainingRequestMutationVariables = {
  input: RespondToTrainingRequestInput;
};


export type RespondToTrainingRequestMutation = { respondToTrainingRequest: Pick<RespondToTrainingRequestPayload, 'success'> };

export type RespondToTrainingTraineeRequestMutationVariables = {
  input: RespondToTrainingTraineeRequestInput;
};


export type RespondToTrainingTraineeRequestMutation = { respondToTrainingTraineeRequest: Pick<RespondToTrainingTraineeRequestPayload, 'success'> };

export type SendMatchRefereeEmailMutationVariables = {
  input: SendMatchRefereeEmailInput;
};


export type SendMatchRefereeEmailMutation = { sendMatchRefereeEmail: Pick<SendMatchRefereeEmailPayload, 'success'> };

export type SendMatchRequestMutationVariables = {
  input: SendMatchRequestInput;
};


export type SendMatchRequestMutation = { sendMatchRequest: Pick<SendMatchRequestPayload, 'success'> };

export type SendMatchVolunteerEmailMutationVariables = {
  input: SendMatchVolunteerEmailInput;
};


export type SendMatchVolunteerEmailMutation = { sendMatchVolunteerEmail: Pick<SendMatchVolunteerEmailPayload, 'success'> };

export type SendMatchVolunteerRequestMutationVariables = {
  input: SendMatchVolunteerRequestInput;
};


export type SendMatchVolunteerRequestMutation = { sendMatchVolunteerRequest: Pick<SendMatchVolunteerRequestPayload, 'success'> };

export type SendMatchsToRefereesMutationVariables = {
  input: SendMatchsToRefereesInput;
};


export type SendMatchsToRefereesMutation = { sendMatchsToReferees: Pick<SendMatchsToRefereesPayload, 'success'> };

export type SendMatchsToVolunteersMutationVariables = {
  input: SendMatchsToVolunteersInput;
};


export type SendMatchsToVolunteersMutation = { sendMatchsToVolunteers: Pick<SendMatchsToVolunteersPayload, 'success'> };

export type SendMeetingMemberEmailMutationVariables = {
  input: SendMeetingMemberEmailInput;
};


export type SendMeetingMemberEmailMutation = { sendMeetingMemberEmail: Pick<SendMeetingMemberEmailPayload, 'success'> };

export type SendMeetingRequestMutationVariables = {
  input: SendMeetingRequestInput;
};


export type SendMeetingRequestMutation = { sendMeetingRequest: Pick<SendMeetingRequestPayload, 'success'> };

export type SendMeetingsToMembersMutationVariables = {
  input: SendMeetingsToMembersInput;
};


export type SendMeetingsToMembersMutation = { sendMeetingsToMembers: Pick<SendMeetingsToMembersPayload, 'success'> };

export type SendTrainingRequestMutationVariables = {
  input: SendTrainingRequestInput;
};


export type SendTrainingRequestMutation = { sendTrainingRequest: Pick<SendTrainingRequestPayload, 'success'> };

export type SendTrainingTraineeEmailMutationVariables = {
  input: SendTrainingTraineeEmailInput;
};


export type SendTrainingTraineeEmailMutation = { sendTrainingTraineeEmail: Pick<SendTrainingTraineeEmailPayload, 'success'> };

export type SendTrainingTraineeRequestMutationVariables = {
  input: SendTrainingTraineeRequestInput;
};


export type SendTrainingTraineeRequestMutation = { sendTrainingTraineeRequest: Pick<SendTrainingTraineeRequestPayload, 'success'> };

export type SendTrainingTrainerEmailMutationVariables = {
  input: SendTrainingTrainerEmailInput;
};


export type SendTrainingTrainerEmailMutation = { sendTrainingTrainerEmail: Pick<SendTrainingTrainerEmailPayload, 'success'> };

export type SendTrainingsToTraineesMutationVariables = {
  input: SendTrainingsToTraineesInput;
};


export type SendTrainingsToTraineesMutation = { sendTrainingsToTrainees: Pick<SendTrainingsToTraineesPayload, 'success'> };

export type SendTrainingsToTrainersMutationVariables = {
  input: SendTrainingsToTrainersInput;
};


export type SendTrainingsToTrainersMutation = { sendTrainingsToTrainers: Pick<SendTrainingsToTrainersPayload, 'success'> };

export type UpdateAdminMutationVariables = {
  input: UpdateAdminInput;
};


export type UpdateAdminMutation = { updateAdmin: { user: Pick<User, 'id'> } };

export type UpdateEmailMutationVariables = {
  input: UpdateEmailInput;
};


export type UpdateEmailMutation = { updateEmail: { user: Pick<User, 'email' | 'id'> } };

export type UpdateLeaguePricesMutationVariables = {
  input: UpdateLeaguePricesInput;
};


export type UpdateLeaguePricesMutation = { updateLeaguePrices: { leaguePrices: Pick<LeaguePrices, 'id' | 'year' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'> } };

export type UpdateMatchMutationVariables = {
  input: UpdateMatchInput;
};


export type UpdateMatchMutation = { updateMatch: { match: (
      Pick<Match, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
      & { matchDates: Array<Pick<MatchDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>>, competitions: Array<Pick<Competition, 'id' | 'name'>> }
    ) } };

export type UpdateMatchDateResponsibleMutationVariables = {
  input: UpdateMatchDateResponsibleInput;
};


export type UpdateMatchDateResponsibleMutation = { updateMatchDateResponsible: { matchDate: (
      Pick<MatchDate, 'id'>
      & { responsible?: Maybe<Pick<MatchDateReferee, 'id'>> }
    ) } };

export type UpdateMatchLocationMutationVariables = {
  input: UpdateMatchLocationInput;
};


export type UpdateMatchLocationMutation = { updateMatchLocation: { match: Pick<Match, 'address' | 'city' | 'id' | 'zipCode' | 'lat' | 'lng'> } };

export type UpdateMatchRefereeMutationVariables = {
  input: UpdateMatchRefereeInput;
};


export type UpdateMatchRefereeMutation = { updateMatchReferee: { matchReferee: (
      Pick<MatchReferee, 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'id' | 'informations'>
      & { referee: Pick<Referee, 'id' | 'firstName' | 'lastName'>, matchRefereeRoute: Pick<MatchRefereeRoute, 'id' | 'to' | 'from'>, matchDateReferees: Array<(
        Pick<MatchDateReferee, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { matchDate: Pick<MatchDate, 'id' | 'date'> }
      )> }
    ) } };

export type UpdateMatchRefereeDisciplineMutationVariables = {
  input: UpdateMatchRefereeDisciplineInput;
};


export type UpdateMatchRefereeDisciplineMutation = { updateMatchRefereeDiscipline: { matchReferee: (
      Pick<MatchReferee, 'id'>
      & { discipline?: Maybe<(
        Pick<RefereeDiscipline, 'id' | 'level' | 'obtentionDate' | 'validityDate'>
        & { discipline: Pick<Discipline, 'id' | 'name'> }
      )> }
    ) } };

export type UpdateMatchRefereeRouteMutationVariables = {
  input: UpdateMatchRefereeRouteInput;
};


export type UpdateMatchRefereeRouteMutation = { updateMatchRefereeRoute: { matchRefereeRoute: Pick<MatchRefereeRoute, 'id' | 'to' | 'from'> } };

export type UpdateMatchVolunteerMutationVariables = {
  input: UpdateMatchVolunteerInput;
};


export type UpdateMatchVolunteerMutation = { updateMatchVolunteer: { matchVolunteer: (
      Pick<MatchVolunteer, 'eveHotel' | 'id' | 'informations'>
      & { volunteer: Pick<Volunteer, 'id' | 'firstName' | 'lastName'>, matchDateVolunteers: Array<(
        Pick<MatchDateVolunteer, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { matchDate: Pick<MatchDate, 'id' | 'date'> }
      )> }
    ) } };

export type UpdateMeetingMutationVariables = {
  input: UpdateMeetingInput;
};


export type UpdateMeetingMutation = { updateMeeting: { meeting: (
      Pick<Meeting, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
      & { meetingDates: Array<Pick<MeetingDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>> }
    ) } };

export type UpdateMeetingLocationMutationVariables = {
  input: UpdateMeetingLocationInput;
};


export type UpdateMeetingLocationMutation = { updateMeetingLocation: { meeting: Pick<Meeting, 'address' | 'city' | 'id' | 'zipCode' | 'lat' | 'lng'> } };

export type UpdateMeetingMemberMutationVariables = {
  input: UpdateMeetingMemberInput;
};


export type UpdateMeetingMemberMutation = { updateMeetingMember: { meetingMember: (
      Pick<MeetingMember, 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'id' | 'informations'>
      & { member: Pick<Member, 'id' | 'firstName' | 'lastName'>, meetingMemberRoute: Pick<MeetingMemberRoute, 'id' | 'to' | 'from'>, meetingDateMembers: Array<(
        Pick<MeetingDateMember, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { meetingDate: Pick<MeetingDate, 'id' | 'date'> }
      )> }
    ) } };

export type UpdateMeetingMemberRouteMutationVariables = {
  input: UpdateMeetingMemberRouteInput;
};


export type UpdateMeetingMemberRouteMutation = { updateMeetingMemberRoute: { meetingMemberRoute: Pick<MeetingMemberRoute, 'id' | 'to' | 'from'> } };

export type UpdateTraineeMutationVariables = {
  input: UpdateTraineeInput;
};


export type UpdateTraineeMutation = { updateTrainee: { trainee: (
      Pick<Trainee, 'id' | 'lastName' | 'firstName' | 'email' | 'phone' | 'enabled'>
      & { category?: Maybe<Pick<TraineeCategory, 'id' | 'name'>> }
    ) } };

export type UpdateTrainingMutationVariables = {
  input: UpdateTrainingInput;
};


export type UpdateTrainingMutation = { updateTraining: { training: (
      Pick<Training, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
      & { trainingDates: Array<Pick<TrainingDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>> }
    ) } };

export type UpdateTrainingLocationMutationVariables = {
  input: UpdateTrainingLocationInput;
};


export type UpdateTrainingLocationMutation = { updateTrainingLocation: { training: Pick<Training, 'address' | 'city' | 'id' | 'zipCode' | 'lat' | 'lng'> } };

export type UpdateTrainingTraineeMutationVariables = {
  input: UpdateTrainingTraineeInput;
};


export type UpdateTrainingTraineeMutation = { updateTrainingTrainee: { trainingTrainee: (
      Pick<TrainingTrainee, 'eveHotel' | 'id' | 'informations'>
      & { trainee: Pick<Trainee, 'id' | 'firstName' | 'lastName'>, trainingDateTrainees: Array<(
        Pick<TrainingDateTrainee, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { trainingDate: Pick<TrainingDate, 'id' | 'date'> }
      )> }
    ) } };

export type UpdateTrainingTrainerMutationVariables = {
  input: UpdateTrainingTrainerInput;
};


export type UpdateTrainingTrainerMutation = { updateTrainingTrainer: { trainingTrainer: (
      Pick<TrainingTrainer, 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'id' | 'informations'>
      & { trainer: Pick<Trainer, 'id' | 'firstName' | 'lastName'>, trainingTrainerRoute: Pick<TrainingTrainerRoute, 'id' | 'to' | 'from'>, trainingDateTrainers: Array<(
        Pick<TrainingDateTrainer, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { trainingDate: Pick<TrainingDate, 'id' | 'date'> }
      )> }
    ) } };

export type UpdateTrainingTrainerRouteMutationVariables = {
  input: UpdateTrainingTrainerRouteInput;
};


export type UpdateTrainingTrainerRouteMutation = { updateTrainingTrainerRoute: { trainingTrainerRoute: Pick<TrainingTrainerRoute, 'id' | 'to' | 'from'> } };

export type UpdateUserMutationVariables = {
  input: UpdateUserInput;
};


export type UpdateUserMutation = { updateUser: { user: Pick<User, 'email' | 'firstName' | 'id' | 'lastName' | 'permissions'> } };

export type UpdateVolunteerMutationVariables = {
  input: UpdateVolunteerInput;
};


export type UpdateVolunteerMutation = { updateVolunteer: { volunteer: Pick<Volunteer, 'id' | 'lastName' | 'firstName' | 'email' | 'phone' | 'enabled'> } };

export type AdminsQueryVariables = {};


export type AdminsQuery = { admins: Array<(
    Pick<User, 'email' | 'firstName' | 'id' | 'lastName'>
    & { department?: Maybe<Pick<Department, 'name' | 'id'>> }
  )> };

export type CompetitionsQueryVariables = {};


export type CompetitionsQuery = { competitions: Array<Pick<Competition, 'id' | 'name'>> };

export type CurrentUserQueryVariables = {};


export type CurrentUserQuery = { user: (
    Pick<User, 'id' | 'firstName' | 'lastName' | 'admin' | 'permissions' | 'email'>
    & { league: (
      Pick<League, 'id' | 'name' | 'logo'>
      & { departments: Array<Pick<Department, 'id' | 'name' | 'number'>> }
    ), department?: Maybe<Pick<Department, 'id' | 'name' | 'logo'>> }
  ) };

export type DisciplinesQueryVariables = {};


export type DisciplinesQuery = { disciplines: Array<Pick<Discipline, 'id' | 'name'>> };

export type LeaguePricesQueryVariables = {
  year: Scalars['Int'];
};


export type LeaguePricesQuery = { user: (
    Pick<User, 'id'>
    & { league: (
      Pick<League, 'id'>
      & { prices: Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'> }
    ) }
  ) };

export type MatchResponsesQueryVariables = {
  matchId: Scalars['ID'];
};


export type MatchResponsesQuery = { match: (
    Pick<Match, 'eveHotel' | 'id'>
    & { matchDates: Array<Pick<MatchDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>>, refereesRequest: Array<(
      Pick<RefereeRequestMatch, 'id' | 'createdAt'>
      & { referee: (
        Pick<Referee, 'id' | 'firstName' | 'lastName' | 'address' | 'zipCode' | 'city' | 'dateOfBirth'>
        & { disciplines: Array<(
          Pick<RefereeDiscipline, 'id' | 'level' | 'obtentionDate' | 'validityDate'>
          & { discipline: Pick<Discipline, 'id' | 'name'> }
        )> }
      ), lastResponse?: Maybe<(
        Pick<MatchResponse, 'id' | 'informations' | 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'createdAt' | 'accepted'>
        & { matchRefereeRoute: Pick<MatchRefereeRoute, 'id' | 'from' | 'to'>, matchDateResponses: Array<(
          Pick<MatchDateResponse, 'id' | 'informations' | 'lunch' | 'hotel' | 'dinner'>
          & { matchDate: Pick<MatchDate, 'id' | 'date'> }
        )> }
      )> }
    )> }
  ) };

export type MatchResumeQueryVariables = {
  matchId: Scalars['ID'];
};


export type MatchResumeQuery = { match: (
    Pick<Match, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
    & { department?: Maybe<Pick<Department, 'id'>>, competitions: Array<Pick<Competition, 'id' | 'name'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>>, matchDates: Array<(
      Pick<MatchDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>
      & { responsible?: Maybe<Pick<MatchDateReferee, 'id'>> }
    )>, matchReferees: Array<(
      Pick<MatchReferee, 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'id' | 'informations'>
      & { referee: (
        Pick<Referee, 'id' | 'firstName' | 'lastName' | 'address' | 'zipCode' | 'city' | 'dateOfBirth'>
        & { disciplines: Array<(
          Pick<RefereeDiscipline, 'id' | 'level' | 'obtentionDate' | 'validityDate'>
          & { discipline: Pick<Discipline, 'id' | 'name'> }
        )> }
      ), matchRefereeRoute: Pick<MatchRefereeRoute, 'id' | 'to' | 'from'>, matchDateReferees: Array<(
        Pick<MatchDateReferee, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { matchDate: Pick<MatchDate, 'id' | 'date'> }
      )>, discipline?: Maybe<(
        Pick<RefereeDiscipline, 'id' | 'level' | 'obtentionDate' | 'validityDate'>
        & { discipline: Pick<Discipline, 'id' | 'name'> }
      )> }
    )>, matchVolunteers: Array<(
      Pick<MatchVolunteer, 'eveHotel' | 'id' | 'informations'>
      & { volunteer: Pick<Volunteer, 'id' | 'firstName' | 'lastName'>, matchDateVolunteers: Array<(
        Pick<MatchDateVolunteer, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { matchDate: Pick<MatchDate, 'id' | 'date'> }
      )> }
    )> }
  ) };

export type MatchVolunteerResponsesQueryVariables = {
  matchId: Scalars['ID'];
};


export type MatchVolunteerResponsesQuery = { match: (
    Pick<Match, 'eveHotel' | 'id'>
    & { matchDates: Array<Pick<MatchDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>>, volunteersRequest: Array<(
      Pick<VolunteerRequestMatch, 'id' | 'createdAt'>
      & { lastResponse?: Maybe<(
        Pick<MatchVolunteerResponse, 'id' | 'createdAt' | 'informations' | 'eveHotel' | 'accepted'>
        & { matchDateResponses: Array<(
          Pick<MatchDateVolunteerResponse, 'dinner' | 'hotel' | 'id' | 'informations' | 'lunch'>
          & { matchDate: Pick<MatchDate, 'id' | 'date'> }
        )> }
      )>, volunteer: Pick<Volunteer, 'id' | 'lastName' | 'firstName'> }
    )> }
  ) };

export type MatchesQueryVariables = {
  year: Scalars['Int'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type MatchesQuery = { matches: Array<Pick<Match, 'address' | 'city' | 'createdAt' | 'endDate' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode'>> };

export type MatchesForInvitationQueryVariables = {
  matchIds?: Maybe<Array<Scalars['ID']>>;
};


export type MatchesForInvitationQuery = { matchesByIds: Array<(
    Pick<Match, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'informations' | 'startDate' | 'title' | 'zipCode'>
    & { matchDates: Array<Pick<MatchDate, 'date' | 'dinner' | 'endHour' | 'hotel' | 'id' | 'lunch' | 'startHour'>> }
  )> };

export type MeetingResponsesQueryVariables = {
  meetingId: Scalars['ID'];
};


export type MeetingResponsesQuery = { meeting: (
    Pick<Meeting, 'eveHotel' | 'id'>
    & { meetingDates: Array<Pick<MeetingDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>>, membersRequest: Array<(
      Pick<MemberRequestMeeting, 'id' | 'createdAt'>
      & { member: (
        Pick<Member, 'id' | 'firstName' | 'lastName' | 'address' | 'zipCode' | 'city' | 'dateOfBirth'>
        & { infos: Array<Pick<MemberInfo, 'id' | 'accessRight' | 'title' | 'groupe'>> }
      ), lastResponse?: Maybe<(
        Pick<MeetingResponse, 'id' | 'informations' | 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'createdAt' | 'accepted'>
        & { meetingMemberRoute: Pick<MeetingMemberRoute, 'id' | 'from' | 'to'>, meetingDateResponses: Array<(
          Pick<MeetingDateResponse, 'id' | 'informations' | 'lunch' | 'hotel' | 'dinner'>
          & { meetingDate: Pick<MeetingDate, 'id' | 'date'> }
        )> }
      )> }
    )> }
  ) };

export type MeetingResumeQueryVariables = {
  meetingId: Scalars['ID'];
};


export type MeetingResumeQuery = { meeting: (
    Pick<Meeting, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
    & { department?: Maybe<Pick<Department, 'id'>>, meetingDates: Array<Pick<MeetingDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>>, meetingMembers: Array<(
      Pick<MeetingMember, 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'id' | 'informations'>
      & { member: (
        Pick<Member, 'id' | 'firstName' | 'lastName' | 'address' | 'zipCode' | 'city' | 'dateOfBirth'>
        & { infos: Array<Pick<MemberInfo, 'id' | 'accessRight' | 'title' | 'groupe'>> }
      ), meetingMemberRoute: Pick<MeetingMemberRoute, 'id' | 'to' | 'from'>, meetingDateMembers: Array<(
        Pick<MeetingDateMember, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { meetingDate: Pick<MeetingDate, 'id' | 'date'> }
      )> }
    )> }
  ) };

export type MeetingsQueryVariables = {
  year: Scalars['Int'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type MeetingsQuery = { meetings: Array<Pick<Meeting, 'address' | 'city' | 'createdAt' | 'endDate' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode'>> };

export type MeetingsForInvitationQueryVariables = {
  meetingIds?: Maybe<Array<Scalars['ID']>>;
};


export type MeetingsForInvitationQuery = { meetingsByIds: Array<(
    Pick<Meeting, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'informations' | 'startDate' | 'title' | 'zipCode'>
    & { meetingDates: Array<Pick<MeetingDate, 'date' | 'dinner' | 'endHour' | 'hotel' | 'id' | 'lunch' | 'startHour'>> }
  )> };

export type MemberQueryVariables = {
  memberId: Scalars['ID'];
};


export type MemberQuery = { member?: Maybe<(
    Pick<Member, 'id' | 'lastName' | 'firstName' | 'licence' | 'mobilePhone' | 'phone' | 'zipCode' | 'email' | 'dateOfBirth' | 'clubNumber' | 'clubName' | 'city' | 'address'>
    & { league: Pick<League, 'id' | 'name'>, department?: Maybe<Pick<Department, 'id' | 'name'>>, infos: Array<Pick<MemberInfo, 'id' | 'accessRight' | 'title' | 'groupe'>> }
  )> };

export type MemberMeetingsQueryVariables = {
  memberId: Scalars['ID'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type MemberMeetingsQuery = { member?: Maybe<(
    Pick<Member, 'id'>
    & { meetings: Array<(
      Pick<MeetingMember, 'id' | 'informations' | 'eveHotel' | 'noKmsFees' | 'freeHosting'>
      & { meeting: (
        Pick<Meeting, 'title' | 'endDate' | 'city' | 'address' | 'id' | 'zipCode' | 'startDate' | 'eveHotel'>
        & { meetingDates: Array<Pick<MeetingDate, 'id' | 'date' | 'dinner' | 'endHour' | 'hotel' | 'lunch' | 'startHour'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>> }
      ), meetingDateMembers: Array<(
        Pick<MeetingDateMember, 'id' | 'informations' | 'hotel' | 'dinner' | 'lunch' | 'role'>
        & { meetingDate: Pick<MeetingDate, 'id' | 'startHour' | 'endHour' | 'date'> }
      )>, meetingMemberRoute: Pick<MeetingMemberRoute, 'id' | 'from' | 'to'> }
    )> }
  )> };

export type MemberRequestQueryVariables = {
  token: Scalars['String'];
};


export type MemberRequestQuery = { memberRequest: (
    Pick<MemberRequest, 'id' | 'responded'>
    & { member: Pick<Member, 'id' | 'lastName' | 'firstName' | 'address' | 'zipCode' | 'city'>, message: Pick<RequestMessage, 'message' | 'id'>, requestMeetings: Array<(
      Pick<MemberRequestMeeting, 'id'>
      & { meeting: (
        Pick<Meeting, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
        & { prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm'>>, meetingDates: Array<Pick<MeetingDate, 'date' | 'id' | 'lunch' | 'startHour' | 'hotel' | 'endHour' | 'dinner'>> }
      ), meetingMemberRoute: Pick<MeetingMemberRoute, 'id' | 'from' | 'to'>, lastResponse?: Maybe<(
        Pick<MeetingResponse, 'accepted' | 'createdAt' | 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'id' | 'informations'>
        & { meetingDateResponses: Array<(
          Pick<MeetingDateResponse, 'dinner' | 'hotel' | 'id' | 'informations' | 'lunch'>
          & { meetingDate: Pick<MeetingDate, 'id'> }
        )> }
      )> }
    )> }
  ) };

export type MembersQueryVariables = {
  groupe?: Maybe<Scalars['String']>;
};


export type MembersQuery = { members: Array<(
    Pick<Member, 'id' | 'lastName' | 'firstName' | 'enabled' | 'licence' | 'mobilePhone' | 'phone' | 'zipCode' | 'email' | 'dateOfBirth' | 'clubNumber' | 'clubName' | 'city' | 'address'>
    & { league: Pick<League, 'id' | 'name'>, department?: Maybe<Pick<Department, 'id' | 'name'>>, infos: Array<Pick<MemberInfo, 'id' | 'accessRight' | 'title' | 'groupe'>> }
  )> };

export type MembersStatsQueryVariables = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type MembersStatsQuery = { membersForStats: Array<(
    Pick<Member, 'id' | 'lastName' | 'firstName' | 'totalInvitationCount' | 'invitationWithoutResponseCount'>
    & { meetings: Array<(
      Pick<MeetingMember, 'id' | 'informations' | 'eveHotel' | 'noKmsFees' | 'freeHosting'>
      & { meeting: (
        Pick<Meeting, 'title' | 'endDate' | 'city' | 'address' | 'id' | 'zipCode' | 'startDate' | 'eveHotel'>
        & { meetingDates: Array<Pick<MeetingDate, 'id' | 'date' | 'dinner' | 'endHour' | 'hotel' | 'lunch' | 'startHour'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>> }
      ), meetingDateMembers: Array<(
        Pick<MeetingDateMember, 'id' | 'informations' | 'hotel' | 'dinner' | 'lunch' | 'role'>
        & { meetingDate: Pick<MeetingDate, 'id' | 'startHour' | 'endHour' | 'date'> }
      )>, meetingMemberRoute: Pick<MeetingMemberRoute, 'id' | 'from' | 'to'> }
    )> }
  )> };

export type RefereeQueryVariables = {
  refereeId: Scalars['ID'];
};


export type RefereeQuery = { referee?: Maybe<(
    Pick<Referee, 'id' | 'lastName' | 'firstName' | 'licence' | 'mobilePhone' | 'phone' | 'zipCode' | 'email' | 'dateOfBirth' | 'clubNumber' | 'clubName' | 'city' | 'address'>
    & { league: Pick<League, 'id' | 'name'>, department?: Maybe<Pick<Department, 'id' | 'name'>>, disciplines: Array<(
      Pick<RefereeDiscipline, 'id' | 'level' | 'obtentionDate' | 'validityDate'>
      & { discipline: Pick<Discipline, 'id' | 'name'> }
    )> }
  )> };

export type RefereeMatchesQueryVariables = {
  refereeId: Scalars['ID'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type RefereeMatchesQuery = { referee?: Maybe<(
    Pick<Referee, 'id'>
    & { matches: Array<(
      Pick<MatchReferee, 'id' | 'informations' | 'eveHotel' | 'noKmsFees' | 'freeHosting'>
      & { match: (
        Pick<Match, 'title' | 'endDate' | 'city' | 'address' | 'id' | 'zipCode' | 'startDate' | 'eveHotel'>
        & { matchDates: Array<Pick<MatchDate, 'id' | 'date' | 'dinner' | 'endHour' | 'hotel' | 'lunch' | 'startHour'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>> }
      ), matchDateReferees: Array<(
        Pick<MatchDateReferee, 'id' | 'informations' | 'hotel' | 'dinner' | 'lunch' | 'role'>
        & { matchDate: Pick<MatchDate, 'id' | 'startHour' | 'endHour' | 'date'> }
      )>, matchRefereeRoute: Pick<MatchRefereeRoute, 'id' | 'from' | 'to'> }
    )> }
  )> };

export type RefereeRequestQueryVariables = {
  token: Scalars['String'];
};


export type RefereeRequestQuery = { refereeRequest: (
    Pick<RefereeRequest, 'id' | 'responded'>
    & { referee: Pick<Referee, 'id' | 'lastName' | 'firstName' | 'address' | 'zipCode' | 'city'>, message: Pick<RequestMessage, 'message' | 'id'>, requestMatches: Array<(
      Pick<RefereeRequestMatch, 'id'>
      & { match: (
        Pick<Match, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
        & { prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm'>>, matchDates: Array<Pick<MatchDate, 'date' | 'id' | 'lunch' | 'startHour' | 'hotel' | 'endHour' | 'dinner'>> }
      ), matchRefereeRoute: Pick<MatchRefereeRoute, 'id' | 'from' | 'to'>, lastResponse?: Maybe<(
        Pick<MatchResponse, 'accepted' | 'createdAt' | 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'id' | 'informations'>
        & { matchDateResponses: Array<(
          Pick<MatchDateResponse, 'dinner' | 'hotel' | 'id' | 'informations' | 'lunch'>
          & { matchDate: Pick<MatchDate, 'id'> }
        )> }
      )> }
    )> }
  ) };

export type RefereesQueryVariables = {};


export type RefereesQuery = { referees: Array<(
    Pick<Referee, 'id' | 'lastName' | 'firstName' | 'enabled' | 'licence' | 'mobilePhone' | 'phone' | 'zipCode' | 'email' | 'dateOfBirth' | 'clubNumber' | 'clubName' | 'city' | 'address'>
    & { league: Pick<League, 'id' | 'name'>, department?: Maybe<Pick<Department, 'id' | 'name'>>, disciplines: Array<(
      Pick<RefereeDiscipline, 'id' | 'level' | 'obtentionDate' | 'validityDate'>
      & { discipline: Pick<Discipline, 'id' | 'name'> }
    )> }
  )> };

export type RefereesStatsQueryVariables = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type RefereesStatsQuery = { refereesForStats: Array<(
    Pick<Referee, 'id' | 'lastName' | 'firstName' | 'totalInvitationCount' | 'invitationWithoutResponseCount'>
    & { matches: Array<(
      Pick<MatchReferee, 'id' | 'informations' | 'eveHotel' | 'noKmsFees' | 'freeHosting'>
      & { match: (
        Pick<Match, 'title' | 'endDate' | 'city' | 'address' | 'id' | 'zipCode' | 'startDate' | 'eveHotel'>
        & { matchDates: Array<Pick<MatchDate, 'id' | 'date' | 'dinner' | 'endHour' | 'hotel' | 'lunch' | 'startHour'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>> }
      ), matchDateReferees: Array<(
        Pick<MatchDateReferee, 'id' | 'informations' | 'hotel' | 'dinner' | 'lunch' | 'role'>
        & { matchDate: Pick<MatchDate, 'id' | 'startHour' | 'endHour' | 'date'> }
      )>, matchRefereeRoute: Pick<MatchRefereeRoute, 'id' | 'from' | 'to'> }
    )> }
  )> };

export type SearchMembersQueryVariables = {
  search: Scalars['String'];
  meetingId: Scalars['ID'];
};


export type SearchMembersQuery = { searchMembers: Array<(
    Pick<Member, 'id' | 'firstName' | 'lastName' | 'address' | 'zipCode' | 'city' | 'dateOfBirth'>
    & { department?: Maybe<Pick<Department, 'id' | 'name'>>, infos: Array<Pick<MemberInfo, 'id' | 'accessRight' | 'title' | 'groupe'>> }
  )> };

export type SearchRefereesQueryVariables = {
  search: Scalars['String'];
  matchId: Scalars['ID'];
};


export type SearchRefereesQuery = { searchReferees: Array<(
    Pick<Referee, 'id' | 'firstName' | 'lastName' | 'address' | 'zipCode' | 'city' | 'dateOfBirth'>
    & { department?: Maybe<Pick<Department, 'id' | 'name'>>, disciplines: Array<(
      Pick<RefereeDiscipline, 'id' | 'level' | 'obtentionDate' | 'validityDate'>
      & { discipline: Pick<Discipline, 'id' | 'name'> }
    )> }
  )> };

export type SearchTraineesQueryVariables = {
  search: Scalars['String'];
  trainingId: Scalars['ID'];
};


export type SearchTraineesQuery = { searchTrainees: Array<Pick<Trainee, 'id' | 'firstName' | 'lastName'>> };

export type SearchTrainersQueryVariables = {
  search: Scalars['String'];
  trainingId: Scalars['ID'];
};


export type SearchTrainersQuery = { searchTrainers: Array<(
    Pick<Trainer, 'id' | 'firstName' | 'lastName' | 'address' | 'zipCode' | 'city' | 'dateOfBirth'>
    & { department?: Maybe<Pick<Department, 'id' | 'name'>> }
  )> };

export type SearchVolunteersQueryVariables = {
  search: Scalars['String'];
  matchId: Scalars['ID'];
};


export type SearchVolunteersQuery = { searchVolunteers: Array<Pick<Volunteer, 'id' | 'firstName' | 'lastName'>> };

export type TraineeQueryVariables = {
  traineeId: Scalars['ID'];
};


export type TraineeQuery = { trainee?: Maybe<(
    Pick<Trainee, 'id' | 'lastName' | 'firstName' | 'email' | 'phone' | 'enabled'>
    & { category?: Maybe<Pick<TraineeCategory, 'id' | 'name'>> }
  )> };

export type TraineeCategoriesQueryVariables = {};


export type TraineeCategoriesQuery = { traineeCategories: Array<Pick<TraineeCategory, 'id' | 'name'>> };

export type TraineeRequestQueryVariables = {
  token: Scalars['String'];
};


export type TraineeRequestQuery = { traineeRequest: (
    Pick<TraineeRequest, 'id' | 'responded'>
    & { trainee: Pick<Trainee, 'id' | 'lastName' | 'firstName'>, message: Pick<RequestMessage, 'message' | 'id'>, requestTrainings: Array<(
      Pick<TraineeRequestTraining, 'id'>
      & { training: (
        Pick<Training, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'informations'>
        & { department?: Maybe<Pick<Department, 'id' | 'name'>>, league: Pick<League, 'id' | 'name'>, trainingDates: Array<Pick<TrainingDate, 'date' | 'id' | 'lunch' | 'startHour' | 'hotel' | 'endHour' | 'dinner'>> }
      ), lastResponse?: Maybe<(
        Pick<TrainingTraineeResponse, 'accepted' | 'createdAt' | 'eveHotel' | 'id' | 'informations'>
        & { trainingDateResponses: Array<(
          Pick<TrainingDateTraineeResponse, 'dinner' | 'hotel' | 'id' | 'informations' | 'lunch'>
          & { trainingDate: Pick<TrainingDate, 'id'> }
        )> }
      )> }
    )> }
  ) };

export type TraineesQueryVariables = {};


export type TraineesQuery = { trainees: Array<(
    Pick<Trainee, 'id' | 'lastName' | 'firstName' | 'email' | 'phone' | 'enabled'>
    & { category?: Maybe<Pick<TraineeCategory, 'id' | 'name'>> }
  )> };

export type TraineesStatsQueryVariables = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type TraineesStatsQuery = { traineesForStats: Array<(
    Pick<Trainee, 'id' | 'lastName' | 'firstName' | 'totalInvitationCount' | 'invitationWithoutResponseCount'>
    & { trainings: Array<(
      Pick<TrainingTrainee, 'id' | 'informations' | 'eveHotel'>
      & { training: (
        Pick<Training, 'title' | 'endDate' | 'city' | 'address' | 'id' | 'zipCode' | 'startDate' | 'eveHotel'>
        & { trainingDates: Array<Pick<TrainingDate, 'id' | 'date' | 'dinner' | 'endHour' | 'hotel' | 'lunch' | 'startHour'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>> }
      ), trainingDateTrainees: Array<(
        Pick<TrainingDateTrainee, 'id' | 'informations' | 'hotel' | 'dinner' | 'lunch' | 'role'>
        & { trainingDate: Pick<TrainingDate, 'id' | 'startHour' | 'endHour' | 'date'> }
      )> }
    )> }
  )> };

export type TrainerQueryVariables = {
  trainerId: Scalars['ID'];
};


export type TrainerQuery = { trainer?: Maybe<(
    Pick<Trainer, 'id' | 'lastName' | 'firstName' | 'licence' | 'mobilePhone' | 'phone' | 'zipCode' | 'email' | 'dateOfBirth' | 'clubNumber' | 'clubName' | 'city' | 'address'>
    & { league: Pick<League, 'id' | 'name'>, department?: Maybe<Pick<Department, 'id' | 'name'>> }
  )> };

export type TrainerRequestQueryVariables = {
  token: Scalars['String'];
};


export type TrainerRequestQuery = { trainerRequest: (
    Pick<TrainerRequest, 'id' | 'responded'>
    & { trainer: Pick<Trainer, 'id' | 'lastName' | 'firstName' | 'address' | 'zipCode' | 'city'>, message: Pick<RequestMessage, 'message' | 'id'>, requestTrainings: Array<(
      Pick<TrainerRequestTraining, 'id'>
      & { training: (
        Pick<Training, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
        & { prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm'>>, trainingDates: Array<Pick<TrainingDate, 'date' | 'id' | 'lunch' | 'startHour' | 'hotel' | 'endHour' | 'dinner'>> }
      ), trainingTrainerRoute: Pick<TrainingTrainerRoute, 'id' | 'from' | 'to'>, lastResponse?: Maybe<(
        Pick<TrainingResponse, 'accepted' | 'createdAt' | 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'id' | 'informations'>
        & { trainingDateResponses: Array<(
          Pick<TrainingDateResponse, 'dinner' | 'hotel' | 'id' | 'informations' | 'lunch'>
          & { trainingDate: Pick<TrainingDate, 'id'> }
        )> }
      )> }
    )> }
  ) };

export type TrainerTrainingsQueryVariables = {
  trainerId: Scalars['ID'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type TrainerTrainingsQuery = { trainer?: Maybe<(
    Pick<Trainer, 'id'>
    & { trainings: Array<(
      Pick<TrainingTrainer, 'id' | 'informations' | 'eveHotel' | 'noKmsFees' | 'freeHosting'>
      & { training: (
        Pick<Training, 'title' | 'endDate' | 'city' | 'address' | 'id' | 'zipCode' | 'startDate' | 'eveHotel'>
        & { trainingDates: Array<Pick<TrainingDate, 'id' | 'date' | 'dinner' | 'endHour' | 'hotel' | 'lunch' | 'startHour'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>> }
      ), trainingDateTrainers: Array<(
        Pick<TrainingDateTrainer, 'id' | 'informations' | 'hotel' | 'dinner' | 'lunch' | 'role'>
        & { trainingDate: Pick<TrainingDate, 'id' | 'startHour' | 'endHour' | 'date'> }
      )>, trainingTrainerRoute: Pick<TrainingTrainerRoute, 'id' | 'from' | 'to'> }
    )> }
  )> };

export type TrainersQueryVariables = {};


export type TrainersQuery = { trainers: Array<(
    Pick<Trainer, 'id' | 'lastName' | 'firstName' | 'enabled' | 'licence' | 'mobilePhone' | 'phone' | 'zipCode' | 'email' | 'dateOfBirth' | 'clubNumber' | 'clubName' | 'city' | 'address'>
    & { league: Pick<League, 'id' | 'name'>, department?: Maybe<Pick<Department, 'id' | 'name'>> }
  )> };

export type TrainersStatsQueryVariables = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type TrainersStatsQuery = { trainersForStats: Array<(
    Pick<Trainer, 'id' | 'lastName' | 'firstName' | 'totalInvitationCount' | 'invitationWithoutResponseCount'>
    & { trainings: Array<(
      Pick<TrainingTrainer, 'id' | 'informations' | 'eveHotel' | 'noKmsFees' | 'freeHosting'>
      & { training: (
        Pick<Training, 'title' | 'endDate' | 'city' | 'address' | 'id' | 'zipCode' | 'startDate' | 'eveHotel'>
        & { trainingDates: Array<Pick<TrainingDate, 'id' | 'date' | 'dinner' | 'endHour' | 'hotel' | 'lunch' | 'startHour'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>> }
      ), trainingDateTrainers: Array<(
        Pick<TrainingDateTrainer, 'id' | 'informations' | 'hotel' | 'dinner' | 'lunch' | 'role'>
        & { trainingDate: Pick<TrainingDate, 'id' | 'startHour' | 'endHour' | 'date'> }
      )>, trainingTrainerRoute: Pick<TrainingTrainerRoute, 'id' | 'from' | 'to'> }
    )> }
  )> };

export type TrainingResponsesQueryVariables = {
  trainingId: Scalars['ID'];
};


export type TrainingResponsesQuery = { training: (
    Pick<Training, 'eveHotel' | 'id'>
    & { trainingDates: Array<Pick<TrainingDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>>, trainersRequest: Array<(
      Pick<TrainerRequestTraining, 'id' | 'createdAt'>
      & { trainer: Pick<Trainer, 'id' | 'firstName' | 'lastName' | 'address' | 'zipCode' | 'city' | 'dateOfBirth'>, lastResponse?: Maybe<(
        Pick<TrainingResponse, 'id' | 'informations' | 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'createdAt' | 'accepted'>
        & { trainingTrainerRoute: Pick<TrainingTrainerRoute, 'id' | 'from' | 'to'>, trainingDateResponses: Array<(
          Pick<TrainingDateResponse, 'id' | 'informations' | 'lunch' | 'hotel' | 'dinner'>
          & { trainingDate: Pick<TrainingDate, 'id' | 'date'> }
        )> }
      )> }
    )> }
  ) };

export type TrainingResumeQueryVariables = {
  trainingId: Scalars['ID'];
};


export type TrainingResumeQuery = { training: (
    Pick<Training, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
    & { department?: Maybe<Pick<Department, 'id'>>, trainingDates: Array<Pick<TrainingDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>>, trainingTrainers: Array<(
      Pick<TrainingTrainer, 'eveHotel' | 'noKmsFees' | 'freeHosting' | 'id' | 'informations'>
      & { trainer: Pick<Trainer, 'id' | 'firstName' | 'lastName' | 'address' | 'zipCode' | 'city' | 'dateOfBirth'>, trainingTrainerRoute: Pick<TrainingTrainerRoute, 'id' | 'to' | 'from'>, trainingDateTrainers: Array<(
        Pick<TrainingDateTrainer, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { trainingDate: Pick<TrainingDate, 'id' | 'date'> }
      )> }
    )>, trainingTrainees: Array<(
      Pick<TrainingTrainee, 'eveHotel' | 'id' | 'informations'>
      & { trainee: (
        Pick<Trainee, 'id' | 'firstName' | 'lastName'>
        & { category?: Maybe<Pick<TraineeCategory, 'id' | 'name'>> }
      ), trainingDateTrainees: Array<(
        Pick<TrainingDateTrainee, 'id' | 'lunch' | 'informations' | 'hotel' | 'dinner' | 'role'>
        & { trainingDate: Pick<TrainingDate, 'id' | 'date'> }
      )> }
    )> }
  ) };

export type TrainingTraineeResponsesQueryVariables = {
  trainingId: Scalars['ID'];
};


export type TrainingTraineeResponsesQuery = { training: (
    Pick<Training, 'eveHotel' | 'id'>
    & { trainingDates: Array<Pick<TrainingDate, 'id' | 'requiredCount' | 'startHour' | 'lunch' | 'hotel' | 'endHour' | 'dinner' | 'date'>>, traineesRequest: Array<(
      Pick<TraineeRequestTraining, 'id' | 'createdAt'>
      & { trainee: (
        Pick<Trainee, 'id' | 'firstName' | 'lastName'>
        & { category?: Maybe<Pick<TraineeCategory, 'id' | 'name'>> }
      ), lastResponse?: Maybe<(
        Pick<TrainingTraineeResponse, 'id' | 'informations' | 'eveHotel' | 'createdAt' | 'accepted'>
        & { trainingDateResponses: Array<(
          Pick<TrainingDateTraineeResponse, 'id' | 'informations' | 'lunch' | 'hotel' | 'dinner'>
          & { trainingDate: Pick<TrainingDate, 'id' | 'date'> }
        )> }
      )> }
    )> }
  ) };

export type TrainingsQueryVariables = {
  year: Scalars['Int'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type TrainingsQuery = { trainings: Array<Pick<Training, 'address' | 'city' | 'createdAt' | 'endDate' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode'>> };

export type TrainingsForInvitationQueryVariables = {
  trainingIds?: Maybe<Array<Scalars['ID']>>;
};


export type TrainingsForInvitationQuery = { trainingsByIds: Array<(
    Pick<Training, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'informations' | 'startDate' | 'title' | 'zipCode'>
    & { trainingDates: Array<Pick<TrainingDate, 'date' | 'dinner' | 'endHour' | 'hotel' | 'id' | 'lunch' | 'startHour'>> }
  )> };

export type UserQueryVariables = {
  userId: Scalars['ID'];
};


export type UserQuery = { user: (
    Pick<User, 'email' | 'firstName' | 'id' | 'lastName' | 'permissions'>
    & { department?: Maybe<Pick<Department, 'name' | 'id'>> }
  ) };

export type UsersQueryVariables = {};


export type UsersQuery = { users: Array<Pick<User, 'email' | 'firstName' | 'id' | 'lastName'>> };

export type VolunteerQueryVariables = {
  volunteerId: Scalars['ID'];
};


export type VolunteerQuery = { volunteer?: Maybe<Pick<Volunteer, 'id' | 'lastName' | 'firstName' | 'email' | 'phone' | 'enabled'>> };

export type VolunteerRequestQueryVariables = {
  token: Scalars['String'];
};


export type VolunteerRequestQuery = { volunteerRequest: (
    Pick<VolunteerRequest, 'id' | 'responded'>
    & { volunteer: Pick<Volunteer, 'id' | 'lastName' | 'firstName'>, message: Pick<RequestMessage, 'message' | 'id'>, requestMatches: Array<(
      Pick<VolunteerRequestMatch, 'id'>
      & { match: (
        Pick<Match, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'informations'>
        & { department?: Maybe<Pick<Department, 'id' | 'name'>>, league: Pick<League, 'id' | 'name'>, matchDates: Array<Pick<MatchDate, 'date' | 'id' | 'lunch' | 'startHour' | 'hotel' | 'endHour' | 'dinner'>> }
      ), lastResponse?: Maybe<(
        Pick<MatchVolunteerResponse, 'accepted' | 'createdAt' | 'eveHotel' | 'id' | 'informations'>
        & { matchDateResponses: Array<(
          Pick<MatchDateVolunteerResponse, 'dinner' | 'hotel' | 'id' | 'informations' | 'lunch'>
          & { matchDate: Pick<MatchDate, 'id'> }
        )> }
      )> }
    )> }
  ) };

export type VolunteersQueryVariables = {};


export type VolunteersQuery = { volunteers: Array<Pick<Volunteer, 'id' | 'lastName' | 'firstName' | 'email' | 'phone' | 'enabled'>> };

export type VolunteersStatsQueryVariables = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  departmentId?: Maybe<Scalars['ID']>;
};


export type VolunteersStatsQuery = { volunteersForStats: Array<(
    Pick<Volunteer, 'id' | 'lastName' | 'firstName' | 'totalInvitationCount' | 'invitationWithoutResponseCount'>
    & { matches: Array<(
      Pick<MatchVolunteer, 'id' | 'informations' | 'eveHotel'>
      & { match: (
        Pick<Match, 'title' | 'endDate' | 'city' | 'address' | 'id' | 'zipCode' | 'startDate' | 'eveHotel'>
        & { matchDates: Array<Pick<MatchDate, 'id' | 'date' | 'dinner' | 'endHour' | 'hotel' | 'lunch' | 'startHour'>>, prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm' | 'pricePerLunch' | 'pricePerDinner' | 'pricePerHotel'>> }
      ), matchDateVolunteers: Array<(
        Pick<MatchDateVolunteer, 'id' | 'informations' | 'hotel' | 'dinner' | 'lunch' | 'role'>
        & { matchDate: Pick<MatchDate, 'id' | 'startHour' | 'endHour' | 'date'> }
      )> }
    )> }
  )> };


export const AddAdminDocument = gql`
    mutation AddAdmin($input: AddAdminInput!) {
  addAdmin(input: $input) {
    user {
      id
    }
  }
}
    `;
export type AddAdminMutationFn = ApolloReactCommon.MutationFunction<AddAdminMutation, AddAdminMutationVariables>;

/**
 * __useAddAdminMutation__
 *
 * To run a mutation, you first call `useAddAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdminMutation, { data, loading, error }] = useAddAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAdminMutation, AddAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAdminMutation, AddAdminMutationVariables>(AddAdminDocument, baseOptions);
      }
export type AddAdminMutationHookResult = ReturnType<typeof useAddAdminMutation>;
export type AddAdminMutationResult = ApolloReactCommon.MutationResult<AddAdminMutation>;
export type AddAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAdminMutation, AddAdminMutationVariables>;
export const AddCompetitionDocument = gql`
    mutation AddCompetition($input: AddCompetitionInput!) {
  addCompetition(input: $input) {
    success
  }
}
    `;
export type AddCompetitionMutationFn = ApolloReactCommon.MutationFunction<AddCompetitionMutation, AddCompetitionMutationVariables>;

/**
 * __useAddCompetitionMutation__
 *
 * To run a mutation, you first call `useAddCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompetitionMutation, { data, loading, error }] = useAddCompetitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCompetitionMutation, AddCompetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCompetitionMutation, AddCompetitionMutationVariables>(AddCompetitionDocument, baseOptions);
      }
export type AddCompetitionMutationHookResult = ReturnType<typeof useAddCompetitionMutation>;
export type AddCompetitionMutationResult = ApolloReactCommon.MutationResult<AddCompetitionMutation>;
export type AddCompetitionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCompetitionMutation, AddCompetitionMutationVariables>;
export const AddMatchDocument = gql`
    mutation AddMatch($input: AddMatchInput!) {
  addMatch(input: $input) {
    match {
      id
    }
  }
}
    `;
export type AddMatchMutationFn = ApolloReactCommon.MutationFunction<AddMatchMutation, AddMatchMutationVariables>;

/**
 * __useAddMatchMutation__
 *
 * To run a mutation, you first call `useAddMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMatchMutation, { data, loading, error }] = useAddMatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMatchMutation, AddMatchMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMatchMutation, AddMatchMutationVariables>(AddMatchDocument, baseOptions);
      }
export type AddMatchMutationHookResult = ReturnType<typeof useAddMatchMutation>;
export type AddMatchMutationResult = ApolloReactCommon.MutationResult<AddMatchMutation>;
export type AddMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMatchMutation, AddMatchMutationVariables>;
export const AddMatchRefereeDocument = gql`
    mutation AddMatchReferee($input: AddMatchRefereeInput!) {
  addMatchReferee(input: $input) {
    matchReferee {
      id
    }
  }
}
    `;
export type AddMatchRefereeMutationFn = ApolloReactCommon.MutationFunction<AddMatchRefereeMutation, AddMatchRefereeMutationVariables>;

/**
 * __useAddMatchRefereeMutation__
 *
 * To run a mutation, you first call `useAddMatchRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMatchRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMatchRefereeMutation, { data, loading, error }] = useAddMatchRefereeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMatchRefereeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMatchRefereeMutation, AddMatchRefereeMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMatchRefereeMutation, AddMatchRefereeMutationVariables>(AddMatchRefereeDocument, baseOptions);
      }
export type AddMatchRefereeMutationHookResult = ReturnType<typeof useAddMatchRefereeMutation>;
export type AddMatchRefereeMutationResult = ApolloReactCommon.MutationResult<AddMatchRefereeMutation>;
export type AddMatchRefereeMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMatchRefereeMutation, AddMatchRefereeMutationVariables>;
export const AddMatchVolunteerDocument = gql`
    mutation AddMatchVolunteer($input: AddMatchVolunteerInput!) {
  addMatchVolunteer(input: $input) {
    matchVolunteer {
      id
    }
  }
}
    `;
export type AddMatchVolunteerMutationFn = ApolloReactCommon.MutationFunction<AddMatchVolunteerMutation, AddMatchVolunteerMutationVariables>;

/**
 * __useAddMatchVolunteerMutation__
 *
 * To run a mutation, you first call `useAddMatchVolunteerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMatchVolunteerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMatchVolunteerMutation, { data, loading, error }] = useAddMatchVolunteerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMatchVolunteerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMatchVolunteerMutation, AddMatchVolunteerMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMatchVolunteerMutation, AddMatchVolunteerMutationVariables>(AddMatchVolunteerDocument, baseOptions);
      }
export type AddMatchVolunteerMutationHookResult = ReturnType<typeof useAddMatchVolunteerMutation>;
export type AddMatchVolunteerMutationResult = ApolloReactCommon.MutationResult<AddMatchVolunteerMutation>;
export type AddMatchVolunteerMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMatchVolunteerMutation, AddMatchVolunteerMutationVariables>;
export const AddMeetingDocument = gql`
    mutation AddMeeting($input: AddMeetingInput!) {
  addMeeting(input: $input) {
    meeting {
      id
    }
  }
}
    `;
export type AddMeetingMutationFn = ApolloReactCommon.MutationFunction<AddMeetingMutation, AddMeetingMutationVariables>;

/**
 * __useAddMeetingMutation__
 *
 * To run a mutation, you first call `useAddMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMeetingMutation, { data, loading, error }] = useAddMeetingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMeetingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMeetingMutation, AddMeetingMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMeetingMutation, AddMeetingMutationVariables>(AddMeetingDocument, baseOptions);
      }
export type AddMeetingMutationHookResult = ReturnType<typeof useAddMeetingMutation>;
export type AddMeetingMutationResult = ApolloReactCommon.MutationResult<AddMeetingMutation>;
export type AddMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMeetingMutation, AddMeetingMutationVariables>;
export const AddMeetingMemberDocument = gql`
    mutation AddMeetingMember($input: AddMeetingMemberInput!) {
  addMeetingMember(input: $input) {
    meetingMember {
      id
    }
  }
}
    `;
export type AddMeetingMemberMutationFn = ApolloReactCommon.MutationFunction<AddMeetingMemberMutation, AddMeetingMemberMutationVariables>;

/**
 * __useAddMeetingMemberMutation__
 *
 * To run a mutation, you first call `useAddMeetingMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMeetingMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMeetingMemberMutation, { data, loading, error }] = useAddMeetingMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMeetingMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMeetingMemberMutation, AddMeetingMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMeetingMemberMutation, AddMeetingMemberMutationVariables>(AddMeetingMemberDocument, baseOptions);
      }
export type AddMeetingMemberMutationHookResult = ReturnType<typeof useAddMeetingMemberMutation>;
export type AddMeetingMemberMutationResult = ApolloReactCommon.MutationResult<AddMeetingMemberMutation>;
export type AddMeetingMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMeetingMemberMutation, AddMeetingMemberMutationVariables>;
export const AddTraineeDocument = gql`
    mutation AddTrainee($input: AddTraineeInput!) {
  addTrainee(input: $input) {
    success
  }
}
    `;
export type AddTraineeMutationFn = ApolloReactCommon.MutationFunction<AddTraineeMutation, AddTraineeMutationVariables>;

/**
 * __useAddTraineeMutation__
 *
 * To run a mutation, you first call `useAddTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTraineeMutation, { data, loading, error }] = useAddTraineeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTraineeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTraineeMutation, AddTraineeMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTraineeMutation, AddTraineeMutationVariables>(AddTraineeDocument, baseOptions);
      }
export type AddTraineeMutationHookResult = ReturnType<typeof useAddTraineeMutation>;
export type AddTraineeMutationResult = ApolloReactCommon.MutationResult<AddTraineeMutation>;
export type AddTraineeMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTraineeMutation, AddTraineeMutationVariables>;
export const AddTraineeCategoryDocument = gql`
    mutation AddTraineeCategory($input: AddTraineeCategoryInput!) {
  addTraineeCategory(input: $input) {
    success
  }
}
    `;
export type AddTraineeCategoryMutationFn = ApolloReactCommon.MutationFunction<AddTraineeCategoryMutation, AddTraineeCategoryMutationVariables>;

/**
 * __useAddTraineeCategoryMutation__
 *
 * To run a mutation, you first call `useAddTraineeCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTraineeCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTraineeCategoryMutation, { data, loading, error }] = useAddTraineeCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTraineeCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTraineeCategoryMutation, AddTraineeCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTraineeCategoryMutation, AddTraineeCategoryMutationVariables>(AddTraineeCategoryDocument, baseOptions);
      }
export type AddTraineeCategoryMutationHookResult = ReturnType<typeof useAddTraineeCategoryMutation>;
export type AddTraineeCategoryMutationResult = ApolloReactCommon.MutationResult<AddTraineeCategoryMutation>;
export type AddTraineeCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTraineeCategoryMutation, AddTraineeCategoryMutationVariables>;
export const AddTrainingDocument = gql`
    mutation AddTraining($input: AddTrainingInput!) {
  addTraining(input: $input) {
    training {
      id
    }
  }
}
    `;
export type AddTrainingMutationFn = ApolloReactCommon.MutationFunction<AddTrainingMutation, AddTrainingMutationVariables>;

/**
 * __useAddTrainingMutation__
 *
 * To run a mutation, you first call `useAddTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingMutation, { data, loading, error }] = useAddTrainingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTrainingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTrainingMutation, AddTrainingMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTrainingMutation, AddTrainingMutationVariables>(AddTrainingDocument, baseOptions);
      }
export type AddTrainingMutationHookResult = ReturnType<typeof useAddTrainingMutation>;
export type AddTrainingMutationResult = ApolloReactCommon.MutationResult<AddTrainingMutation>;
export type AddTrainingMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTrainingMutation, AddTrainingMutationVariables>;
export const AddTrainingTraineeDocument = gql`
    mutation AddTrainingTrainee($input: AddTrainingTraineeInput!) {
  addTrainingTrainee(input: $input) {
    trainingTrainee {
      id
    }
  }
}
    `;
export type AddTrainingTraineeMutationFn = ApolloReactCommon.MutationFunction<AddTrainingTraineeMutation, AddTrainingTraineeMutationVariables>;

/**
 * __useAddTrainingTraineeMutation__
 *
 * To run a mutation, you first call `useAddTrainingTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingTraineeMutation, { data, loading, error }] = useAddTrainingTraineeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTrainingTraineeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTrainingTraineeMutation, AddTrainingTraineeMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTrainingTraineeMutation, AddTrainingTraineeMutationVariables>(AddTrainingTraineeDocument, baseOptions);
      }
export type AddTrainingTraineeMutationHookResult = ReturnType<typeof useAddTrainingTraineeMutation>;
export type AddTrainingTraineeMutationResult = ApolloReactCommon.MutationResult<AddTrainingTraineeMutation>;
export type AddTrainingTraineeMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTrainingTraineeMutation, AddTrainingTraineeMutationVariables>;
export const AddTrainingTrainerDocument = gql`
    mutation AddTrainingTrainer($input: AddTrainingTrainerInput!) {
  addTrainingTrainer(input: $input) {
    trainingTrainer {
      id
    }
  }
}
    `;
export type AddTrainingTrainerMutationFn = ApolloReactCommon.MutationFunction<AddTrainingTrainerMutation, AddTrainingTrainerMutationVariables>;

/**
 * __useAddTrainingTrainerMutation__
 *
 * To run a mutation, you first call `useAddTrainingTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingTrainerMutation, { data, loading, error }] = useAddTrainingTrainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTrainingTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTrainingTrainerMutation, AddTrainingTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<AddTrainingTrainerMutation, AddTrainingTrainerMutationVariables>(AddTrainingTrainerDocument, baseOptions);
      }
export type AddTrainingTrainerMutationHookResult = ReturnType<typeof useAddTrainingTrainerMutation>;
export type AddTrainingTrainerMutationResult = ApolloReactCommon.MutationResult<AddTrainingTrainerMutation>;
export type AddTrainingTrainerMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTrainingTrainerMutation, AddTrainingTrainerMutationVariables>;
export const AddUserDocument = gql`
    mutation AddUser($input: AddUserInput!) {
  addUser(input: $input) {
    user {
      id
    }
  }
}
    `;
export type AddUserMutationFn = ApolloReactCommon.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, baseOptions);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = ApolloReactCommon.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const AddVolunteerDocument = gql`
    mutation AddVolunteer($input: AddVolunteerInput!) {
  addVolunteer(input: $input) {
    success
  }
}
    `;
export type AddVolunteerMutationFn = ApolloReactCommon.MutationFunction<AddVolunteerMutation, AddVolunteerMutationVariables>;

/**
 * __useAddVolunteerMutation__
 *
 * To run a mutation, you first call `useAddVolunteerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVolunteerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVolunteerMutation, { data, loading, error }] = useAddVolunteerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVolunteerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVolunteerMutation, AddVolunteerMutationVariables>) {
        return ApolloReactHooks.useMutation<AddVolunteerMutation, AddVolunteerMutationVariables>(AddVolunteerDocument, baseOptions);
      }
export type AddVolunteerMutationHookResult = ReturnType<typeof useAddVolunteerMutation>;
export type AddVolunteerMutationResult = ApolloReactCommon.MutationResult<AddVolunteerMutation>;
export type AddVolunteerMutationOptions = ApolloReactCommon.BaseMutationOptions<AddVolunteerMutation, AddVolunteerMutationVariables>;
export const DisableTraineeDocument = gql`
    mutation DisableTrainee($input: DisableTraineeInput!) {
  disableTrainee(input: $input) {
    trainee {
      id
      enabled
    }
  }
}
    `;
export type DisableTraineeMutationFn = ApolloReactCommon.MutationFunction<DisableTraineeMutation, DisableTraineeMutationVariables>;

/**
 * __useDisableTraineeMutation__
 *
 * To run a mutation, you first call `useDisableTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTraineeMutation, { data, loading, error }] = useDisableTraineeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableTraineeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableTraineeMutation, DisableTraineeMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableTraineeMutation, DisableTraineeMutationVariables>(DisableTraineeDocument, baseOptions);
      }
export type DisableTraineeMutationHookResult = ReturnType<typeof useDisableTraineeMutation>;
export type DisableTraineeMutationResult = ApolloReactCommon.MutationResult<DisableTraineeMutation>;
export type DisableTraineeMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableTraineeMutation, DisableTraineeMutationVariables>;
export const DisableVolunteerDocument = gql`
    mutation DisableVolunteer($input: DisableVolunteerInput!) {
  disableVolunteer(input: $input) {
    volunteer {
      id
      enabled
    }
  }
}
    `;
export type DisableVolunteerMutationFn = ApolloReactCommon.MutationFunction<DisableVolunteerMutation, DisableVolunteerMutationVariables>;

/**
 * __useDisableVolunteerMutation__
 *
 * To run a mutation, you first call `useDisableVolunteerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableVolunteerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableVolunteerMutation, { data, loading, error }] = useDisableVolunteerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableVolunteerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableVolunteerMutation, DisableVolunteerMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableVolunteerMutation, DisableVolunteerMutationVariables>(DisableVolunteerDocument, baseOptions);
      }
export type DisableVolunteerMutationHookResult = ReturnType<typeof useDisableVolunteerMutation>;
export type DisableVolunteerMutationResult = ApolloReactCommon.MutationResult<DisableVolunteerMutation>;
export type DisableVolunteerMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableVolunteerMutation, DisableVolunteerMutationVariables>;
export const EnableTraineeDocument = gql`
    mutation EnableTrainee($input: EnableTraineeInput!) {
  enableTrainee(input: $input) {
    trainee {
      id
      enabled
    }
  }
}
    `;
export type EnableTraineeMutationFn = ApolloReactCommon.MutationFunction<EnableTraineeMutation, EnableTraineeMutationVariables>;

/**
 * __useEnableTraineeMutation__
 *
 * To run a mutation, you first call `useEnableTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableTraineeMutation, { data, loading, error }] = useEnableTraineeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableTraineeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableTraineeMutation, EnableTraineeMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableTraineeMutation, EnableTraineeMutationVariables>(EnableTraineeDocument, baseOptions);
      }
export type EnableTraineeMutationHookResult = ReturnType<typeof useEnableTraineeMutation>;
export type EnableTraineeMutationResult = ApolloReactCommon.MutationResult<EnableTraineeMutation>;
export type EnableTraineeMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableTraineeMutation, EnableTraineeMutationVariables>;
export const EnableVolunteerDocument = gql`
    mutation EnableVolunteer($input: EnableVolunteerInput!) {
  enableVolunteer(input: $input) {
    volunteer {
      id
      enabled
    }
  }
}
    `;
export type EnableVolunteerMutationFn = ApolloReactCommon.MutationFunction<EnableVolunteerMutation, EnableVolunteerMutationVariables>;

/**
 * __useEnableVolunteerMutation__
 *
 * To run a mutation, you first call `useEnableVolunteerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableVolunteerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableVolunteerMutation, { data, loading, error }] = useEnableVolunteerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableVolunteerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableVolunteerMutation, EnableVolunteerMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableVolunteerMutation, EnableVolunteerMutationVariables>(EnableVolunteerDocument, baseOptions);
      }
export type EnableVolunteerMutationHookResult = ReturnType<typeof useEnableVolunteerMutation>;
export type EnableVolunteerMutationResult = ApolloReactCommon.MutationResult<EnableVolunteerMutation>;
export type EnableVolunteerMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableVolunteerMutation, EnableVolunteerMutationVariables>;
export const GenerateMatchsToRefereesDocument = gql`
    mutation GenerateMatchsToReferees($input: GenerateMatchsToRefereesInput!) {
  generateMatchsToReferees(input: $input) {
    data {
      referee {
        id
        firstName
        lastName
      }
      doc
    }
  }
}
    `;
export type GenerateMatchsToRefereesMutationFn = ApolloReactCommon.MutationFunction<GenerateMatchsToRefereesMutation, GenerateMatchsToRefereesMutationVariables>;

/**
 * __useGenerateMatchsToRefereesMutation__
 *
 * To run a mutation, you first call `useGenerateMatchsToRefereesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMatchsToRefereesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMatchsToRefereesMutation, { data, loading, error }] = useGenerateMatchsToRefereesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateMatchsToRefereesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateMatchsToRefereesMutation, GenerateMatchsToRefereesMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateMatchsToRefereesMutation, GenerateMatchsToRefereesMutationVariables>(GenerateMatchsToRefereesDocument, baseOptions);
      }
export type GenerateMatchsToRefereesMutationHookResult = ReturnType<typeof useGenerateMatchsToRefereesMutation>;
export type GenerateMatchsToRefereesMutationResult = ApolloReactCommon.MutationResult<GenerateMatchsToRefereesMutation>;
export type GenerateMatchsToRefereesMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateMatchsToRefereesMutation, GenerateMatchsToRefereesMutationVariables>;
export const GenerateMatchsToVolunteersDocument = gql`
    mutation GenerateMatchsToVolunteers($input: GenerateMatchsToVolunteersInput!) {
  generateMatchsToVolunteers(input: $input) {
    data {
      volunteer {
        id
        firstName
        lastName
      }
      doc
    }
  }
}
    `;
export type GenerateMatchsToVolunteersMutationFn = ApolloReactCommon.MutationFunction<GenerateMatchsToVolunteersMutation, GenerateMatchsToVolunteersMutationVariables>;

/**
 * __useGenerateMatchsToVolunteersMutation__
 *
 * To run a mutation, you first call `useGenerateMatchsToVolunteersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMatchsToVolunteersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMatchsToVolunteersMutation, { data, loading, error }] = useGenerateMatchsToVolunteersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateMatchsToVolunteersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateMatchsToVolunteersMutation, GenerateMatchsToVolunteersMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateMatchsToVolunteersMutation, GenerateMatchsToVolunteersMutationVariables>(GenerateMatchsToVolunteersDocument, baseOptions);
      }
export type GenerateMatchsToVolunteersMutationHookResult = ReturnType<typeof useGenerateMatchsToVolunteersMutation>;
export type GenerateMatchsToVolunteersMutationResult = ApolloReactCommon.MutationResult<GenerateMatchsToVolunteersMutation>;
export type GenerateMatchsToVolunteersMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateMatchsToVolunteersMutation, GenerateMatchsToVolunteersMutationVariables>;
export const GenerateMeetingsToMembersDocument = gql`
    mutation GenerateMeetingsToMembers($input: GenerateMeetingsToMembersInput!) {
  generateMeetingsToMembers(input: $input) {
    data {
      member {
        id
        firstName
        lastName
      }
      doc
    }
  }
}
    `;
export type GenerateMeetingsToMembersMutationFn = ApolloReactCommon.MutationFunction<GenerateMeetingsToMembersMutation, GenerateMeetingsToMembersMutationVariables>;

/**
 * __useGenerateMeetingsToMembersMutation__
 *
 * To run a mutation, you first call `useGenerateMeetingsToMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMeetingsToMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMeetingsToMembersMutation, { data, loading, error }] = useGenerateMeetingsToMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateMeetingsToMembersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateMeetingsToMembersMutation, GenerateMeetingsToMembersMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateMeetingsToMembersMutation, GenerateMeetingsToMembersMutationVariables>(GenerateMeetingsToMembersDocument, baseOptions);
      }
export type GenerateMeetingsToMembersMutationHookResult = ReturnType<typeof useGenerateMeetingsToMembersMutation>;
export type GenerateMeetingsToMembersMutationResult = ApolloReactCommon.MutationResult<GenerateMeetingsToMembersMutation>;
export type GenerateMeetingsToMembersMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateMeetingsToMembersMutation, GenerateMeetingsToMembersMutationVariables>;
export const GenerateTrainingsToTraineesDocument = gql`
    mutation GenerateTrainingsToTrainees($input: GenerateTrainingsToTraineesInput!) {
  generateTrainingsToTrainees(input: $input) {
    data {
      trainee {
        id
        firstName
        lastName
      }
      doc
    }
  }
}
    `;
export type GenerateTrainingsToTraineesMutationFn = ApolloReactCommon.MutationFunction<GenerateTrainingsToTraineesMutation, GenerateTrainingsToTraineesMutationVariables>;

/**
 * __useGenerateTrainingsToTraineesMutation__
 *
 * To run a mutation, you first call `useGenerateTrainingsToTraineesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTrainingsToTraineesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTrainingsToTraineesMutation, { data, loading, error }] = useGenerateTrainingsToTraineesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateTrainingsToTraineesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateTrainingsToTraineesMutation, GenerateTrainingsToTraineesMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateTrainingsToTraineesMutation, GenerateTrainingsToTraineesMutationVariables>(GenerateTrainingsToTraineesDocument, baseOptions);
      }
export type GenerateTrainingsToTraineesMutationHookResult = ReturnType<typeof useGenerateTrainingsToTraineesMutation>;
export type GenerateTrainingsToTraineesMutationResult = ApolloReactCommon.MutationResult<GenerateTrainingsToTraineesMutation>;
export type GenerateTrainingsToTraineesMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateTrainingsToTraineesMutation, GenerateTrainingsToTraineesMutationVariables>;
export const GenerateTrainingsToTrainersDocument = gql`
    mutation GenerateTrainingsToTrainers($input: GenerateTrainingsToTrainersInput!) {
  generateTrainingsToTrainers(input: $input) {
    data {
      trainer {
        id
        firstName
        lastName
      }
      doc
    }
  }
}
    `;
export type GenerateTrainingsToTrainersMutationFn = ApolloReactCommon.MutationFunction<GenerateTrainingsToTrainersMutation, GenerateTrainingsToTrainersMutationVariables>;

/**
 * __useGenerateTrainingsToTrainersMutation__
 *
 * To run a mutation, you first call `useGenerateTrainingsToTrainersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTrainingsToTrainersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTrainingsToTrainersMutation, { data, loading, error }] = useGenerateTrainingsToTrainersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateTrainingsToTrainersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateTrainingsToTrainersMutation, GenerateTrainingsToTrainersMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateTrainingsToTrainersMutation, GenerateTrainingsToTrainersMutationVariables>(GenerateTrainingsToTrainersDocument, baseOptions);
      }
export type GenerateTrainingsToTrainersMutationHookResult = ReturnType<typeof useGenerateTrainingsToTrainersMutation>;
export type GenerateTrainingsToTrainersMutationResult = ApolloReactCommon.MutationResult<GenerateTrainingsToTrainersMutation>;
export type GenerateTrainingsToTrainersMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateTrainingsToTrainersMutation, GenerateTrainingsToTrainersMutationVariables>;
export const ImportMembersDocument = gql`
    mutation ImportMembers($input: ImportMembersInput!) {
  importMembers(input: $input) {
    success
    addedCount
    updatedCount
    removedCount
  }
}
    `;
export type ImportMembersMutationFn = ApolloReactCommon.MutationFunction<ImportMembersMutation, ImportMembersMutationVariables>;

/**
 * __useImportMembersMutation__
 *
 * To run a mutation, you first call `useImportMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importMembersMutation, { data, loading, error }] = useImportMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportMembersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportMembersMutation, ImportMembersMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportMembersMutation, ImportMembersMutationVariables>(ImportMembersDocument, baseOptions);
      }
export type ImportMembersMutationHookResult = ReturnType<typeof useImportMembersMutation>;
export type ImportMembersMutationResult = ApolloReactCommon.MutationResult<ImportMembersMutation>;
export type ImportMembersMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportMembersMutation, ImportMembersMutationVariables>;
export const ImportRefereesDocument = gql`
    mutation ImportReferees($input: ImportRefereesInput!) {
  importReferees(input: $input) {
    success
    addedCount
    updatedCount
    removedCount
  }
}
    `;
export type ImportRefereesMutationFn = ApolloReactCommon.MutationFunction<ImportRefereesMutation, ImportRefereesMutationVariables>;

/**
 * __useImportRefereesMutation__
 *
 * To run a mutation, you first call `useImportRefereesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportRefereesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importRefereesMutation, { data, loading, error }] = useImportRefereesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportRefereesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportRefereesMutation, ImportRefereesMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportRefereesMutation, ImportRefereesMutationVariables>(ImportRefereesDocument, baseOptions);
      }
export type ImportRefereesMutationHookResult = ReturnType<typeof useImportRefereesMutation>;
export type ImportRefereesMutationResult = ApolloReactCommon.MutationResult<ImportRefereesMutation>;
export type ImportRefereesMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportRefereesMutation, ImportRefereesMutationVariables>;
export const ImportTrainersDocument = gql`
    mutation ImportTrainers($input: ImportTrainersInput!) {
  importTrainers(input: $input) {
    success
    addedCount
    updatedCount
    removedCount
  }
}
    `;
export type ImportTrainersMutationFn = ApolloReactCommon.MutationFunction<ImportTrainersMutation, ImportTrainersMutationVariables>;

/**
 * __useImportTrainersMutation__
 *
 * To run a mutation, you first call `useImportTrainersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTrainersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTrainersMutation, { data, loading, error }] = useImportTrainersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportTrainersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportTrainersMutation, ImportTrainersMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportTrainersMutation, ImportTrainersMutationVariables>(ImportTrainersDocument, baseOptions);
      }
export type ImportTrainersMutationHookResult = ReturnType<typeof useImportTrainersMutation>;
export type ImportTrainersMutationResult = ApolloReactCommon.MutationResult<ImportTrainersMutation>;
export type ImportTrainersMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportTrainersMutation, ImportTrainersMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    success
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RelaunchMemberRequestDocument = gql`
    mutation RelaunchMemberRequest($input: RelaunchMemberRequestInput!) {
  relaunchMemberRequest(input: $input) {
    success
  }
}
    `;
export type RelaunchMemberRequestMutationFn = ApolloReactCommon.MutationFunction<RelaunchMemberRequestMutation, RelaunchMemberRequestMutationVariables>;

/**
 * __useRelaunchMemberRequestMutation__
 *
 * To run a mutation, you first call `useRelaunchMemberRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelaunchMemberRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relaunchMemberRequestMutation, { data, loading, error }] = useRelaunchMemberRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelaunchMemberRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RelaunchMemberRequestMutation, RelaunchMemberRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RelaunchMemberRequestMutation, RelaunchMemberRequestMutationVariables>(RelaunchMemberRequestDocument, baseOptions);
      }
export type RelaunchMemberRequestMutationHookResult = ReturnType<typeof useRelaunchMemberRequestMutation>;
export type RelaunchMemberRequestMutationResult = ApolloReactCommon.MutationResult<RelaunchMemberRequestMutation>;
export type RelaunchMemberRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RelaunchMemberRequestMutation, RelaunchMemberRequestMutationVariables>;
export const RelaunchRefereeRequestDocument = gql`
    mutation RelaunchRefereeRequest($input: RelaunchRefereeRequestInput!) {
  relaunchRefereeRequest(input: $input) {
    success
  }
}
    `;
export type RelaunchRefereeRequestMutationFn = ApolloReactCommon.MutationFunction<RelaunchRefereeRequestMutation, RelaunchRefereeRequestMutationVariables>;

/**
 * __useRelaunchRefereeRequestMutation__
 *
 * To run a mutation, you first call `useRelaunchRefereeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelaunchRefereeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relaunchRefereeRequestMutation, { data, loading, error }] = useRelaunchRefereeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelaunchRefereeRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RelaunchRefereeRequestMutation, RelaunchRefereeRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RelaunchRefereeRequestMutation, RelaunchRefereeRequestMutationVariables>(RelaunchRefereeRequestDocument, baseOptions);
      }
export type RelaunchRefereeRequestMutationHookResult = ReturnType<typeof useRelaunchRefereeRequestMutation>;
export type RelaunchRefereeRequestMutationResult = ApolloReactCommon.MutationResult<RelaunchRefereeRequestMutation>;
export type RelaunchRefereeRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RelaunchRefereeRequestMutation, RelaunchRefereeRequestMutationVariables>;
export const RelaunchTraineeRequestDocument = gql`
    mutation RelaunchTraineeRequest($input: RelaunchTraineeRequestInput!) {
  relaunchTraineeRequest(input: $input) {
    success
  }
}
    `;
export type RelaunchTraineeRequestMutationFn = ApolloReactCommon.MutationFunction<RelaunchTraineeRequestMutation, RelaunchTraineeRequestMutationVariables>;

/**
 * __useRelaunchTraineeRequestMutation__
 *
 * To run a mutation, you first call `useRelaunchTraineeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelaunchTraineeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relaunchTraineeRequestMutation, { data, loading, error }] = useRelaunchTraineeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelaunchTraineeRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RelaunchTraineeRequestMutation, RelaunchTraineeRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RelaunchTraineeRequestMutation, RelaunchTraineeRequestMutationVariables>(RelaunchTraineeRequestDocument, baseOptions);
      }
export type RelaunchTraineeRequestMutationHookResult = ReturnType<typeof useRelaunchTraineeRequestMutation>;
export type RelaunchTraineeRequestMutationResult = ApolloReactCommon.MutationResult<RelaunchTraineeRequestMutation>;
export type RelaunchTraineeRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RelaunchTraineeRequestMutation, RelaunchTraineeRequestMutationVariables>;
export const RelaunchTrainerRequestDocument = gql`
    mutation RelaunchTrainerRequest($input: RelaunchTrainerRequestInput!) {
  relaunchTrainerRequest(input: $input) {
    success
  }
}
    `;
export type RelaunchTrainerRequestMutationFn = ApolloReactCommon.MutationFunction<RelaunchTrainerRequestMutation, RelaunchTrainerRequestMutationVariables>;

/**
 * __useRelaunchTrainerRequestMutation__
 *
 * To run a mutation, you first call `useRelaunchTrainerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelaunchTrainerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relaunchTrainerRequestMutation, { data, loading, error }] = useRelaunchTrainerRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelaunchTrainerRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RelaunchTrainerRequestMutation, RelaunchTrainerRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RelaunchTrainerRequestMutation, RelaunchTrainerRequestMutationVariables>(RelaunchTrainerRequestDocument, baseOptions);
      }
export type RelaunchTrainerRequestMutationHookResult = ReturnType<typeof useRelaunchTrainerRequestMutation>;
export type RelaunchTrainerRequestMutationResult = ApolloReactCommon.MutationResult<RelaunchTrainerRequestMutation>;
export type RelaunchTrainerRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RelaunchTrainerRequestMutation, RelaunchTrainerRequestMutationVariables>;
export const RelaunchVolunteerRequestDocument = gql`
    mutation RelaunchVolunteerRequest($input: RelaunchVolunteerRequestInput!) {
  relaunchVolunteerRequest(input: $input) {
    success
  }
}
    `;
export type RelaunchVolunteerRequestMutationFn = ApolloReactCommon.MutationFunction<RelaunchVolunteerRequestMutation, RelaunchVolunteerRequestMutationVariables>;

/**
 * __useRelaunchVolunteerRequestMutation__
 *
 * To run a mutation, you first call `useRelaunchVolunteerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelaunchVolunteerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relaunchVolunteerRequestMutation, { data, loading, error }] = useRelaunchVolunteerRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelaunchVolunteerRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RelaunchVolunteerRequestMutation, RelaunchVolunteerRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RelaunchVolunteerRequestMutation, RelaunchVolunteerRequestMutationVariables>(RelaunchVolunteerRequestDocument, baseOptions);
      }
export type RelaunchVolunteerRequestMutationHookResult = ReturnType<typeof useRelaunchVolunteerRequestMutation>;
export type RelaunchVolunteerRequestMutationResult = ApolloReactCommon.MutationResult<RelaunchVolunteerRequestMutation>;
export type RelaunchVolunteerRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RelaunchVolunteerRequestMutation, RelaunchVolunteerRequestMutationVariables>;
export const RemoveAdminDocument = gql`
    mutation RemoveAdmin($input: RemoveAdminInput!) {
  removeAdmin(input: $input) {
    success
  }
}
    `;
export type RemoveAdminMutationFn = ApolloReactCommon.MutationFunction<RemoveAdminMutation, RemoveAdminMutationVariables>;

/**
 * __useRemoveAdminMutation__
 *
 * To run a mutation, you first call `useRemoveAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdminMutation, { data, loading, error }] = useRemoveAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAdminMutation, RemoveAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAdminMutation, RemoveAdminMutationVariables>(RemoveAdminDocument, baseOptions);
      }
export type RemoveAdminMutationHookResult = ReturnType<typeof useRemoveAdminMutation>;
export type RemoveAdminMutationResult = ApolloReactCommon.MutationResult<RemoveAdminMutation>;
export type RemoveAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAdminMutation, RemoveAdminMutationVariables>;
export const RemoveCompetitionDocument = gql`
    mutation RemoveCompetition($input: RemoveCompetitionInput!) {
  removeCompetition(input: $input) {
    success
  }
}
    `;
export type RemoveCompetitionMutationFn = ApolloReactCommon.MutationFunction<RemoveCompetitionMutation, RemoveCompetitionMutationVariables>;

/**
 * __useRemoveCompetitionMutation__
 *
 * To run a mutation, you first call `useRemoveCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompetitionMutation, { data, loading, error }] = useRemoveCompetitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCompetitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCompetitionMutation, RemoveCompetitionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCompetitionMutation, RemoveCompetitionMutationVariables>(RemoveCompetitionDocument, baseOptions);
      }
export type RemoveCompetitionMutationHookResult = ReturnType<typeof useRemoveCompetitionMutation>;
export type RemoveCompetitionMutationResult = ApolloReactCommon.MutationResult<RemoveCompetitionMutation>;
export type RemoveCompetitionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCompetitionMutation, RemoveCompetitionMutationVariables>;
export const RemoveMatchDocument = gql`
    mutation RemoveMatch($input: RemoveMatchInput!) {
  removeMatch(input: $input) {
    success
  }
}
    `;
export type RemoveMatchMutationFn = ApolloReactCommon.MutationFunction<RemoveMatchMutation, RemoveMatchMutationVariables>;

/**
 * __useRemoveMatchMutation__
 *
 * To run a mutation, you first call `useRemoveMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMatchMutation, { data, loading, error }] = useRemoveMatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMatchMutation, RemoveMatchMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMatchMutation, RemoveMatchMutationVariables>(RemoveMatchDocument, baseOptions);
      }
export type RemoveMatchMutationHookResult = ReturnType<typeof useRemoveMatchMutation>;
export type RemoveMatchMutationResult = ApolloReactCommon.MutationResult<RemoveMatchMutation>;
export type RemoveMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMatchMutation, RemoveMatchMutationVariables>;
export const RemoveMatchRefereeDocument = gql`
    mutation RemoveMatchReferee($input: RemoveMatchRefereeInput!) {
  removeMatchReferee(input: $input) {
    success
  }
}
    `;
export type RemoveMatchRefereeMutationFn = ApolloReactCommon.MutationFunction<RemoveMatchRefereeMutation, RemoveMatchRefereeMutationVariables>;

/**
 * __useRemoveMatchRefereeMutation__
 *
 * To run a mutation, you first call `useRemoveMatchRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMatchRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMatchRefereeMutation, { data, loading, error }] = useRemoveMatchRefereeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMatchRefereeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMatchRefereeMutation, RemoveMatchRefereeMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMatchRefereeMutation, RemoveMatchRefereeMutationVariables>(RemoveMatchRefereeDocument, baseOptions);
      }
export type RemoveMatchRefereeMutationHookResult = ReturnType<typeof useRemoveMatchRefereeMutation>;
export type RemoveMatchRefereeMutationResult = ApolloReactCommon.MutationResult<RemoveMatchRefereeMutation>;
export type RemoveMatchRefereeMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMatchRefereeMutation, RemoveMatchRefereeMutationVariables>;
export const RemoveMatchVolunteerDocument = gql`
    mutation RemoveMatchVolunteer($input: RemoveMatchVolunteerInput!) {
  removeMatchVolunteer(input: $input) {
    success
  }
}
    `;
export type RemoveMatchVolunteerMutationFn = ApolloReactCommon.MutationFunction<RemoveMatchVolunteerMutation, RemoveMatchVolunteerMutationVariables>;

/**
 * __useRemoveMatchVolunteerMutation__
 *
 * To run a mutation, you first call `useRemoveMatchVolunteerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMatchVolunteerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMatchVolunteerMutation, { data, loading, error }] = useRemoveMatchVolunteerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMatchVolunteerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMatchVolunteerMutation, RemoveMatchVolunteerMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMatchVolunteerMutation, RemoveMatchVolunteerMutationVariables>(RemoveMatchVolunteerDocument, baseOptions);
      }
export type RemoveMatchVolunteerMutationHookResult = ReturnType<typeof useRemoveMatchVolunteerMutation>;
export type RemoveMatchVolunteerMutationResult = ApolloReactCommon.MutationResult<RemoveMatchVolunteerMutation>;
export type RemoveMatchVolunteerMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMatchVolunteerMutation, RemoveMatchVolunteerMutationVariables>;
export const RemoveMeetingDocument = gql`
    mutation RemoveMeeting($input: RemoveMeetingInput!) {
  removeMeeting(input: $input) {
    success
  }
}
    `;
export type RemoveMeetingMutationFn = ApolloReactCommon.MutationFunction<RemoveMeetingMutation, RemoveMeetingMutationVariables>;

/**
 * __useRemoveMeetingMutation__
 *
 * To run a mutation, you first call `useRemoveMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMeetingMutation, { data, loading, error }] = useRemoveMeetingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMeetingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMeetingMutation, RemoveMeetingMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMeetingMutation, RemoveMeetingMutationVariables>(RemoveMeetingDocument, baseOptions);
      }
export type RemoveMeetingMutationHookResult = ReturnType<typeof useRemoveMeetingMutation>;
export type RemoveMeetingMutationResult = ApolloReactCommon.MutationResult<RemoveMeetingMutation>;
export type RemoveMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMeetingMutation, RemoveMeetingMutationVariables>;
export const RemoveMeetingMemberDocument = gql`
    mutation RemoveMeetingMember($input: RemoveMeetingMemberInput!) {
  removeMeetingMember(input: $input) {
    success
  }
}
    `;
export type RemoveMeetingMemberMutationFn = ApolloReactCommon.MutationFunction<RemoveMeetingMemberMutation, RemoveMeetingMemberMutationVariables>;

/**
 * __useRemoveMeetingMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMeetingMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMeetingMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMeetingMemberMutation, { data, loading, error }] = useRemoveMeetingMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMeetingMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMeetingMemberMutation, RemoveMeetingMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMeetingMemberMutation, RemoveMeetingMemberMutationVariables>(RemoveMeetingMemberDocument, baseOptions);
      }
export type RemoveMeetingMemberMutationHookResult = ReturnType<typeof useRemoveMeetingMemberMutation>;
export type RemoveMeetingMemberMutationResult = ApolloReactCommon.MutationResult<RemoveMeetingMemberMutation>;
export type RemoveMeetingMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMeetingMemberMutation, RemoveMeetingMemberMutationVariables>;
export const RemoveTraineeCategoryDocument = gql`
    mutation RemoveTraineeCategory($input: RemoveTraineeCategoryInput!) {
  removeTraineeCategory(input: $input) {
    success
  }
}
    `;
export type RemoveTraineeCategoryMutationFn = ApolloReactCommon.MutationFunction<RemoveTraineeCategoryMutation, RemoveTraineeCategoryMutationVariables>;

/**
 * __useRemoveTraineeCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveTraineeCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTraineeCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTraineeCategoryMutation, { data, loading, error }] = useRemoveTraineeCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTraineeCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTraineeCategoryMutation, RemoveTraineeCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTraineeCategoryMutation, RemoveTraineeCategoryMutationVariables>(RemoveTraineeCategoryDocument, baseOptions);
      }
export type RemoveTraineeCategoryMutationHookResult = ReturnType<typeof useRemoveTraineeCategoryMutation>;
export type RemoveTraineeCategoryMutationResult = ApolloReactCommon.MutationResult<RemoveTraineeCategoryMutation>;
export type RemoveTraineeCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTraineeCategoryMutation, RemoveTraineeCategoryMutationVariables>;
export const RemoveTrainingDocument = gql`
    mutation RemoveTraining($input: RemoveTrainingInput!) {
  removeTraining(input: $input) {
    success
  }
}
    `;
export type RemoveTrainingMutationFn = ApolloReactCommon.MutationFunction<RemoveTrainingMutation, RemoveTrainingMutationVariables>;

/**
 * __useRemoveTrainingMutation__
 *
 * To run a mutation, you first call `useRemoveTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrainingMutation, { data, loading, error }] = useRemoveTrainingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTrainingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTrainingMutation, RemoveTrainingMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTrainingMutation, RemoveTrainingMutationVariables>(RemoveTrainingDocument, baseOptions);
      }
export type RemoveTrainingMutationHookResult = ReturnType<typeof useRemoveTrainingMutation>;
export type RemoveTrainingMutationResult = ApolloReactCommon.MutationResult<RemoveTrainingMutation>;
export type RemoveTrainingMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTrainingMutation, RemoveTrainingMutationVariables>;
export const RemoveTrainingTraineeDocument = gql`
    mutation RemoveTrainingTrainee($input: RemoveTrainingTraineeInput!) {
  removeTrainingTrainee(input: $input) {
    success
  }
}
    `;
export type RemoveTrainingTraineeMutationFn = ApolloReactCommon.MutationFunction<RemoveTrainingTraineeMutation, RemoveTrainingTraineeMutationVariables>;

/**
 * __useRemoveTrainingTraineeMutation__
 *
 * To run a mutation, you first call `useRemoveTrainingTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrainingTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrainingTraineeMutation, { data, loading, error }] = useRemoveTrainingTraineeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTrainingTraineeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTrainingTraineeMutation, RemoveTrainingTraineeMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTrainingTraineeMutation, RemoveTrainingTraineeMutationVariables>(RemoveTrainingTraineeDocument, baseOptions);
      }
export type RemoveTrainingTraineeMutationHookResult = ReturnType<typeof useRemoveTrainingTraineeMutation>;
export type RemoveTrainingTraineeMutationResult = ApolloReactCommon.MutationResult<RemoveTrainingTraineeMutation>;
export type RemoveTrainingTraineeMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTrainingTraineeMutation, RemoveTrainingTraineeMutationVariables>;
export const RemoveTrainingTrainerDocument = gql`
    mutation RemoveTrainingTrainer($input: RemoveTrainingTrainerInput!) {
  removeTrainingTrainer(input: $input) {
    success
  }
}
    `;
export type RemoveTrainingTrainerMutationFn = ApolloReactCommon.MutationFunction<RemoveTrainingTrainerMutation, RemoveTrainingTrainerMutationVariables>;

/**
 * __useRemoveTrainingTrainerMutation__
 *
 * To run a mutation, you first call `useRemoveTrainingTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrainingTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrainingTrainerMutation, { data, loading, error }] = useRemoveTrainingTrainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTrainingTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTrainingTrainerMutation, RemoveTrainingTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTrainingTrainerMutation, RemoveTrainingTrainerMutationVariables>(RemoveTrainingTrainerDocument, baseOptions);
      }
export type RemoveTrainingTrainerMutationHookResult = ReturnType<typeof useRemoveTrainingTrainerMutation>;
export type RemoveTrainingTrainerMutationResult = ApolloReactCommon.MutationResult<RemoveTrainingTrainerMutation>;
export type RemoveTrainingTrainerMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTrainingTrainerMutation, RemoveTrainingTrainerMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($input: RemoveUserInput!) {
  removeUser(input: $input) {
    success
  }
}
    `;
export type RemoveUserMutationFn = ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = ApolloReactCommon.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation RequestResetPassword($input: RequestResetPasswordInput!) {
  requestResetPassword(input: $input) {
    success
  }
}
    `;
export type RequestResetPasswordMutationFn = ApolloReactCommon.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, baseOptions);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = ApolloReactCommon.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    success
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RespondToMatchRequestDocument = gql`
    mutation RespondToMatchRequest($input: RespondToMatchRequestInput!) {
  respondToMatchRequest(input: $input) {
    success
  }
}
    `;
export type RespondToMatchRequestMutationFn = ApolloReactCommon.MutationFunction<RespondToMatchRequestMutation, RespondToMatchRequestMutationVariables>;

/**
 * __useRespondToMatchRequestMutation__
 *
 * To run a mutation, you first call `useRespondToMatchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToMatchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToMatchRequestMutation, { data, loading, error }] = useRespondToMatchRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRespondToMatchRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespondToMatchRequestMutation, RespondToMatchRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RespondToMatchRequestMutation, RespondToMatchRequestMutationVariables>(RespondToMatchRequestDocument, baseOptions);
      }
export type RespondToMatchRequestMutationHookResult = ReturnType<typeof useRespondToMatchRequestMutation>;
export type RespondToMatchRequestMutationResult = ApolloReactCommon.MutationResult<RespondToMatchRequestMutation>;
export type RespondToMatchRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RespondToMatchRequestMutation, RespondToMatchRequestMutationVariables>;
export const RespondToMatchVolunteerRequestDocument = gql`
    mutation RespondToMatchVolunteerRequest($input: RespondToMatchVolunteerRequestInput!) {
  respondToMatchVolunteerRequest(input: $input) {
    success
  }
}
    `;
export type RespondToMatchVolunteerRequestMutationFn = ApolloReactCommon.MutationFunction<RespondToMatchVolunteerRequestMutation, RespondToMatchVolunteerRequestMutationVariables>;

/**
 * __useRespondToMatchVolunteerRequestMutation__
 *
 * To run a mutation, you first call `useRespondToMatchVolunteerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToMatchVolunteerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToMatchVolunteerRequestMutation, { data, loading, error }] = useRespondToMatchVolunteerRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRespondToMatchVolunteerRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespondToMatchVolunteerRequestMutation, RespondToMatchVolunteerRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RespondToMatchVolunteerRequestMutation, RespondToMatchVolunteerRequestMutationVariables>(RespondToMatchVolunteerRequestDocument, baseOptions);
      }
export type RespondToMatchVolunteerRequestMutationHookResult = ReturnType<typeof useRespondToMatchVolunteerRequestMutation>;
export type RespondToMatchVolunteerRequestMutationResult = ApolloReactCommon.MutationResult<RespondToMatchVolunteerRequestMutation>;
export type RespondToMatchVolunteerRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RespondToMatchVolunteerRequestMutation, RespondToMatchVolunteerRequestMutationVariables>;
export const RespondToMeetingRequestDocument = gql`
    mutation RespondToMeetingRequest($input: RespondToMeetingRequestInput!) {
  respondToMeetingRequest(input: $input) {
    success
  }
}
    `;
export type RespondToMeetingRequestMutationFn = ApolloReactCommon.MutationFunction<RespondToMeetingRequestMutation, RespondToMeetingRequestMutationVariables>;

/**
 * __useRespondToMeetingRequestMutation__
 *
 * To run a mutation, you first call `useRespondToMeetingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToMeetingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToMeetingRequestMutation, { data, loading, error }] = useRespondToMeetingRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRespondToMeetingRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespondToMeetingRequestMutation, RespondToMeetingRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RespondToMeetingRequestMutation, RespondToMeetingRequestMutationVariables>(RespondToMeetingRequestDocument, baseOptions);
      }
export type RespondToMeetingRequestMutationHookResult = ReturnType<typeof useRespondToMeetingRequestMutation>;
export type RespondToMeetingRequestMutationResult = ApolloReactCommon.MutationResult<RespondToMeetingRequestMutation>;
export type RespondToMeetingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RespondToMeetingRequestMutation, RespondToMeetingRequestMutationVariables>;
export const RespondToTrainingRequestDocument = gql`
    mutation RespondToTrainingRequest($input: RespondToTrainingRequestInput!) {
  respondToTrainingRequest(input: $input) {
    success
  }
}
    `;
export type RespondToTrainingRequestMutationFn = ApolloReactCommon.MutationFunction<RespondToTrainingRequestMutation, RespondToTrainingRequestMutationVariables>;

/**
 * __useRespondToTrainingRequestMutation__
 *
 * To run a mutation, you first call `useRespondToTrainingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToTrainingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToTrainingRequestMutation, { data, loading, error }] = useRespondToTrainingRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRespondToTrainingRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespondToTrainingRequestMutation, RespondToTrainingRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RespondToTrainingRequestMutation, RespondToTrainingRequestMutationVariables>(RespondToTrainingRequestDocument, baseOptions);
      }
export type RespondToTrainingRequestMutationHookResult = ReturnType<typeof useRespondToTrainingRequestMutation>;
export type RespondToTrainingRequestMutationResult = ApolloReactCommon.MutationResult<RespondToTrainingRequestMutation>;
export type RespondToTrainingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RespondToTrainingRequestMutation, RespondToTrainingRequestMutationVariables>;
export const RespondToTrainingTraineeRequestDocument = gql`
    mutation RespondToTrainingTraineeRequest($input: RespondToTrainingTraineeRequestInput!) {
  respondToTrainingTraineeRequest(input: $input) {
    success
  }
}
    `;
export type RespondToTrainingTraineeRequestMutationFn = ApolloReactCommon.MutationFunction<RespondToTrainingTraineeRequestMutation, RespondToTrainingTraineeRequestMutationVariables>;

/**
 * __useRespondToTrainingTraineeRequestMutation__
 *
 * To run a mutation, you first call `useRespondToTrainingTraineeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToTrainingTraineeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToTrainingTraineeRequestMutation, { data, loading, error }] = useRespondToTrainingTraineeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRespondToTrainingTraineeRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespondToTrainingTraineeRequestMutation, RespondToTrainingTraineeRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<RespondToTrainingTraineeRequestMutation, RespondToTrainingTraineeRequestMutationVariables>(RespondToTrainingTraineeRequestDocument, baseOptions);
      }
export type RespondToTrainingTraineeRequestMutationHookResult = ReturnType<typeof useRespondToTrainingTraineeRequestMutation>;
export type RespondToTrainingTraineeRequestMutationResult = ApolloReactCommon.MutationResult<RespondToTrainingTraineeRequestMutation>;
export type RespondToTrainingTraineeRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<RespondToTrainingTraineeRequestMutation, RespondToTrainingTraineeRequestMutationVariables>;
export const SendMatchRefereeEmailDocument = gql`
    mutation SendMatchRefereeEmail($input: SendMatchRefereeEmailInput!) {
  sendMatchRefereeEmail(input: $input) {
    success
  }
}
    `;
export type SendMatchRefereeEmailMutationFn = ApolloReactCommon.MutationFunction<SendMatchRefereeEmailMutation, SendMatchRefereeEmailMutationVariables>;

/**
 * __useSendMatchRefereeEmailMutation__
 *
 * To run a mutation, you first call `useSendMatchRefereeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMatchRefereeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMatchRefereeEmailMutation, { data, loading, error }] = useSendMatchRefereeEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMatchRefereeEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMatchRefereeEmailMutation, SendMatchRefereeEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMatchRefereeEmailMutation, SendMatchRefereeEmailMutationVariables>(SendMatchRefereeEmailDocument, baseOptions);
      }
export type SendMatchRefereeEmailMutationHookResult = ReturnType<typeof useSendMatchRefereeEmailMutation>;
export type SendMatchRefereeEmailMutationResult = ApolloReactCommon.MutationResult<SendMatchRefereeEmailMutation>;
export type SendMatchRefereeEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMatchRefereeEmailMutation, SendMatchRefereeEmailMutationVariables>;
export const SendMatchRequestDocument = gql`
    mutation SendMatchRequest($input: SendMatchRequestInput!) {
  sendMatchRequest(input: $input) {
    success
  }
}
    `;
export type SendMatchRequestMutationFn = ApolloReactCommon.MutationFunction<SendMatchRequestMutation, SendMatchRequestMutationVariables>;

/**
 * __useSendMatchRequestMutation__
 *
 * To run a mutation, you first call `useSendMatchRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMatchRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMatchRequestMutation, { data, loading, error }] = useSendMatchRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMatchRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMatchRequestMutation, SendMatchRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMatchRequestMutation, SendMatchRequestMutationVariables>(SendMatchRequestDocument, baseOptions);
      }
export type SendMatchRequestMutationHookResult = ReturnType<typeof useSendMatchRequestMutation>;
export type SendMatchRequestMutationResult = ApolloReactCommon.MutationResult<SendMatchRequestMutation>;
export type SendMatchRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMatchRequestMutation, SendMatchRequestMutationVariables>;
export const SendMatchVolunteerEmailDocument = gql`
    mutation SendMatchVolunteerEmail($input: SendMatchVolunteerEmailInput!) {
  sendMatchVolunteerEmail(input: $input) {
    success
  }
}
    `;
export type SendMatchVolunteerEmailMutationFn = ApolloReactCommon.MutationFunction<SendMatchVolunteerEmailMutation, SendMatchVolunteerEmailMutationVariables>;

/**
 * __useSendMatchVolunteerEmailMutation__
 *
 * To run a mutation, you first call `useSendMatchVolunteerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMatchVolunteerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMatchVolunteerEmailMutation, { data, loading, error }] = useSendMatchVolunteerEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMatchVolunteerEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMatchVolunteerEmailMutation, SendMatchVolunteerEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMatchVolunteerEmailMutation, SendMatchVolunteerEmailMutationVariables>(SendMatchVolunteerEmailDocument, baseOptions);
      }
export type SendMatchVolunteerEmailMutationHookResult = ReturnType<typeof useSendMatchVolunteerEmailMutation>;
export type SendMatchVolunteerEmailMutationResult = ApolloReactCommon.MutationResult<SendMatchVolunteerEmailMutation>;
export type SendMatchVolunteerEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMatchVolunteerEmailMutation, SendMatchVolunteerEmailMutationVariables>;
export const SendMatchVolunteerRequestDocument = gql`
    mutation SendMatchVolunteerRequest($input: SendMatchVolunteerRequestInput!) {
  sendMatchVolunteerRequest(input: $input) {
    success
  }
}
    `;
export type SendMatchVolunteerRequestMutationFn = ApolloReactCommon.MutationFunction<SendMatchVolunteerRequestMutation, SendMatchVolunteerRequestMutationVariables>;

/**
 * __useSendMatchVolunteerRequestMutation__
 *
 * To run a mutation, you first call `useSendMatchVolunteerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMatchVolunteerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMatchVolunteerRequestMutation, { data, loading, error }] = useSendMatchVolunteerRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMatchVolunteerRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMatchVolunteerRequestMutation, SendMatchVolunteerRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMatchVolunteerRequestMutation, SendMatchVolunteerRequestMutationVariables>(SendMatchVolunteerRequestDocument, baseOptions);
      }
export type SendMatchVolunteerRequestMutationHookResult = ReturnType<typeof useSendMatchVolunteerRequestMutation>;
export type SendMatchVolunteerRequestMutationResult = ApolloReactCommon.MutationResult<SendMatchVolunteerRequestMutation>;
export type SendMatchVolunteerRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMatchVolunteerRequestMutation, SendMatchVolunteerRequestMutationVariables>;
export const SendMatchsToRefereesDocument = gql`
    mutation SendMatchsToReferees($input: SendMatchsToRefereesInput!) {
  sendMatchsToReferees(input: $input) {
    success
  }
}
    `;
export type SendMatchsToRefereesMutationFn = ApolloReactCommon.MutationFunction<SendMatchsToRefereesMutation, SendMatchsToRefereesMutationVariables>;

/**
 * __useSendMatchsToRefereesMutation__
 *
 * To run a mutation, you first call `useSendMatchsToRefereesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMatchsToRefereesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMatchsToRefereesMutation, { data, loading, error }] = useSendMatchsToRefereesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMatchsToRefereesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMatchsToRefereesMutation, SendMatchsToRefereesMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMatchsToRefereesMutation, SendMatchsToRefereesMutationVariables>(SendMatchsToRefereesDocument, baseOptions);
      }
export type SendMatchsToRefereesMutationHookResult = ReturnType<typeof useSendMatchsToRefereesMutation>;
export type SendMatchsToRefereesMutationResult = ApolloReactCommon.MutationResult<SendMatchsToRefereesMutation>;
export type SendMatchsToRefereesMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMatchsToRefereesMutation, SendMatchsToRefereesMutationVariables>;
export const SendMatchsToVolunteersDocument = gql`
    mutation SendMatchsToVolunteers($input: SendMatchsToVolunteersInput!) {
  sendMatchsToVolunteers(input: $input) {
    success
  }
}
    `;
export type SendMatchsToVolunteersMutationFn = ApolloReactCommon.MutationFunction<SendMatchsToVolunteersMutation, SendMatchsToVolunteersMutationVariables>;

/**
 * __useSendMatchsToVolunteersMutation__
 *
 * To run a mutation, you first call `useSendMatchsToVolunteersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMatchsToVolunteersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMatchsToVolunteersMutation, { data, loading, error }] = useSendMatchsToVolunteersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMatchsToVolunteersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMatchsToVolunteersMutation, SendMatchsToVolunteersMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMatchsToVolunteersMutation, SendMatchsToVolunteersMutationVariables>(SendMatchsToVolunteersDocument, baseOptions);
      }
export type SendMatchsToVolunteersMutationHookResult = ReturnType<typeof useSendMatchsToVolunteersMutation>;
export type SendMatchsToVolunteersMutationResult = ApolloReactCommon.MutationResult<SendMatchsToVolunteersMutation>;
export type SendMatchsToVolunteersMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMatchsToVolunteersMutation, SendMatchsToVolunteersMutationVariables>;
export const SendMeetingMemberEmailDocument = gql`
    mutation SendMeetingMemberEmail($input: SendMeetingMemberEmailInput!) {
  sendMeetingMemberEmail(input: $input) {
    success
  }
}
    `;
export type SendMeetingMemberEmailMutationFn = ApolloReactCommon.MutationFunction<SendMeetingMemberEmailMutation, SendMeetingMemberEmailMutationVariables>;

/**
 * __useSendMeetingMemberEmailMutation__
 *
 * To run a mutation, you first call `useSendMeetingMemberEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMeetingMemberEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMeetingMemberEmailMutation, { data, loading, error }] = useSendMeetingMemberEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMeetingMemberEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMeetingMemberEmailMutation, SendMeetingMemberEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMeetingMemberEmailMutation, SendMeetingMemberEmailMutationVariables>(SendMeetingMemberEmailDocument, baseOptions);
      }
export type SendMeetingMemberEmailMutationHookResult = ReturnType<typeof useSendMeetingMemberEmailMutation>;
export type SendMeetingMemberEmailMutationResult = ApolloReactCommon.MutationResult<SendMeetingMemberEmailMutation>;
export type SendMeetingMemberEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMeetingMemberEmailMutation, SendMeetingMemberEmailMutationVariables>;
export const SendMeetingRequestDocument = gql`
    mutation SendMeetingRequest($input: SendMeetingRequestInput!) {
  sendMeetingRequest(input: $input) {
    success
  }
}
    `;
export type SendMeetingRequestMutationFn = ApolloReactCommon.MutationFunction<SendMeetingRequestMutation, SendMeetingRequestMutationVariables>;

/**
 * __useSendMeetingRequestMutation__
 *
 * To run a mutation, you first call `useSendMeetingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMeetingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMeetingRequestMutation, { data, loading, error }] = useSendMeetingRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMeetingRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMeetingRequestMutation, SendMeetingRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMeetingRequestMutation, SendMeetingRequestMutationVariables>(SendMeetingRequestDocument, baseOptions);
      }
export type SendMeetingRequestMutationHookResult = ReturnType<typeof useSendMeetingRequestMutation>;
export type SendMeetingRequestMutationResult = ApolloReactCommon.MutationResult<SendMeetingRequestMutation>;
export type SendMeetingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMeetingRequestMutation, SendMeetingRequestMutationVariables>;
export const SendMeetingsToMembersDocument = gql`
    mutation SendMeetingsToMembers($input: SendMeetingsToMembersInput!) {
  sendMeetingsToMembers(input: $input) {
    success
  }
}
    `;
export type SendMeetingsToMembersMutationFn = ApolloReactCommon.MutationFunction<SendMeetingsToMembersMutation, SendMeetingsToMembersMutationVariables>;

/**
 * __useSendMeetingsToMembersMutation__
 *
 * To run a mutation, you first call `useSendMeetingsToMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMeetingsToMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMeetingsToMembersMutation, { data, loading, error }] = useSendMeetingsToMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMeetingsToMembersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMeetingsToMembersMutation, SendMeetingsToMembersMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMeetingsToMembersMutation, SendMeetingsToMembersMutationVariables>(SendMeetingsToMembersDocument, baseOptions);
      }
export type SendMeetingsToMembersMutationHookResult = ReturnType<typeof useSendMeetingsToMembersMutation>;
export type SendMeetingsToMembersMutationResult = ApolloReactCommon.MutationResult<SendMeetingsToMembersMutation>;
export type SendMeetingsToMembersMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMeetingsToMembersMutation, SendMeetingsToMembersMutationVariables>;
export const SendTrainingRequestDocument = gql`
    mutation SendTrainingRequest($input: SendTrainingRequestInput!) {
  sendTrainingRequest(input: $input) {
    success
  }
}
    `;
export type SendTrainingRequestMutationFn = ApolloReactCommon.MutationFunction<SendTrainingRequestMutation, SendTrainingRequestMutationVariables>;

/**
 * __useSendTrainingRequestMutation__
 *
 * To run a mutation, you first call `useSendTrainingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTrainingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTrainingRequestMutation, { data, loading, error }] = useSendTrainingRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTrainingRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTrainingRequestMutation, SendTrainingRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SendTrainingRequestMutation, SendTrainingRequestMutationVariables>(SendTrainingRequestDocument, baseOptions);
      }
export type SendTrainingRequestMutationHookResult = ReturnType<typeof useSendTrainingRequestMutation>;
export type SendTrainingRequestMutationResult = ApolloReactCommon.MutationResult<SendTrainingRequestMutation>;
export type SendTrainingRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTrainingRequestMutation, SendTrainingRequestMutationVariables>;
export const SendTrainingTraineeEmailDocument = gql`
    mutation SendTrainingTraineeEmail($input: SendTrainingTraineeEmailInput!) {
  sendTrainingTraineeEmail(input: $input) {
    success
  }
}
    `;
export type SendTrainingTraineeEmailMutationFn = ApolloReactCommon.MutationFunction<SendTrainingTraineeEmailMutation, SendTrainingTraineeEmailMutationVariables>;

/**
 * __useSendTrainingTraineeEmailMutation__
 *
 * To run a mutation, you first call `useSendTrainingTraineeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTrainingTraineeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTrainingTraineeEmailMutation, { data, loading, error }] = useSendTrainingTraineeEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTrainingTraineeEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTrainingTraineeEmailMutation, SendTrainingTraineeEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendTrainingTraineeEmailMutation, SendTrainingTraineeEmailMutationVariables>(SendTrainingTraineeEmailDocument, baseOptions);
      }
export type SendTrainingTraineeEmailMutationHookResult = ReturnType<typeof useSendTrainingTraineeEmailMutation>;
export type SendTrainingTraineeEmailMutationResult = ApolloReactCommon.MutationResult<SendTrainingTraineeEmailMutation>;
export type SendTrainingTraineeEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTrainingTraineeEmailMutation, SendTrainingTraineeEmailMutationVariables>;
export const SendTrainingTraineeRequestDocument = gql`
    mutation SendTrainingTraineeRequest($input: SendTrainingTraineeRequestInput!) {
  sendTrainingTraineeRequest(input: $input) {
    success
  }
}
    `;
export type SendTrainingTraineeRequestMutationFn = ApolloReactCommon.MutationFunction<SendTrainingTraineeRequestMutation, SendTrainingTraineeRequestMutationVariables>;

/**
 * __useSendTrainingTraineeRequestMutation__
 *
 * To run a mutation, you first call `useSendTrainingTraineeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTrainingTraineeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTrainingTraineeRequestMutation, { data, loading, error }] = useSendTrainingTraineeRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTrainingTraineeRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTrainingTraineeRequestMutation, SendTrainingTraineeRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<SendTrainingTraineeRequestMutation, SendTrainingTraineeRequestMutationVariables>(SendTrainingTraineeRequestDocument, baseOptions);
      }
export type SendTrainingTraineeRequestMutationHookResult = ReturnType<typeof useSendTrainingTraineeRequestMutation>;
export type SendTrainingTraineeRequestMutationResult = ApolloReactCommon.MutationResult<SendTrainingTraineeRequestMutation>;
export type SendTrainingTraineeRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTrainingTraineeRequestMutation, SendTrainingTraineeRequestMutationVariables>;
export const SendTrainingTrainerEmailDocument = gql`
    mutation SendTrainingTrainerEmail($input: SendTrainingTrainerEmailInput!) {
  sendTrainingTrainerEmail(input: $input) {
    success
  }
}
    `;
export type SendTrainingTrainerEmailMutationFn = ApolloReactCommon.MutationFunction<SendTrainingTrainerEmailMutation, SendTrainingTrainerEmailMutationVariables>;

/**
 * __useSendTrainingTrainerEmailMutation__
 *
 * To run a mutation, you first call `useSendTrainingTrainerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTrainingTrainerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTrainingTrainerEmailMutation, { data, loading, error }] = useSendTrainingTrainerEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTrainingTrainerEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTrainingTrainerEmailMutation, SendTrainingTrainerEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendTrainingTrainerEmailMutation, SendTrainingTrainerEmailMutationVariables>(SendTrainingTrainerEmailDocument, baseOptions);
      }
export type SendTrainingTrainerEmailMutationHookResult = ReturnType<typeof useSendTrainingTrainerEmailMutation>;
export type SendTrainingTrainerEmailMutationResult = ApolloReactCommon.MutationResult<SendTrainingTrainerEmailMutation>;
export type SendTrainingTrainerEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTrainingTrainerEmailMutation, SendTrainingTrainerEmailMutationVariables>;
export const SendTrainingsToTraineesDocument = gql`
    mutation SendTrainingsToTrainees($input: SendTrainingsToTraineesInput!) {
  sendTrainingsToTrainees(input: $input) {
    success
  }
}
    `;
export type SendTrainingsToTraineesMutationFn = ApolloReactCommon.MutationFunction<SendTrainingsToTraineesMutation, SendTrainingsToTraineesMutationVariables>;

/**
 * __useSendTrainingsToTraineesMutation__
 *
 * To run a mutation, you first call `useSendTrainingsToTraineesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTrainingsToTraineesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTrainingsToTraineesMutation, { data, loading, error }] = useSendTrainingsToTraineesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTrainingsToTraineesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTrainingsToTraineesMutation, SendTrainingsToTraineesMutationVariables>) {
        return ApolloReactHooks.useMutation<SendTrainingsToTraineesMutation, SendTrainingsToTraineesMutationVariables>(SendTrainingsToTraineesDocument, baseOptions);
      }
export type SendTrainingsToTraineesMutationHookResult = ReturnType<typeof useSendTrainingsToTraineesMutation>;
export type SendTrainingsToTraineesMutationResult = ApolloReactCommon.MutationResult<SendTrainingsToTraineesMutation>;
export type SendTrainingsToTraineesMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTrainingsToTraineesMutation, SendTrainingsToTraineesMutationVariables>;
export const SendTrainingsToTrainersDocument = gql`
    mutation SendTrainingsToTrainers($input: SendTrainingsToTrainersInput!) {
  sendTrainingsToTrainers(input: $input) {
    success
  }
}
    `;
export type SendTrainingsToTrainersMutationFn = ApolloReactCommon.MutationFunction<SendTrainingsToTrainersMutation, SendTrainingsToTrainersMutationVariables>;

/**
 * __useSendTrainingsToTrainersMutation__
 *
 * To run a mutation, you first call `useSendTrainingsToTrainersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTrainingsToTrainersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTrainingsToTrainersMutation, { data, loading, error }] = useSendTrainingsToTrainersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTrainingsToTrainersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTrainingsToTrainersMutation, SendTrainingsToTrainersMutationVariables>) {
        return ApolloReactHooks.useMutation<SendTrainingsToTrainersMutation, SendTrainingsToTrainersMutationVariables>(SendTrainingsToTrainersDocument, baseOptions);
      }
export type SendTrainingsToTrainersMutationHookResult = ReturnType<typeof useSendTrainingsToTrainersMutation>;
export type SendTrainingsToTrainersMutationResult = ApolloReactCommon.MutationResult<SendTrainingsToTrainersMutation>;
export type SendTrainingsToTrainersMutationOptions = ApolloReactCommon.BaseMutationOptions<SendTrainingsToTrainersMutation, SendTrainingsToTrainersMutationVariables>;
export const UpdateAdminDocument = gql`
    mutation UpdateAdmin($input: UpdateAdminInput!) {
  updateAdmin(input: $input) {
    user {
      id
    }
  }
}
    `;
export type UpdateAdminMutationFn = ApolloReactCommon.MutationFunction<UpdateAdminMutation, UpdateAdminMutationVariables>;

/**
 * __useUpdateAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminMutation, { data, loading, error }] = useUpdateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAdminMutation, UpdateAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, baseOptions);
      }
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = ApolloReactCommon.MutationResult<UpdateAdminMutation>;
export type UpdateAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAdminMutation, UpdateAdminMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($input: UpdateEmailInput!) {
  updateEmail(input: $input) {
    user {
      email
      id
    }
  }
}
    `;
export type UpdateEmailMutationFn = ApolloReactCommon.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, baseOptions);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = ApolloReactCommon.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdateLeaguePricesDocument = gql`
    mutation UpdateLeaguePrices($input: UpdateLeaguePricesInput!) {
  updateLeaguePrices(input: $input) {
    leaguePrices {
      id
      year
      pricePerKm
      pricePerLunch
      pricePerDinner
      pricePerHotel
    }
  }
}
    `;
export type UpdateLeaguePricesMutationFn = ApolloReactCommon.MutationFunction<UpdateLeaguePricesMutation, UpdateLeaguePricesMutationVariables>;

/**
 * __useUpdateLeaguePricesMutation__
 *
 * To run a mutation, you first call `useUpdateLeaguePricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeaguePricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeaguePricesMutation, { data, loading, error }] = useUpdateLeaguePricesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLeaguePricesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLeaguePricesMutation, UpdateLeaguePricesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLeaguePricesMutation, UpdateLeaguePricesMutationVariables>(UpdateLeaguePricesDocument, baseOptions);
      }
export type UpdateLeaguePricesMutationHookResult = ReturnType<typeof useUpdateLeaguePricesMutation>;
export type UpdateLeaguePricesMutationResult = ApolloReactCommon.MutationResult<UpdateLeaguePricesMutation>;
export type UpdateLeaguePricesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLeaguePricesMutation, UpdateLeaguePricesMutationVariables>;
export const UpdateMatchDocument = gql`
    mutation UpdateMatch($input: UpdateMatchInput!) {
  updateMatch(input: $input) {
    match {
      address
      city
      endDate
      eveHotel
      id
      level
      startDate
      title
      zipCode
      lat
      lng
      informations
      matchDates {
        id
        requiredCount
        startHour
        lunch
        hotel
        endHour
        dinner
        date
      }
      competitions {
        id
        name
      }
    }
  }
}
    `;
export type UpdateMatchMutationFn = ApolloReactCommon.MutationFunction<UpdateMatchMutation, UpdateMatchMutationVariables>;

/**
 * __useUpdateMatchMutation__
 *
 * To run a mutation, you first call `useUpdateMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchMutation, { data, loading, error }] = useUpdateMatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMatchMutation, UpdateMatchMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMatchMutation, UpdateMatchMutationVariables>(UpdateMatchDocument, baseOptions);
      }
export type UpdateMatchMutationHookResult = ReturnType<typeof useUpdateMatchMutation>;
export type UpdateMatchMutationResult = ApolloReactCommon.MutationResult<UpdateMatchMutation>;
export type UpdateMatchMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMatchMutation, UpdateMatchMutationVariables>;
export const UpdateMatchDateResponsibleDocument = gql`
    mutation UpdateMatchDateResponsible($input: UpdateMatchDateResponsibleInput!) {
  updateMatchDateResponsible(input: $input) {
    matchDate {
      id
      responsible {
        id
      }
    }
  }
}
    `;
export type UpdateMatchDateResponsibleMutationFn = ApolloReactCommon.MutationFunction<UpdateMatchDateResponsibleMutation, UpdateMatchDateResponsibleMutationVariables>;

/**
 * __useUpdateMatchDateResponsibleMutation__
 *
 * To run a mutation, you first call `useUpdateMatchDateResponsibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchDateResponsibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchDateResponsibleMutation, { data, loading, error }] = useUpdateMatchDateResponsibleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMatchDateResponsibleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMatchDateResponsibleMutation, UpdateMatchDateResponsibleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMatchDateResponsibleMutation, UpdateMatchDateResponsibleMutationVariables>(UpdateMatchDateResponsibleDocument, baseOptions);
      }
export type UpdateMatchDateResponsibleMutationHookResult = ReturnType<typeof useUpdateMatchDateResponsibleMutation>;
export type UpdateMatchDateResponsibleMutationResult = ApolloReactCommon.MutationResult<UpdateMatchDateResponsibleMutation>;
export type UpdateMatchDateResponsibleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMatchDateResponsibleMutation, UpdateMatchDateResponsibleMutationVariables>;
export const UpdateMatchLocationDocument = gql`
    mutation UpdateMatchLocation($input: UpdateMatchLocationInput!) {
  updateMatchLocation(input: $input) {
    match {
      address
      city
      id
      zipCode
      lat
      lng
    }
  }
}
    `;
export type UpdateMatchLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateMatchLocationMutation, UpdateMatchLocationMutationVariables>;

/**
 * __useUpdateMatchLocationMutation__
 *
 * To run a mutation, you first call `useUpdateMatchLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchLocationMutation, { data, loading, error }] = useUpdateMatchLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMatchLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMatchLocationMutation, UpdateMatchLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMatchLocationMutation, UpdateMatchLocationMutationVariables>(UpdateMatchLocationDocument, baseOptions);
      }
export type UpdateMatchLocationMutationHookResult = ReturnType<typeof useUpdateMatchLocationMutation>;
export type UpdateMatchLocationMutationResult = ApolloReactCommon.MutationResult<UpdateMatchLocationMutation>;
export type UpdateMatchLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMatchLocationMutation, UpdateMatchLocationMutationVariables>;
export const UpdateMatchRefereeDocument = gql`
    mutation UpdateMatchReferee($input: UpdateMatchRefereeInput!) {
  updateMatchReferee(input: $input) {
    matchReferee {
      eveHotel
      noKmsFees
      freeHosting
      id
      informations
      referee {
        id
        firstName
        lastName
      }
      matchRefereeRoute {
        id
        to
        from
      }
      matchDateReferees {
        id
        matchDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
    }
  }
}
    `;
export type UpdateMatchRefereeMutationFn = ApolloReactCommon.MutationFunction<UpdateMatchRefereeMutation, UpdateMatchRefereeMutationVariables>;

/**
 * __useUpdateMatchRefereeMutation__
 *
 * To run a mutation, you first call `useUpdateMatchRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchRefereeMutation, { data, loading, error }] = useUpdateMatchRefereeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMatchRefereeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMatchRefereeMutation, UpdateMatchRefereeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMatchRefereeMutation, UpdateMatchRefereeMutationVariables>(UpdateMatchRefereeDocument, baseOptions);
      }
export type UpdateMatchRefereeMutationHookResult = ReturnType<typeof useUpdateMatchRefereeMutation>;
export type UpdateMatchRefereeMutationResult = ApolloReactCommon.MutationResult<UpdateMatchRefereeMutation>;
export type UpdateMatchRefereeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMatchRefereeMutation, UpdateMatchRefereeMutationVariables>;
export const UpdateMatchRefereeDisciplineDocument = gql`
    mutation UpdateMatchRefereeDiscipline($input: UpdateMatchRefereeDisciplineInput!) {
  updateMatchRefereeDiscipline(input: $input) {
    matchReferee {
      id
      discipline {
        id
        discipline {
          id
          name
        }
        level
        obtentionDate
        validityDate
      }
    }
  }
}
    `;
export type UpdateMatchRefereeDisciplineMutationFn = ApolloReactCommon.MutationFunction<UpdateMatchRefereeDisciplineMutation, UpdateMatchRefereeDisciplineMutationVariables>;

/**
 * __useUpdateMatchRefereeDisciplineMutation__
 *
 * To run a mutation, you first call `useUpdateMatchRefereeDisciplineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchRefereeDisciplineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchRefereeDisciplineMutation, { data, loading, error }] = useUpdateMatchRefereeDisciplineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMatchRefereeDisciplineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMatchRefereeDisciplineMutation, UpdateMatchRefereeDisciplineMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMatchRefereeDisciplineMutation, UpdateMatchRefereeDisciplineMutationVariables>(UpdateMatchRefereeDisciplineDocument, baseOptions);
      }
export type UpdateMatchRefereeDisciplineMutationHookResult = ReturnType<typeof useUpdateMatchRefereeDisciplineMutation>;
export type UpdateMatchRefereeDisciplineMutationResult = ApolloReactCommon.MutationResult<UpdateMatchRefereeDisciplineMutation>;
export type UpdateMatchRefereeDisciplineMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMatchRefereeDisciplineMutation, UpdateMatchRefereeDisciplineMutationVariables>;
export const UpdateMatchRefereeRouteDocument = gql`
    mutation UpdateMatchRefereeRoute($input: UpdateMatchRefereeRouteInput!) {
  updateMatchRefereeRoute(input: $input) {
    matchRefereeRoute {
      id
      to
      from
    }
  }
}
    `;
export type UpdateMatchRefereeRouteMutationFn = ApolloReactCommon.MutationFunction<UpdateMatchRefereeRouteMutation, UpdateMatchRefereeRouteMutationVariables>;

/**
 * __useUpdateMatchRefereeRouteMutation__
 *
 * To run a mutation, you first call `useUpdateMatchRefereeRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchRefereeRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchRefereeRouteMutation, { data, loading, error }] = useUpdateMatchRefereeRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMatchRefereeRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMatchRefereeRouteMutation, UpdateMatchRefereeRouteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMatchRefereeRouteMutation, UpdateMatchRefereeRouteMutationVariables>(UpdateMatchRefereeRouteDocument, baseOptions);
      }
export type UpdateMatchRefereeRouteMutationHookResult = ReturnType<typeof useUpdateMatchRefereeRouteMutation>;
export type UpdateMatchRefereeRouteMutationResult = ApolloReactCommon.MutationResult<UpdateMatchRefereeRouteMutation>;
export type UpdateMatchRefereeRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMatchRefereeRouteMutation, UpdateMatchRefereeRouteMutationVariables>;
export const UpdateMatchVolunteerDocument = gql`
    mutation UpdateMatchVolunteer($input: UpdateMatchVolunteerInput!) {
  updateMatchVolunteer(input: $input) {
    matchVolunteer {
      eveHotel
      id
      informations
      volunteer {
        id
        firstName
        lastName
      }
      matchDateVolunteers {
        id
        matchDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
    }
  }
}
    `;
export type UpdateMatchVolunteerMutationFn = ApolloReactCommon.MutationFunction<UpdateMatchVolunteerMutation, UpdateMatchVolunteerMutationVariables>;

/**
 * __useUpdateMatchVolunteerMutation__
 *
 * To run a mutation, you first call `useUpdateMatchVolunteerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchVolunteerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchVolunteerMutation, { data, loading, error }] = useUpdateMatchVolunteerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMatchVolunteerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMatchVolunteerMutation, UpdateMatchVolunteerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMatchVolunteerMutation, UpdateMatchVolunteerMutationVariables>(UpdateMatchVolunteerDocument, baseOptions);
      }
export type UpdateMatchVolunteerMutationHookResult = ReturnType<typeof useUpdateMatchVolunteerMutation>;
export type UpdateMatchVolunteerMutationResult = ApolloReactCommon.MutationResult<UpdateMatchVolunteerMutation>;
export type UpdateMatchVolunteerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMatchVolunteerMutation, UpdateMatchVolunteerMutationVariables>;
export const UpdateMeetingDocument = gql`
    mutation UpdateMeeting($input: UpdateMeetingInput!) {
  updateMeeting(input: $input) {
    meeting {
      address
      city
      endDate
      eveHotel
      id
      level
      startDate
      title
      zipCode
      lat
      lng
      informations
      meetingDates {
        id
        requiredCount
        startHour
        lunch
        hotel
        endHour
        dinner
        date
      }
    }
  }
}
    `;
export type UpdateMeetingMutationFn = ApolloReactCommon.MutationFunction<UpdateMeetingMutation, UpdateMeetingMutationVariables>;

/**
 * __useUpdateMeetingMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingMutation, { data, loading, error }] = useUpdateMeetingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeetingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMeetingMutation, UpdateMeetingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMeetingMutation, UpdateMeetingMutationVariables>(UpdateMeetingDocument, baseOptions);
      }
export type UpdateMeetingMutationHookResult = ReturnType<typeof useUpdateMeetingMutation>;
export type UpdateMeetingMutationResult = ApolloReactCommon.MutationResult<UpdateMeetingMutation>;
export type UpdateMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMeetingMutation, UpdateMeetingMutationVariables>;
export const UpdateMeetingLocationDocument = gql`
    mutation UpdateMeetingLocation($input: UpdateMeetingLocationInput!) {
  updateMeetingLocation(input: $input) {
    meeting {
      address
      city
      id
      zipCode
      lat
      lng
    }
  }
}
    `;
export type UpdateMeetingLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateMeetingLocationMutation, UpdateMeetingLocationMutationVariables>;

/**
 * __useUpdateMeetingLocationMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingLocationMutation, { data, loading, error }] = useUpdateMeetingLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeetingLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMeetingLocationMutation, UpdateMeetingLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMeetingLocationMutation, UpdateMeetingLocationMutationVariables>(UpdateMeetingLocationDocument, baseOptions);
      }
export type UpdateMeetingLocationMutationHookResult = ReturnType<typeof useUpdateMeetingLocationMutation>;
export type UpdateMeetingLocationMutationResult = ApolloReactCommon.MutationResult<UpdateMeetingLocationMutation>;
export type UpdateMeetingLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMeetingLocationMutation, UpdateMeetingLocationMutationVariables>;
export const UpdateMeetingMemberDocument = gql`
    mutation UpdateMeetingMember($input: UpdateMeetingMemberInput!) {
  updateMeetingMember(input: $input) {
    meetingMember {
      eveHotel
      noKmsFees
      freeHosting
      id
      informations
      member {
        id
        firstName
        lastName
      }
      meetingMemberRoute {
        id
        to
        from
      }
      meetingDateMembers {
        id
        meetingDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
    }
  }
}
    `;
export type UpdateMeetingMemberMutationFn = ApolloReactCommon.MutationFunction<UpdateMeetingMemberMutation, UpdateMeetingMemberMutationVariables>;

/**
 * __useUpdateMeetingMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingMemberMutation, { data, loading, error }] = useUpdateMeetingMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeetingMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMeetingMemberMutation, UpdateMeetingMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMeetingMemberMutation, UpdateMeetingMemberMutationVariables>(UpdateMeetingMemberDocument, baseOptions);
      }
export type UpdateMeetingMemberMutationHookResult = ReturnType<typeof useUpdateMeetingMemberMutation>;
export type UpdateMeetingMemberMutationResult = ApolloReactCommon.MutationResult<UpdateMeetingMemberMutation>;
export type UpdateMeetingMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMeetingMemberMutation, UpdateMeetingMemberMutationVariables>;
export const UpdateMeetingMemberRouteDocument = gql`
    mutation UpdateMeetingMemberRoute($input: UpdateMeetingMemberRouteInput!) {
  updateMeetingMemberRoute(input: $input) {
    meetingMemberRoute {
      id
      to
      from
    }
  }
}
    `;
export type UpdateMeetingMemberRouteMutationFn = ApolloReactCommon.MutationFunction<UpdateMeetingMemberRouteMutation, UpdateMeetingMemberRouteMutationVariables>;

/**
 * __useUpdateMeetingMemberRouteMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingMemberRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingMemberRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingMemberRouteMutation, { data, loading, error }] = useUpdateMeetingMemberRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeetingMemberRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMeetingMemberRouteMutation, UpdateMeetingMemberRouteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMeetingMemberRouteMutation, UpdateMeetingMemberRouteMutationVariables>(UpdateMeetingMemberRouteDocument, baseOptions);
      }
export type UpdateMeetingMemberRouteMutationHookResult = ReturnType<typeof useUpdateMeetingMemberRouteMutation>;
export type UpdateMeetingMemberRouteMutationResult = ApolloReactCommon.MutationResult<UpdateMeetingMemberRouteMutation>;
export type UpdateMeetingMemberRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMeetingMemberRouteMutation, UpdateMeetingMemberRouteMutationVariables>;
export const UpdateTraineeDocument = gql`
    mutation UpdateTrainee($input: UpdateTraineeInput!) {
  updateTrainee(input: $input) {
    trainee {
      id
      lastName
      firstName
      email
      phone
      category {
        id
        name
      }
      enabled
    }
  }
}
    `;
export type UpdateTraineeMutationFn = ApolloReactCommon.MutationFunction<UpdateTraineeMutation, UpdateTraineeMutationVariables>;

/**
 * __useUpdateTraineeMutation__
 *
 * To run a mutation, you first call `useUpdateTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTraineeMutation, { data, loading, error }] = useUpdateTraineeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTraineeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTraineeMutation, UpdateTraineeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTraineeMutation, UpdateTraineeMutationVariables>(UpdateTraineeDocument, baseOptions);
      }
export type UpdateTraineeMutationHookResult = ReturnType<typeof useUpdateTraineeMutation>;
export type UpdateTraineeMutationResult = ApolloReactCommon.MutationResult<UpdateTraineeMutation>;
export type UpdateTraineeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTraineeMutation, UpdateTraineeMutationVariables>;
export const UpdateTrainingDocument = gql`
    mutation UpdateTraining($input: UpdateTrainingInput!) {
  updateTraining(input: $input) {
    training {
      address
      city
      endDate
      eveHotel
      id
      level
      startDate
      title
      zipCode
      lat
      lng
      informations
      trainingDates {
        id
        requiredCount
        startHour
        lunch
        hotel
        endHour
        dinner
        date
      }
    }
  }
}
    `;
export type UpdateTrainingMutationFn = ApolloReactCommon.MutationFunction<UpdateTrainingMutation, UpdateTrainingMutationVariables>;

/**
 * __useUpdateTrainingMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingMutation, { data, loading, error }] = useUpdateTrainingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrainingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainingMutation, UpdateTrainingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTrainingMutation, UpdateTrainingMutationVariables>(UpdateTrainingDocument, baseOptions);
      }
export type UpdateTrainingMutationHookResult = ReturnType<typeof useUpdateTrainingMutation>;
export type UpdateTrainingMutationResult = ApolloReactCommon.MutationResult<UpdateTrainingMutation>;
export type UpdateTrainingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTrainingMutation, UpdateTrainingMutationVariables>;
export const UpdateTrainingLocationDocument = gql`
    mutation UpdateTrainingLocation($input: UpdateTrainingLocationInput!) {
  updateTrainingLocation(input: $input) {
    training {
      address
      city
      id
      zipCode
      lat
      lng
    }
  }
}
    `;
export type UpdateTrainingLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateTrainingLocationMutation, UpdateTrainingLocationMutationVariables>;

/**
 * __useUpdateTrainingLocationMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingLocationMutation, { data, loading, error }] = useUpdateTrainingLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrainingLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainingLocationMutation, UpdateTrainingLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTrainingLocationMutation, UpdateTrainingLocationMutationVariables>(UpdateTrainingLocationDocument, baseOptions);
      }
export type UpdateTrainingLocationMutationHookResult = ReturnType<typeof useUpdateTrainingLocationMutation>;
export type UpdateTrainingLocationMutationResult = ApolloReactCommon.MutationResult<UpdateTrainingLocationMutation>;
export type UpdateTrainingLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTrainingLocationMutation, UpdateTrainingLocationMutationVariables>;
export const UpdateTrainingTraineeDocument = gql`
    mutation UpdateTrainingTrainee($input: UpdateTrainingTraineeInput!) {
  updateTrainingTrainee(input: $input) {
    trainingTrainee {
      eveHotel
      id
      informations
      trainee {
        id
        firstName
        lastName
      }
      trainingDateTrainees {
        id
        trainingDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
    }
  }
}
    `;
export type UpdateTrainingTraineeMutationFn = ApolloReactCommon.MutationFunction<UpdateTrainingTraineeMutation, UpdateTrainingTraineeMutationVariables>;

/**
 * __useUpdateTrainingTraineeMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingTraineeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingTraineeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingTraineeMutation, { data, loading, error }] = useUpdateTrainingTraineeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrainingTraineeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainingTraineeMutation, UpdateTrainingTraineeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTrainingTraineeMutation, UpdateTrainingTraineeMutationVariables>(UpdateTrainingTraineeDocument, baseOptions);
      }
export type UpdateTrainingTraineeMutationHookResult = ReturnType<typeof useUpdateTrainingTraineeMutation>;
export type UpdateTrainingTraineeMutationResult = ApolloReactCommon.MutationResult<UpdateTrainingTraineeMutation>;
export type UpdateTrainingTraineeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTrainingTraineeMutation, UpdateTrainingTraineeMutationVariables>;
export const UpdateTrainingTrainerDocument = gql`
    mutation UpdateTrainingTrainer($input: UpdateTrainingTrainerInput!) {
  updateTrainingTrainer(input: $input) {
    trainingTrainer {
      eveHotel
      noKmsFees
      freeHosting
      id
      informations
      trainer {
        id
        firstName
        lastName
      }
      trainingTrainerRoute {
        id
        to
        from
      }
      trainingDateTrainers {
        id
        trainingDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
    }
  }
}
    `;
export type UpdateTrainingTrainerMutationFn = ApolloReactCommon.MutationFunction<UpdateTrainingTrainerMutation, UpdateTrainingTrainerMutationVariables>;

/**
 * __useUpdateTrainingTrainerMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingTrainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingTrainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingTrainerMutation, { data, loading, error }] = useUpdateTrainingTrainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrainingTrainerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainingTrainerMutation, UpdateTrainingTrainerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTrainingTrainerMutation, UpdateTrainingTrainerMutationVariables>(UpdateTrainingTrainerDocument, baseOptions);
      }
export type UpdateTrainingTrainerMutationHookResult = ReturnType<typeof useUpdateTrainingTrainerMutation>;
export type UpdateTrainingTrainerMutationResult = ApolloReactCommon.MutationResult<UpdateTrainingTrainerMutation>;
export type UpdateTrainingTrainerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTrainingTrainerMutation, UpdateTrainingTrainerMutationVariables>;
export const UpdateTrainingTrainerRouteDocument = gql`
    mutation UpdateTrainingTrainerRoute($input: UpdateTrainingTrainerRouteInput!) {
  updateTrainingTrainerRoute(input: $input) {
    trainingTrainerRoute {
      id
      to
      from
    }
  }
}
    `;
export type UpdateTrainingTrainerRouteMutationFn = ApolloReactCommon.MutationFunction<UpdateTrainingTrainerRouteMutation, UpdateTrainingTrainerRouteMutationVariables>;

/**
 * __useUpdateTrainingTrainerRouteMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingTrainerRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingTrainerRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingTrainerRouteMutation, { data, loading, error }] = useUpdateTrainingTrainerRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrainingTrainerRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainingTrainerRouteMutation, UpdateTrainingTrainerRouteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTrainingTrainerRouteMutation, UpdateTrainingTrainerRouteMutationVariables>(UpdateTrainingTrainerRouteDocument, baseOptions);
      }
export type UpdateTrainingTrainerRouteMutationHookResult = ReturnType<typeof useUpdateTrainingTrainerRouteMutation>;
export type UpdateTrainingTrainerRouteMutationResult = ApolloReactCommon.MutationResult<UpdateTrainingTrainerRouteMutation>;
export type UpdateTrainingTrainerRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTrainingTrainerRouteMutation, UpdateTrainingTrainerRouteMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      email
      firstName
      id
      lastName
      permissions
    }
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateVolunteerDocument = gql`
    mutation UpdateVolunteer($input: UpdateVolunteerInput!) {
  updateVolunteer(input: $input) {
    volunteer {
      id
      lastName
      firstName
      email
      phone
      enabled
    }
  }
}
    `;
export type UpdateVolunteerMutationFn = ApolloReactCommon.MutationFunction<UpdateVolunteerMutation, UpdateVolunteerMutationVariables>;

/**
 * __useUpdateVolunteerMutation__
 *
 * To run a mutation, you first call `useUpdateVolunteerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVolunteerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVolunteerMutation, { data, loading, error }] = useUpdateVolunteerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVolunteerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVolunteerMutation, UpdateVolunteerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVolunteerMutation, UpdateVolunteerMutationVariables>(UpdateVolunteerDocument, baseOptions);
      }
export type UpdateVolunteerMutationHookResult = ReturnType<typeof useUpdateVolunteerMutation>;
export type UpdateVolunteerMutationResult = ApolloReactCommon.MutationResult<UpdateVolunteerMutation>;
export type UpdateVolunteerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVolunteerMutation, UpdateVolunteerMutationVariables>;
export const AdminsDocument = gql`
    query Admins {
  admins {
    email
    firstName
    id
    lastName
    department {
      name
      id
    }
  }
}
    `;

/**
 * __useAdminsQuery__
 *
 * To run a query within a React component, call `useAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, baseOptions);
      }
export function useAdminsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, baseOptions);
        }
export type AdminsQueryHookResult = ReturnType<typeof useAdminsQuery>;
export type AdminsLazyQueryHookResult = ReturnType<typeof useAdminsLazyQuery>;
export type AdminsQueryResult = ApolloReactCommon.QueryResult<AdminsQuery, AdminsQueryVariables>;
export function refetchAdminsQuery(variables?: AdminsQueryVariables) {
      return { query: AdminsDocument, variables: variables }
    }
export const CompetitionsDocument = gql`
    query Competitions {
  competitions {
    id
    name
  }
}
    `;

/**
 * __useCompetitionsQuery__
 *
 * To run a query within a React component, call `useCompetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompetitionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompetitionsQuery, CompetitionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompetitionsQuery, CompetitionsQueryVariables>(CompetitionsDocument, baseOptions);
      }
export function useCompetitionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompetitionsQuery, CompetitionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompetitionsQuery, CompetitionsQueryVariables>(CompetitionsDocument, baseOptions);
        }
export type CompetitionsQueryHookResult = ReturnType<typeof useCompetitionsQuery>;
export type CompetitionsLazyQueryHookResult = ReturnType<typeof useCompetitionsLazyQuery>;
export type CompetitionsQueryResult = ApolloReactCommon.QueryResult<CompetitionsQuery, CompetitionsQueryVariables>;
export function refetchCompetitionsQuery(variables?: CompetitionsQueryVariables) {
      return { query: CompetitionsDocument, variables: variables }
    }
export const CurrentUserDocument = gql`
    query CurrentUser {
  user {
    id
    league {
      id
      name
      logo
      departments {
        id
        name
        number
      }
    }
    department {
      id
      name
      logo
    }
    firstName
    lastName
    admin
    permissions
    email
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export function refetchCurrentUserQuery(variables?: CurrentUserQueryVariables) {
      return { query: CurrentUserDocument, variables: variables }
    }
export const DisciplinesDocument = gql`
    query Disciplines {
  disciplines {
    id
    name
  }
}
    `;

/**
 * __useDisciplinesQuery__
 *
 * To run a query within a React component, call `useDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisciplinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisciplinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DisciplinesQuery, DisciplinesQueryVariables>) {
        return ApolloReactHooks.useQuery<DisciplinesQuery, DisciplinesQueryVariables>(DisciplinesDocument, baseOptions);
      }
export function useDisciplinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisciplinesQuery, DisciplinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DisciplinesQuery, DisciplinesQueryVariables>(DisciplinesDocument, baseOptions);
        }
export type DisciplinesQueryHookResult = ReturnType<typeof useDisciplinesQuery>;
export type DisciplinesLazyQueryHookResult = ReturnType<typeof useDisciplinesLazyQuery>;
export type DisciplinesQueryResult = ApolloReactCommon.QueryResult<DisciplinesQuery, DisciplinesQueryVariables>;
export function refetchDisciplinesQuery(variables?: DisciplinesQueryVariables) {
      return { query: DisciplinesDocument, variables: variables }
    }
export const LeaguePricesDocument = gql`
    query LeaguePrices($year: Int!) {
  user {
    id
    league {
      id
      prices(year: $year) {
        id
        pricePerKm
        pricePerLunch
        pricePerDinner
        pricePerHotel
      }
    }
  }
}
    `;

/**
 * __useLeaguePricesQuery__
 *
 * To run a query within a React component, call `useLeaguePricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaguePricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaguePricesQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useLeaguePricesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaguePricesQuery, LeaguePricesQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaguePricesQuery, LeaguePricesQueryVariables>(LeaguePricesDocument, baseOptions);
      }
export function useLeaguePricesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaguePricesQuery, LeaguePricesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaguePricesQuery, LeaguePricesQueryVariables>(LeaguePricesDocument, baseOptions);
        }
export type LeaguePricesQueryHookResult = ReturnType<typeof useLeaguePricesQuery>;
export type LeaguePricesLazyQueryHookResult = ReturnType<typeof useLeaguePricesLazyQuery>;
export type LeaguePricesQueryResult = ApolloReactCommon.QueryResult<LeaguePricesQuery, LeaguePricesQueryVariables>;
export function refetchLeaguePricesQuery(variables?: LeaguePricesQueryVariables) {
      return { query: LeaguePricesDocument, variables: variables }
    }
export const MatchResponsesDocument = gql`
    query MatchResponses($matchId: ID!) {
  match(matchId: $matchId) {
    eveHotel
    id
    matchDates {
      id
      requiredCount
      startHour
      lunch
      hotel
      endHour
      dinner
      date
    }
    refereesRequest {
      id
      referee {
        id
        firstName
        lastName
        address
        zipCode
        city
        dateOfBirth
        disciplines {
          id
          discipline {
            id
            name
          }
          level
          obtentionDate
          validityDate
        }
      }
      createdAt
      lastResponse {
        id
        informations
        eveHotel
        noKmsFees
        freeHosting
        createdAt
        accepted
        matchRefereeRoute {
          id
          from
          to
        }
        matchDateResponses {
          id
          informations
          lunch
          hotel
          dinner
          matchDate {
            id
            date
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMatchResponsesQuery__
 *
 * To run a query within a React component, call `useMatchResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchResponsesQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useMatchResponsesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MatchResponsesQuery, MatchResponsesQueryVariables>) {
        return ApolloReactHooks.useQuery<MatchResponsesQuery, MatchResponsesQueryVariables>(MatchResponsesDocument, baseOptions);
      }
export function useMatchResponsesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MatchResponsesQuery, MatchResponsesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MatchResponsesQuery, MatchResponsesQueryVariables>(MatchResponsesDocument, baseOptions);
        }
export type MatchResponsesQueryHookResult = ReturnType<typeof useMatchResponsesQuery>;
export type MatchResponsesLazyQueryHookResult = ReturnType<typeof useMatchResponsesLazyQuery>;
export type MatchResponsesQueryResult = ApolloReactCommon.QueryResult<MatchResponsesQuery, MatchResponsesQueryVariables>;
export function refetchMatchResponsesQuery(variables?: MatchResponsesQueryVariables) {
      return { query: MatchResponsesDocument, variables: variables }
    }
export const MatchResumeDocument = gql`
    query MatchResume($matchId: ID!) {
  match(matchId: $matchId) {
    address
    city
    endDate
    eveHotel
    id
    level
    startDate
    title
    zipCode
    lat
    lng
    informations
    department {
      id
    }
    competitions {
      id
      name
    }
    prices {
      id
      pricePerKm
      pricePerLunch
      pricePerDinner
      pricePerHotel
    }
    matchDates {
      id
      requiredCount
      startHour
      lunch
      hotel
      endHour
      dinner
      date
      responsible {
        id
      }
    }
    matchReferees {
      eveHotel
      noKmsFees
      freeHosting
      id
      informations
      referee {
        id
        firstName
        lastName
        address
        zipCode
        city
        dateOfBirth
        disciplines {
          id
          discipline {
            id
            name
          }
          level
          obtentionDate
          validityDate
        }
      }
      matchRefereeRoute {
        id
        to
        from
      }
      matchDateReferees {
        id
        matchDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
      discipline {
        id
        discipline {
          id
          name
        }
        level
        obtentionDate
        validityDate
      }
    }
    matchVolunteers {
      eveHotel
      id
      informations
      volunteer {
        id
        firstName
        lastName
      }
      matchDateVolunteers {
        id
        matchDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
    }
  }
}
    `;

/**
 * __useMatchResumeQuery__
 *
 * To run a query within a React component, call `useMatchResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchResumeQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useMatchResumeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MatchResumeQuery, MatchResumeQueryVariables>) {
        return ApolloReactHooks.useQuery<MatchResumeQuery, MatchResumeQueryVariables>(MatchResumeDocument, baseOptions);
      }
export function useMatchResumeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MatchResumeQuery, MatchResumeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MatchResumeQuery, MatchResumeQueryVariables>(MatchResumeDocument, baseOptions);
        }
export type MatchResumeQueryHookResult = ReturnType<typeof useMatchResumeQuery>;
export type MatchResumeLazyQueryHookResult = ReturnType<typeof useMatchResumeLazyQuery>;
export type MatchResumeQueryResult = ApolloReactCommon.QueryResult<MatchResumeQuery, MatchResumeQueryVariables>;
export function refetchMatchResumeQuery(variables?: MatchResumeQueryVariables) {
      return { query: MatchResumeDocument, variables: variables }
    }
export const MatchVolunteerResponsesDocument = gql`
    query MatchVolunteerResponses($matchId: ID!) {
  match(matchId: $matchId) {
    eveHotel
    id
    matchDates {
      id
      requiredCount
      startHour
      lunch
      hotel
      endHour
      dinner
      date
    }
    volunteersRequest {
      id
      lastResponse {
        id
        createdAt
        informations
        eveHotel
        accepted
        matchDateResponses {
          dinner
          hotel
          id
          informations
          lunch
          matchDate {
            id
            date
          }
        }
      }
      volunteer {
        id
        lastName
        firstName
      }
      createdAt
    }
  }
}
    `;

/**
 * __useMatchVolunteerResponsesQuery__
 *
 * To run a query within a React component, call `useMatchVolunteerResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchVolunteerResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchVolunteerResponsesQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useMatchVolunteerResponsesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MatchVolunteerResponsesQuery, MatchVolunteerResponsesQueryVariables>) {
        return ApolloReactHooks.useQuery<MatchVolunteerResponsesQuery, MatchVolunteerResponsesQueryVariables>(MatchVolunteerResponsesDocument, baseOptions);
      }
export function useMatchVolunteerResponsesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MatchVolunteerResponsesQuery, MatchVolunteerResponsesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MatchVolunteerResponsesQuery, MatchVolunteerResponsesQueryVariables>(MatchVolunteerResponsesDocument, baseOptions);
        }
export type MatchVolunteerResponsesQueryHookResult = ReturnType<typeof useMatchVolunteerResponsesQuery>;
export type MatchVolunteerResponsesLazyQueryHookResult = ReturnType<typeof useMatchVolunteerResponsesLazyQuery>;
export type MatchVolunteerResponsesQueryResult = ApolloReactCommon.QueryResult<MatchVolunteerResponsesQuery, MatchVolunteerResponsesQueryVariables>;
export function refetchMatchVolunteerResponsesQuery(variables?: MatchVolunteerResponsesQueryVariables) {
      return { query: MatchVolunteerResponsesDocument, variables: variables }
    }
export const MatchesDocument = gql`
    query Matches($year: Int!, $departmentId: ID) {
  matches(year: $year, departmentId: $departmentId) {
    address
    city
    createdAt
    endDate
    id
    level
    startDate
    title
    zipCode
  }
}
    `;

/**
 * __useMatchesQuery__
 *
 * To run a query within a React component, call `useMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchesQuery({
 *   variables: {
 *      year: // value for 'year'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useMatchesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MatchesQuery, MatchesQueryVariables>) {
        return ApolloReactHooks.useQuery<MatchesQuery, MatchesQueryVariables>(MatchesDocument, baseOptions);
      }
export function useMatchesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MatchesQuery, MatchesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MatchesQuery, MatchesQueryVariables>(MatchesDocument, baseOptions);
        }
export type MatchesQueryHookResult = ReturnType<typeof useMatchesQuery>;
export type MatchesLazyQueryHookResult = ReturnType<typeof useMatchesLazyQuery>;
export type MatchesQueryResult = ApolloReactCommon.QueryResult<MatchesQuery, MatchesQueryVariables>;
export function refetchMatchesQuery(variables?: MatchesQueryVariables) {
      return { query: MatchesDocument, variables: variables }
    }
export const MatchesForInvitationDocument = gql`
    query MatchesForInvitation($matchIds: [ID!]) {
  matchesByIds(matchIds: $matchIds) {
    address
    city
    endDate
    eveHotel
    id
    informations
    matchDates {
      date
      dinner
      endHour
      hotel
      id
      lunch
      startHour
    }
    startDate
    title
    zipCode
  }
}
    `;

/**
 * __useMatchesForInvitationQuery__
 *
 * To run a query within a React component, call `useMatchesForInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchesForInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchesForInvitationQuery({
 *   variables: {
 *      matchIds: // value for 'matchIds'
 *   },
 * });
 */
export function useMatchesForInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MatchesForInvitationQuery, MatchesForInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<MatchesForInvitationQuery, MatchesForInvitationQueryVariables>(MatchesForInvitationDocument, baseOptions);
      }
export function useMatchesForInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MatchesForInvitationQuery, MatchesForInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MatchesForInvitationQuery, MatchesForInvitationQueryVariables>(MatchesForInvitationDocument, baseOptions);
        }
export type MatchesForInvitationQueryHookResult = ReturnType<typeof useMatchesForInvitationQuery>;
export type MatchesForInvitationLazyQueryHookResult = ReturnType<typeof useMatchesForInvitationLazyQuery>;
export type MatchesForInvitationQueryResult = ApolloReactCommon.QueryResult<MatchesForInvitationQuery, MatchesForInvitationQueryVariables>;
export function refetchMatchesForInvitationQuery(variables?: MatchesForInvitationQueryVariables) {
      return { query: MatchesForInvitationDocument, variables: variables }
    }
export const MeetingResponsesDocument = gql`
    query MeetingResponses($meetingId: ID!) {
  meeting(meetingId: $meetingId) {
    eveHotel
    id
    meetingDates {
      id
      requiredCount
      startHour
      lunch
      hotel
      endHour
      dinner
      date
    }
    membersRequest {
      id
      member {
        id
        firstName
        lastName
        address
        zipCode
        city
        dateOfBirth
        infos {
          id
          accessRight
          title
          groupe
        }
      }
      createdAt
      lastResponse {
        id
        informations
        eveHotel
        noKmsFees
        freeHosting
        createdAt
        accepted
        meetingMemberRoute {
          id
          from
          to
        }
        meetingDateResponses {
          id
          informations
          lunch
          hotel
          dinner
          meetingDate {
            id
            date
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMeetingResponsesQuery__
 *
 * To run a query within a React component, call `useMeetingResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingResponsesQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useMeetingResponsesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeetingResponsesQuery, MeetingResponsesQueryVariables>) {
        return ApolloReactHooks.useQuery<MeetingResponsesQuery, MeetingResponsesQueryVariables>(MeetingResponsesDocument, baseOptions);
      }
export function useMeetingResponsesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeetingResponsesQuery, MeetingResponsesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeetingResponsesQuery, MeetingResponsesQueryVariables>(MeetingResponsesDocument, baseOptions);
        }
export type MeetingResponsesQueryHookResult = ReturnType<typeof useMeetingResponsesQuery>;
export type MeetingResponsesLazyQueryHookResult = ReturnType<typeof useMeetingResponsesLazyQuery>;
export type MeetingResponsesQueryResult = ApolloReactCommon.QueryResult<MeetingResponsesQuery, MeetingResponsesQueryVariables>;
export function refetchMeetingResponsesQuery(variables?: MeetingResponsesQueryVariables) {
      return { query: MeetingResponsesDocument, variables: variables }
    }
export const MeetingResumeDocument = gql`
    query MeetingResume($meetingId: ID!) {
  meeting(meetingId: $meetingId) {
    address
    city
    endDate
    eveHotel
    id
    level
    startDate
    title
    zipCode
    lat
    lng
    informations
    department {
      id
    }
    meetingDates {
      id
      requiredCount
      startHour
      lunch
      hotel
      endHour
      dinner
      date
    }
    prices {
      id
      pricePerKm
      pricePerLunch
      pricePerDinner
      pricePerHotel
    }
    meetingMembers {
      eveHotel
      noKmsFees
      freeHosting
      id
      informations
      member {
        id
        firstName
        lastName
        address
        zipCode
        city
        dateOfBirth
        infos {
          id
          accessRight
          title
          groupe
        }
      }
      meetingMemberRoute {
        id
        to
        from
      }
      meetingDateMembers {
        id
        meetingDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
    }
  }
}
    `;

/**
 * __useMeetingResumeQuery__
 *
 * To run a query within a React component, call `useMeetingResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingResumeQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useMeetingResumeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeetingResumeQuery, MeetingResumeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeetingResumeQuery, MeetingResumeQueryVariables>(MeetingResumeDocument, baseOptions);
      }
export function useMeetingResumeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeetingResumeQuery, MeetingResumeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeetingResumeQuery, MeetingResumeQueryVariables>(MeetingResumeDocument, baseOptions);
        }
export type MeetingResumeQueryHookResult = ReturnType<typeof useMeetingResumeQuery>;
export type MeetingResumeLazyQueryHookResult = ReturnType<typeof useMeetingResumeLazyQuery>;
export type MeetingResumeQueryResult = ApolloReactCommon.QueryResult<MeetingResumeQuery, MeetingResumeQueryVariables>;
export function refetchMeetingResumeQuery(variables?: MeetingResumeQueryVariables) {
      return { query: MeetingResumeDocument, variables: variables }
    }
export const MeetingsDocument = gql`
    query Meetings($year: Int!, $departmentId: ID) {
  meetings(year: $year, departmentId: $departmentId) {
    address
    city
    createdAt
    endDate
    id
    level
    startDate
    title
    zipCode
  }
}
    `;

/**
 * __useMeetingsQuery__
 *
 * To run a query within a React component, call `useMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useMeetingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeetingsQuery, MeetingsQueryVariables>) {
        return ApolloReactHooks.useQuery<MeetingsQuery, MeetingsQueryVariables>(MeetingsDocument, baseOptions);
      }
export function useMeetingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeetingsQuery, MeetingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeetingsQuery, MeetingsQueryVariables>(MeetingsDocument, baseOptions);
        }
export type MeetingsQueryHookResult = ReturnType<typeof useMeetingsQuery>;
export type MeetingsLazyQueryHookResult = ReturnType<typeof useMeetingsLazyQuery>;
export type MeetingsQueryResult = ApolloReactCommon.QueryResult<MeetingsQuery, MeetingsQueryVariables>;
export function refetchMeetingsQuery(variables?: MeetingsQueryVariables) {
      return { query: MeetingsDocument, variables: variables }
    }
export const MeetingsForInvitationDocument = gql`
    query MeetingsForInvitation($meetingIds: [ID!]) {
  meetingsByIds(meetingIds: $meetingIds) {
    address
    city
    endDate
    eveHotel
    id
    informations
    meetingDates {
      date
      dinner
      endHour
      hotel
      id
      lunch
      startHour
    }
    startDate
    title
    zipCode
  }
}
    `;

/**
 * __useMeetingsForInvitationQuery__
 *
 * To run a query within a React component, call `useMeetingsForInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingsForInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingsForInvitationQuery({
 *   variables: {
 *      meetingIds: // value for 'meetingIds'
 *   },
 * });
 */
export function useMeetingsForInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeetingsForInvitationQuery, MeetingsForInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<MeetingsForInvitationQuery, MeetingsForInvitationQueryVariables>(MeetingsForInvitationDocument, baseOptions);
      }
export function useMeetingsForInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeetingsForInvitationQuery, MeetingsForInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeetingsForInvitationQuery, MeetingsForInvitationQueryVariables>(MeetingsForInvitationDocument, baseOptions);
        }
export type MeetingsForInvitationQueryHookResult = ReturnType<typeof useMeetingsForInvitationQuery>;
export type MeetingsForInvitationLazyQueryHookResult = ReturnType<typeof useMeetingsForInvitationLazyQuery>;
export type MeetingsForInvitationQueryResult = ApolloReactCommon.QueryResult<MeetingsForInvitationQuery, MeetingsForInvitationQueryVariables>;
export function refetchMeetingsForInvitationQuery(variables?: MeetingsForInvitationQueryVariables) {
      return { query: MeetingsForInvitationDocument, variables: variables }
    }
export const MemberDocument = gql`
    query Member($memberId: ID!) {
  member(memberId: $memberId) {
    id
    lastName
    firstName
    league {
      id
      name
    }
    department {
      id
      name
    }
    licence
    mobilePhone
    phone
    zipCode
    email
    dateOfBirth
    clubNumber
    clubName
    city
    address
    infos {
      id
      accessRight
      title
      groupe
    }
  }
}
    `;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useMemberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MemberQuery, MemberQueryVariables>) {
        return ApolloReactHooks.useQuery<MemberQuery, MemberQueryVariables>(MemberDocument, baseOptions);
      }
export function useMemberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MemberQuery, MemberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MemberQuery, MemberQueryVariables>(MemberDocument, baseOptions);
        }
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberQueryResult = ApolloReactCommon.QueryResult<MemberQuery, MemberQueryVariables>;
export function refetchMemberQuery(variables?: MemberQueryVariables) {
      return { query: MemberDocument, variables: variables }
    }
export const MemberMeetingsDocument = gql`
    query MemberMeetings($memberId: ID!, $startDate: String!, $endDate: String!, $departmentId: ID) {
  member(memberId: $memberId) {
    id
    meetings(startDate: $startDate, endDate: $endDate, departmentId: $departmentId) {
      id
      meeting {
        title
        endDate
        city
        address
        id
        zipCode
        startDate
        eveHotel
        meetingDates {
          id
          date
          dinner
          endHour
          hotel
          lunch
          startHour
        }
        prices {
          id
          pricePerKm
          pricePerLunch
          pricePerDinner
          pricePerHotel
        }
      }
      informations
      eveHotel
      noKmsFees
      freeHosting
      meetingDateMembers {
        id
        informations
        hotel
        dinner
        lunch
        role
        meetingDate {
          id
          startHour
          endHour
          date
        }
      }
      meetingMemberRoute {
        id
        from
        to
      }
    }
  }
}
    `;

/**
 * __useMemberMeetingsQuery__
 *
 * To run a query within a React component, call `useMemberMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberMeetingsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useMemberMeetingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MemberMeetingsQuery, MemberMeetingsQueryVariables>) {
        return ApolloReactHooks.useQuery<MemberMeetingsQuery, MemberMeetingsQueryVariables>(MemberMeetingsDocument, baseOptions);
      }
export function useMemberMeetingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MemberMeetingsQuery, MemberMeetingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MemberMeetingsQuery, MemberMeetingsQueryVariables>(MemberMeetingsDocument, baseOptions);
        }
export type MemberMeetingsQueryHookResult = ReturnType<typeof useMemberMeetingsQuery>;
export type MemberMeetingsLazyQueryHookResult = ReturnType<typeof useMemberMeetingsLazyQuery>;
export type MemberMeetingsQueryResult = ApolloReactCommon.QueryResult<MemberMeetingsQuery, MemberMeetingsQueryVariables>;
export function refetchMemberMeetingsQuery(variables?: MemberMeetingsQueryVariables) {
      return { query: MemberMeetingsDocument, variables: variables }
    }
export const MemberRequestDocument = gql`
    query MemberRequest($token: String!) {
  memberRequest(token: $token) {
    id
    responded
    member {
      id
      lastName
      firstName
      address
      zipCode
      city
    }
    message {
      message
      id
    }
    requestMeetings {
      id
      meeting {
        address
        city
        endDate
        eveHotel
        id
        level
        startDate
        title
        zipCode
        lat
        lng
        informations
        prices {
          id
          pricePerKm
        }
        meetingDates {
          date
          id
          lunch
          startHour
          hotel
          endHour
          dinner
        }
      }
      meetingMemberRoute {
        id
        from
        to
      }
      lastResponse {
        accepted
        createdAt
        eveHotel
        noKmsFees
        freeHosting
        id
        informations
        meetingDateResponses {
          meetingDate {
            id
          }
          dinner
          hotel
          id
          informations
          lunch
        }
      }
    }
  }
}
    `;

/**
 * __useMemberRequestQuery__
 *
 * To run a query within a React component, call `useMemberRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberRequestQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useMemberRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MemberRequestQuery, MemberRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<MemberRequestQuery, MemberRequestQueryVariables>(MemberRequestDocument, baseOptions);
      }
export function useMemberRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MemberRequestQuery, MemberRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MemberRequestQuery, MemberRequestQueryVariables>(MemberRequestDocument, baseOptions);
        }
export type MemberRequestQueryHookResult = ReturnType<typeof useMemberRequestQuery>;
export type MemberRequestLazyQueryHookResult = ReturnType<typeof useMemberRequestLazyQuery>;
export type MemberRequestQueryResult = ApolloReactCommon.QueryResult<MemberRequestQuery, MemberRequestQueryVariables>;
export function refetchMemberRequestQuery(variables?: MemberRequestQueryVariables) {
      return { query: MemberRequestDocument, variables: variables }
    }
export const MembersDocument = gql`
    query Members($groupe: String) {
  members(groupe: $groupe) {
    id
    lastName
    firstName
    enabled
    league {
      id
      name
    }
    department {
      id
      name
    }
    licence
    mobilePhone
    phone
    zipCode
    email
    dateOfBirth
    clubNumber
    clubName
    city
    address
    infos {
      id
      accessRight
      title
      groupe
    }
  }
}
    `;

/**
 * __useMembersQuery__
 *
 * To run a query within a React component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersQuery({
 *   variables: {
 *      groupe: // value for 'groupe'
 *   },
 * });
 */
export function useMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MembersQuery, MembersQueryVariables>) {
        return ApolloReactHooks.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, baseOptions);
      }
export function useMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, baseOptions);
        }
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersQueryResult = ApolloReactCommon.QueryResult<MembersQuery, MembersQueryVariables>;
export function refetchMembersQuery(variables?: MembersQueryVariables) {
      return { query: MembersDocument, variables: variables }
    }
export const MembersStatsDocument = gql`
    query MembersStats($startDate: String!, $endDate: String!, $departmentId: ID) {
  membersForStats(departmentId: $departmentId) {
    id
    lastName
    firstName
    meetings(startDate: $startDate, endDate: $endDate, departmentId: $departmentId) {
      id
      meeting {
        title
        endDate
        city
        address
        id
        zipCode
        startDate
        eveHotel
        meetingDates {
          id
          date
          dinner
          endHour
          hotel
          lunch
          startHour
        }
        prices {
          id
          pricePerKm
          pricePerLunch
          pricePerDinner
          pricePerHotel
        }
      }
      informations
      eveHotel
      noKmsFees
      freeHosting
      meetingDateMembers {
        id
        informations
        hotel
        dinner
        lunch
        role
        meetingDate {
          id
          startHour
          endHour
          date
        }
      }
      meetingMemberRoute {
        id
        from
        to
      }
    }
    totalInvitationCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
    invitationWithoutResponseCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
  }
}
    `;

/**
 * __useMembersStatsQuery__
 *
 * To run a query within a React component, call `useMembersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useMembersStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MembersStatsQuery, MembersStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<MembersStatsQuery, MembersStatsQueryVariables>(MembersStatsDocument, baseOptions);
      }
export function useMembersStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MembersStatsQuery, MembersStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MembersStatsQuery, MembersStatsQueryVariables>(MembersStatsDocument, baseOptions);
        }
export type MembersStatsQueryHookResult = ReturnType<typeof useMembersStatsQuery>;
export type MembersStatsLazyQueryHookResult = ReturnType<typeof useMembersStatsLazyQuery>;
export type MembersStatsQueryResult = ApolloReactCommon.QueryResult<MembersStatsQuery, MembersStatsQueryVariables>;
export function refetchMembersStatsQuery(variables?: MembersStatsQueryVariables) {
      return { query: MembersStatsDocument, variables: variables }
    }
export const RefereeDocument = gql`
    query Referee($refereeId: ID!) {
  referee(refereeId: $refereeId) {
    id
    lastName
    firstName
    league {
      id
      name
    }
    department {
      id
      name
    }
    licence
    mobilePhone
    phone
    zipCode
    email
    dateOfBirth
    clubNumber
    clubName
    city
    address
    disciplines {
      id
      discipline {
        id
        name
      }
      level
      obtentionDate
      validityDate
    }
  }
}
    `;

/**
 * __useRefereeQuery__
 *
 * To run a query within a React component, call `useRefereeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereeQuery({
 *   variables: {
 *      refereeId: // value for 'refereeId'
 *   },
 * });
 */
export function useRefereeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereeQuery, RefereeQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereeQuery, RefereeQueryVariables>(RefereeDocument, baseOptions);
      }
export function useRefereeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereeQuery, RefereeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereeQuery, RefereeQueryVariables>(RefereeDocument, baseOptions);
        }
export type RefereeQueryHookResult = ReturnType<typeof useRefereeQuery>;
export type RefereeLazyQueryHookResult = ReturnType<typeof useRefereeLazyQuery>;
export type RefereeQueryResult = ApolloReactCommon.QueryResult<RefereeQuery, RefereeQueryVariables>;
export function refetchRefereeQuery(variables?: RefereeQueryVariables) {
      return { query: RefereeDocument, variables: variables }
    }
export const RefereeMatchesDocument = gql`
    query RefereeMatches($refereeId: ID!, $startDate: String!, $endDate: String!, $departmentId: ID) {
  referee(refereeId: $refereeId) {
    id
    matches(startDate: $startDate, endDate: $endDate, departmentId: $departmentId) {
      id
      match {
        title
        endDate
        city
        address
        id
        zipCode
        startDate
        eveHotel
        matchDates {
          id
          date
          dinner
          endHour
          hotel
          lunch
          startHour
        }
        prices {
          id
          pricePerKm
          pricePerLunch
          pricePerDinner
          pricePerHotel
        }
      }
      informations
      eveHotel
      noKmsFees
      freeHosting
      matchDateReferees {
        id
        informations
        hotel
        dinner
        lunch
        role
        matchDate {
          id
          startHour
          endHour
          date
        }
      }
      matchRefereeRoute {
        id
        from
        to
      }
    }
  }
}
    `;

/**
 * __useRefereeMatchesQuery__
 *
 * To run a query within a React component, call `useRefereeMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereeMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereeMatchesQuery({
 *   variables: {
 *      refereeId: // value for 'refereeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useRefereeMatchesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereeMatchesQuery, RefereeMatchesQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereeMatchesQuery, RefereeMatchesQueryVariables>(RefereeMatchesDocument, baseOptions);
      }
export function useRefereeMatchesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereeMatchesQuery, RefereeMatchesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereeMatchesQuery, RefereeMatchesQueryVariables>(RefereeMatchesDocument, baseOptions);
        }
export type RefereeMatchesQueryHookResult = ReturnType<typeof useRefereeMatchesQuery>;
export type RefereeMatchesLazyQueryHookResult = ReturnType<typeof useRefereeMatchesLazyQuery>;
export type RefereeMatchesQueryResult = ApolloReactCommon.QueryResult<RefereeMatchesQuery, RefereeMatchesQueryVariables>;
export function refetchRefereeMatchesQuery(variables?: RefereeMatchesQueryVariables) {
      return { query: RefereeMatchesDocument, variables: variables }
    }
export const RefereeRequestDocument = gql`
    query RefereeRequest($token: String!) {
  refereeRequest(token: $token) {
    id
    responded
    referee {
      id
      lastName
      firstName
      address
      zipCode
      city
    }
    message {
      message
      id
    }
    requestMatches {
      id
      match {
        address
        city
        endDate
        eveHotel
        id
        level
        startDate
        title
        zipCode
        lat
        lng
        informations
        prices {
          id
          pricePerKm
        }
        matchDates {
          date
          id
          lunch
          startHour
          hotel
          endHour
          dinner
        }
      }
      matchRefereeRoute {
        id
        from
        to
      }
      lastResponse {
        accepted
        createdAt
        eveHotel
        noKmsFees
        freeHosting
        id
        informations
        matchDateResponses {
          matchDate {
            id
          }
          dinner
          hotel
          id
          informations
          lunch
        }
      }
    }
  }
}
    `;

/**
 * __useRefereeRequestQuery__
 *
 * To run a query within a React component, call `useRefereeRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereeRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereeRequestQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefereeRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereeRequestQuery, RefereeRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereeRequestQuery, RefereeRequestQueryVariables>(RefereeRequestDocument, baseOptions);
      }
export function useRefereeRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereeRequestQuery, RefereeRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereeRequestQuery, RefereeRequestQueryVariables>(RefereeRequestDocument, baseOptions);
        }
export type RefereeRequestQueryHookResult = ReturnType<typeof useRefereeRequestQuery>;
export type RefereeRequestLazyQueryHookResult = ReturnType<typeof useRefereeRequestLazyQuery>;
export type RefereeRequestQueryResult = ApolloReactCommon.QueryResult<RefereeRequestQuery, RefereeRequestQueryVariables>;
export function refetchRefereeRequestQuery(variables?: RefereeRequestQueryVariables) {
      return { query: RefereeRequestDocument, variables: variables }
    }
export const RefereesDocument = gql`
    query Referees {
  referees {
    id
    lastName
    firstName
    enabled
    league {
      id
      name
    }
    department {
      id
      name
    }
    licence
    mobilePhone
    phone
    zipCode
    email
    dateOfBirth
    clubNumber
    clubName
    city
    address
    disciplines {
      id
      discipline {
        id
        name
      }
      level
      obtentionDate
      validityDate
    }
  }
}
    `;

/**
 * __useRefereesQuery__
 *
 * To run a query within a React component, call `useRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefereesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereesQuery, RefereesQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereesQuery, RefereesQueryVariables>(RefereesDocument, baseOptions);
      }
export function useRefereesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereesQuery, RefereesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereesQuery, RefereesQueryVariables>(RefereesDocument, baseOptions);
        }
export type RefereesQueryHookResult = ReturnType<typeof useRefereesQuery>;
export type RefereesLazyQueryHookResult = ReturnType<typeof useRefereesLazyQuery>;
export type RefereesQueryResult = ApolloReactCommon.QueryResult<RefereesQuery, RefereesQueryVariables>;
export function refetchRefereesQuery(variables?: RefereesQueryVariables) {
      return { query: RefereesDocument, variables: variables }
    }
export const RefereesStatsDocument = gql`
    query RefereesStats($startDate: String!, $endDate: String!, $departmentId: ID) {
  refereesForStats(departmentId: $departmentId) {
    id
    lastName
    firstName
    matches(startDate: $startDate, endDate: $endDate, departmentId: $departmentId) {
      id
      match {
        title
        endDate
        city
        address
        id
        zipCode
        startDate
        eveHotel
        matchDates {
          id
          date
          dinner
          endHour
          hotel
          lunch
          startHour
        }
        prices {
          id
          pricePerKm
          pricePerLunch
          pricePerDinner
          pricePerHotel
        }
      }
      informations
      eveHotel
      noKmsFees
      freeHosting
      matchDateReferees {
        id
        informations
        hotel
        dinner
        lunch
        role
        matchDate {
          id
          startHour
          endHour
          date
        }
      }
      matchRefereeRoute {
        id
        from
        to
      }
    }
    totalInvitationCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
    invitationWithoutResponseCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
  }
}
    `;

/**
 * __useRefereesStatsQuery__
 *
 * To run a query within a React component, call `useRefereesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefereesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefereesStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useRefereesStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefereesStatsQuery, RefereesStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<RefereesStatsQuery, RefereesStatsQueryVariables>(RefereesStatsDocument, baseOptions);
      }
export function useRefereesStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefereesStatsQuery, RefereesStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefereesStatsQuery, RefereesStatsQueryVariables>(RefereesStatsDocument, baseOptions);
        }
export type RefereesStatsQueryHookResult = ReturnType<typeof useRefereesStatsQuery>;
export type RefereesStatsLazyQueryHookResult = ReturnType<typeof useRefereesStatsLazyQuery>;
export type RefereesStatsQueryResult = ApolloReactCommon.QueryResult<RefereesStatsQuery, RefereesStatsQueryVariables>;
export function refetchRefereesStatsQuery(variables?: RefereesStatsQueryVariables) {
      return { query: RefereesStatsDocument, variables: variables }
    }
export const SearchMembersDocument = gql`
    query SearchMembers($search: String!, $meetingId: ID!) {
  searchMembers(search: $search, meetingId: $meetingId) {
    id
    firstName
    lastName
    address
    zipCode
    city
    dateOfBirth
    department {
      id
      name
    }
    infos {
      id
      accessRight
      title
      groupe
    }
  }
}
    `;

/**
 * __useSearchMembersQuery__
 *
 * To run a query within a React component, call `useSearchMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMembersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useSearchMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchMembersQuery, SearchMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchMembersQuery, SearchMembersQueryVariables>(SearchMembersDocument, baseOptions);
      }
export function useSearchMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchMembersQuery, SearchMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchMembersQuery, SearchMembersQueryVariables>(SearchMembersDocument, baseOptions);
        }
export type SearchMembersQueryHookResult = ReturnType<typeof useSearchMembersQuery>;
export type SearchMembersLazyQueryHookResult = ReturnType<typeof useSearchMembersLazyQuery>;
export type SearchMembersQueryResult = ApolloReactCommon.QueryResult<SearchMembersQuery, SearchMembersQueryVariables>;
export function refetchSearchMembersQuery(variables?: SearchMembersQueryVariables) {
      return { query: SearchMembersDocument, variables: variables }
    }
export const SearchRefereesDocument = gql`
    query SearchReferees($search: String!, $matchId: ID!) {
  searchReferees(search: $search, matchId: $matchId) {
    id
    firstName
    lastName
    address
    zipCode
    city
    department {
      id
      name
    }
    dateOfBirth
    disciplines {
      id
      discipline {
        id
        name
      }
      level
      obtentionDate
      validityDate
    }
  }
}
    `;

/**
 * __useSearchRefereesQuery__
 *
 * To run a query within a React component, call `useSearchRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRefereesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useSearchRefereesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchRefereesQuery, SearchRefereesQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchRefereesQuery, SearchRefereesQueryVariables>(SearchRefereesDocument, baseOptions);
      }
export function useSearchRefereesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchRefereesQuery, SearchRefereesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchRefereesQuery, SearchRefereesQueryVariables>(SearchRefereesDocument, baseOptions);
        }
export type SearchRefereesQueryHookResult = ReturnType<typeof useSearchRefereesQuery>;
export type SearchRefereesLazyQueryHookResult = ReturnType<typeof useSearchRefereesLazyQuery>;
export type SearchRefereesQueryResult = ApolloReactCommon.QueryResult<SearchRefereesQuery, SearchRefereesQueryVariables>;
export function refetchSearchRefereesQuery(variables?: SearchRefereesQueryVariables) {
      return { query: SearchRefereesDocument, variables: variables }
    }
export const SearchTraineesDocument = gql`
    query SearchTrainees($search: String!, $trainingId: ID!) {
  searchTrainees(search: $search, trainingId: $trainingId) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useSearchTraineesQuery__
 *
 * To run a query within a React component, call `useSearchTraineesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTraineesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTraineesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useSearchTraineesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchTraineesQuery, SearchTraineesQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchTraineesQuery, SearchTraineesQueryVariables>(SearchTraineesDocument, baseOptions);
      }
export function useSearchTraineesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchTraineesQuery, SearchTraineesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchTraineesQuery, SearchTraineesQueryVariables>(SearchTraineesDocument, baseOptions);
        }
export type SearchTraineesQueryHookResult = ReturnType<typeof useSearchTraineesQuery>;
export type SearchTraineesLazyQueryHookResult = ReturnType<typeof useSearchTraineesLazyQuery>;
export type SearchTraineesQueryResult = ApolloReactCommon.QueryResult<SearchTraineesQuery, SearchTraineesQueryVariables>;
export function refetchSearchTraineesQuery(variables?: SearchTraineesQueryVariables) {
      return { query: SearchTraineesDocument, variables: variables }
    }
export const SearchTrainersDocument = gql`
    query SearchTrainers($search: String!, $trainingId: ID!) {
  searchTrainers(search: $search, trainingId: $trainingId) {
    id
    firstName
    lastName
    address
    zipCode
    city
    dateOfBirth
    department {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchTrainersQuery__
 *
 * To run a query within a React component, call `useSearchTrainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTrainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTrainersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useSearchTrainersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchTrainersQuery, SearchTrainersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchTrainersQuery, SearchTrainersQueryVariables>(SearchTrainersDocument, baseOptions);
      }
export function useSearchTrainersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchTrainersQuery, SearchTrainersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchTrainersQuery, SearchTrainersQueryVariables>(SearchTrainersDocument, baseOptions);
        }
export type SearchTrainersQueryHookResult = ReturnType<typeof useSearchTrainersQuery>;
export type SearchTrainersLazyQueryHookResult = ReturnType<typeof useSearchTrainersLazyQuery>;
export type SearchTrainersQueryResult = ApolloReactCommon.QueryResult<SearchTrainersQuery, SearchTrainersQueryVariables>;
export function refetchSearchTrainersQuery(variables?: SearchTrainersQueryVariables) {
      return { query: SearchTrainersDocument, variables: variables }
    }
export const SearchVolunteersDocument = gql`
    query SearchVolunteers($search: String!, $matchId: ID!) {
  searchVolunteers(search: $search, matchId: $matchId) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useSearchVolunteersQuery__
 *
 * To run a query within a React component, call `useSearchVolunteersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVolunteersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVolunteersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useSearchVolunteersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchVolunteersQuery, SearchVolunteersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchVolunteersQuery, SearchVolunteersQueryVariables>(SearchVolunteersDocument, baseOptions);
      }
export function useSearchVolunteersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchVolunteersQuery, SearchVolunteersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchVolunteersQuery, SearchVolunteersQueryVariables>(SearchVolunteersDocument, baseOptions);
        }
export type SearchVolunteersQueryHookResult = ReturnType<typeof useSearchVolunteersQuery>;
export type SearchVolunteersLazyQueryHookResult = ReturnType<typeof useSearchVolunteersLazyQuery>;
export type SearchVolunteersQueryResult = ApolloReactCommon.QueryResult<SearchVolunteersQuery, SearchVolunteersQueryVariables>;
export function refetchSearchVolunteersQuery(variables?: SearchVolunteersQueryVariables) {
      return { query: SearchVolunteersDocument, variables: variables }
    }
export const TraineeDocument = gql`
    query Trainee($traineeId: ID!) {
  trainee(traineeId: $traineeId) {
    id
    lastName
    firstName
    email
    phone
    category {
      id
      name
    }
    enabled
  }
}
    `;

/**
 * __useTraineeQuery__
 *
 * To run a query within a React component, call `useTraineeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineeQuery({
 *   variables: {
 *      traineeId: // value for 'traineeId'
 *   },
 * });
 */
export function useTraineeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TraineeQuery, TraineeQueryVariables>) {
        return ApolloReactHooks.useQuery<TraineeQuery, TraineeQueryVariables>(TraineeDocument, baseOptions);
      }
export function useTraineeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TraineeQuery, TraineeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TraineeQuery, TraineeQueryVariables>(TraineeDocument, baseOptions);
        }
export type TraineeQueryHookResult = ReturnType<typeof useTraineeQuery>;
export type TraineeLazyQueryHookResult = ReturnType<typeof useTraineeLazyQuery>;
export type TraineeQueryResult = ApolloReactCommon.QueryResult<TraineeQuery, TraineeQueryVariables>;
export function refetchTraineeQuery(variables?: TraineeQueryVariables) {
      return { query: TraineeDocument, variables: variables }
    }
export const TraineeCategoriesDocument = gql`
    query TraineeCategories {
  traineeCategories {
    id
    name
  }
}
    `;

/**
 * __useTraineeCategoriesQuery__
 *
 * To run a query within a React component, call `useTraineeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTraineeCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TraineeCategoriesQuery, TraineeCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<TraineeCategoriesQuery, TraineeCategoriesQueryVariables>(TraineeCategoriesDocument, baseOptions);
      }
export function useTraineeCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TraineeCategoriesQuery, TraineeCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TraineeCategoriesQuery, TraineeCategoriesQueryVariables>(TraineeCategoriesDocument, baseOptions);
        }
export type TraineeCategoriesQueryHookResult = ReturnType<typeof useTraineeCategoriesQuery>;
export type TraineeCategoriesLazyQueryHookResult = ReturnType<typeof useTraineeCategoriesLazyQuery>;
export type TraineeCategoriesQueryResult = ApolloReactCommon.QueryResult<TraineeCategoriesQuery, TraineeCategoriesQueryVariables>;
export function refetchTraineeCategoriesQuery(variables?: TraineeCategoriesQueryVariables) {
      return { query: TraineeCategoriesDocument, variables: variables }
    }
export const TraineeRequestDocument = gql`
    query TraineeRequest($token: String!) {
  traineeRequest(token: $token) {
    id
    responded
    trainee {
      id
      lastName
      firstName
    }
    message {
      message
      id
    }
    requestTrainings {
      id
      training {
        address
        city
        endDate
        eveHotel
        id
        level
        startDate
        title
        zipCode
        informations
        department {
          id
          name
        }
        league {
          id
          name
        }
        trainingDates {
          date
          id
          lunch
          startHour
          hotel
          endHour
          dinner
        }
      }
      lastResponse {
        accepted
        createdAt
        eveHotel
        id
        informations
        trainingDateResponses {
          trainingDate {
            id
          }
          dinner
          hotel
          id
          informations
          lunch
        }
      }
    }
  }
}
    `;

/**
 * __useTraineeRequestQuery__
 *
 * To run a query within a React component, call `useTraineeRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineeRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineeRequestQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTraineeRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TraineeRequestQuery, TraineeRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<TraineeRequestQuery, TraineeRequestQueryVariables>(TraineeRequestDocument, baseOptions);
      }
export function useTraineeRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TraineeRequestQuery, TraineeRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TraineeRequestQuery, TraineeRequestQueryVariables>(TraineeRequestDocument, baseOptions);
        }
export type TraineeRequestQueryHookResult = ReturnType<typeof useTraineeRequestQuery>;
export type TraineeRequestLazyQueryHookResult = ReturnType<typeof useTraineeRequestLazyQuery>;
export type TraineeRequestQueryResult = ApolloReactCommon.QueryResult<TraineeRequestQuery, TraineeRequestQueryVariables>;
export function refetchTraineeRequestQuery(variables?: TraineeRequestQueryVariables) {
      return { query: TraineeRequestDocument, variables: variables }
    }
export const TraineesDocument = gql`
    query Trainees {
  trainees {
    id
    lastName
    firstName
    email
    phone
    category {
      id
      name
    }
    enabled
  }
}
    `;

/**
 * __useTraineesQuery__
 *
 * To run a query within a React component, call `useTraineesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTraineesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TraineesQuery, TraineesQueryVariables>) {
        return ApolloReactHooks.useQuery<TraineesQuery, TraineesQueryVariables>(TraineesDocument, baseOptions);
      }
export function useTraineesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TraineesQuery, TraineesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TraineesQuery, TraineesQueryVariables>(TraineesDocument, baseOptions);
        }
export type TraineesQueryHookResult = ReturnType<typeof useTraineesQuery>;
export type TraineesLazyQueryHookResult = ReturnType<typeof useTraineesLazyQuery>;
export type TraineesQueryResult = ApolloReactCommon.QueryResult<TraineesQuery, TraineesQueryVariables>;
export function refetchTraineesQuery(variables?: TraineesQueryVariables) {
      return { query: TraineesDocument, variables: variables }
    }
export const TraineesStatsDocument = gql`
    query TraineesStats($startDate: String!, $endDate: String!, $departmentId: ID) {
  traineesForStats(departmentId: $departmentId) {
    id
    lastName
    firstName
    trainings(startDate: $startDate, endDate: $endDate, departmentId: $departmentId) {
      id
      training {
        title
        endDate
        city
        address
        id
        zipCode
        startDate
        eveHotel
        trainingDates {
          id
          date
          dinner
          endHour
          hotel
          lunch
          startHour
        }
        prices {
          id
          pricePerKm
          pricePerLunch
          pricePerDinner
          pricePerHotel
        }
      }
      informations
      eveHotel
      trainingDateTrainees {
        id
        informations
        hotel
        dinner
        lunch
        role
        trainingDate {
          id
          startHour
          endHour
          date
        }
      }
    }
    totalInvitationCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
    invitationWithoutResponseCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
  }
}
    `;

/**
 * __useTraineesStatsQuery__
 *
 * To run a query within a React component, call `useTraineesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineesStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useTraineesStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TraineesStatsQuery, TraineesStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<TraineesStatsQuery, TraineesStatsQueryVariables>(TraineesStatsDocument, baseOptions);
      }
export function useTraineesStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TraineesStatsQuery, TraineesStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TraineesStatsQuery, TraineesStatsQueryVariables>(TraineesStatsDocument, baseOptions);
        }
export type TraineesStatsQueryHookResult = ReturnType<typeof useTraineesStatsQuery>;
export type TraineesStatsLazyQueryHookResult = ReturnType<typeof useTraineesStatsLazyQuery>;
export type TraineesStatsQueryResult = ApolloReactCommon.QueryResult<TraineesStatsQuery, TraineesStatsQueryVariables>;
export function refetchTraineesStatsQuery(variables?: TraineesStatsQueryVariables) {
      return { query: TraineesStatsDocument, variables: variables }
    }
export const TrainerDocument = gql`
    query Trainer($trainerId: ID!) {
  trainer(trainerId: $trainerId) {
    id
    lastName
    firstName
    league {
      id
      name
    }
    department {
      id
      name
    }
    licence
    mobilePhone
    phone
    zipCode
    email
    dateOfBirth
    clubNumber
    clubName
    city
    address
  }
}
    `;

/**
 * __useTrainerQuery__
 *
 * To run a query within a React component, call `useTrainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerQuery({
 *   variables: {
 *      trainerId: // value for 'trainerId'
 *   },
 * });
 */
export function useTrainerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainerQuery, TrainerQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainerQuery, TrainerQueryVariables>(TrainerDocument, baseOptions);
      }
export function useTrainerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainerQuery, TrainerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainerQuery, TrainerQueryVariables>(TrainerDocument, baseOptions);
        }
export type TrainerQueryHookResult = ReturnType<typeof useTrainerQuery>;
export type TrainerLazyQueryHookResult = ReturnType<typeof useTrainerLazyQuery>;
export type TrainerQueryResult = ApolloReactCommon.QueryResult<TrainerQuery, TrainerQueryVariables>;
export function refetchTrainerQuery(variables?: TrainerQueryVariables) {
      return { query: TrainerDocument, variables: variables }
    }
export const TrainerRequestDocument = gql`
    query TrainerRequest($token: String!) {
  trainerRequest(token: $token) {
    id
    responded
    trainer {
      id
      lastName
      firstName
      address
      zipCode
      city
    }
    message {
      message
      id
    }
    requestTrainings {
      id
      training {
        address
        city
        endDate
        eveHotel
        id
        level
        startDate
        title
        zipCode
        lat
        lng
        informations
        prices {
          id
          pricePerKm
        }
        trainingDates {
          date
          id
          lunch
          startHour
          hotel
          endHour
          dinner
        }
      }
      trainingTrainerRoute {
        id
        from
        to
      }
      lastResponse {
        accepted
        createdAt
        eveHotel
        noKmsFees
        freeHosting
        id
        informations
        trainingDateResponses {
          trainingDate {
            id
          }
          dinner
          hotel
          id
          informations
          lunch
        }
      }
    }
  }
}
    `;

/**
 * __useTrainerRequestQuery__
 *
 * To run a query within a React component, call `useTrainerRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerRequestQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTrainerRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainerRequestQuery, TrainerRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainerRequestQuery, TrainerRequestQueryVariables>(TrainerRequestDocument, baseOptions);
      }
export function useTrainerRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainerRequestQuery, TrainerRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainerRequestQuery, TrainerRequestQueryVariables>(TrainerRequestDocument, baseOptions);
        }
export type TrainerRequestQueryHookResult = ReturnType<typeof useTrainerRequestQuery>;
export type TrainerRequestLazyQueryHookResult = ReturnType<typeof useTrainerRequestLazyQuery>;
export type TrainerRequestQueryResult = ApolloReactCommon.QueryResult<TrainerRequestQuery, TrainerRequestQueryVariables>;
export function refetchTrainerRequestQuery(variables?: TrainerRequestQueryVariables) {
      return { query: TrainerRequestDocument, variables: variables }
    }
export const TrainerTrainingsDocument = gql`
    query TrainerTrainings($trainerId: ID!, $startDate: String!, $endDate: String!, $departmentId: ID) {
  trainer(trainerId: $trainerId) {
    id
    trainings(startDate: $startDate, endDate: $endDate, departmentId: $departmentId) {
      id
      training {
        title
        endDate
        city
        address
        id
        zipCode
        startDate
        eveHotel
        trainingDates {
          id
          date
          dinner
          endHour
          hotel
          lunch
          startHour
        }
        prices {
          id
          pricePerKm
          pricePerLunch
          pricePerDinner
          pricePerHotel
        }
      }
      informations
      eveHotel
      noKmsFees
      freeHosting
      trainingDateTrainers {
        id
        informations
        hotel
        dinner
        lunch
        role
        trainingDate {
          id
          startHour
          endHour
          date
        }
      }
      trainingTrainerRoute {
        id
        from
        to
      }
    }
  }
}
    `;

/**
 * __useTrainerTrainingsQuery__
 *
 * To run a query within a React component, call `useTrainerTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerTrainingsQuery({
 *   variables: {
 *      trainerId: // value for 'trainerId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useTrainerTrainingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainerTrainingsQuery, TrainerTrainingsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainerTrainingsQuery, TrainerTrainingsQueryVariables>(TrainerTrainingsDocument, baseOptions);
      }
export function useTrainerTrainingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainerTrainingsQuery, TrainerTrainingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainerTrainingsQuery, TrainerTrainingsQueryVariables>(TrainerTrainingsDocument, baseOptions);
        }
export type TrainerTrainingsQueryHookResult = ReturnType<typeof useTrainerTrainingsQuery>;
export type TrainerTrainingsLazyQueryHookResult = ReturnType<typeof useTrainerTrainingsLazyQuery>;
export type TrainerTrainingsQueryResult = ApolloReactCommon.QueryResult<TrainerTrainingsQuery, TrainerTrainingsQueryVariables>;
export function refetchTrainerTrainingsQuery(variables?: TrainerTrainingsQueryVariables) {
      return { query: TrainerTrainingsDocument, variables: variables }
    }
export const TrainersDocument = gql`
    query Trainers {
  trainers {
    id
    lastName
    firstName
    enabled
    league {
      id
      name
    }
    department {
      id
      name
    }
    licence
    mobilePhone
    phone
    zipCode
    email
    dateOfBirth
    clubNumber
    clubName
    city
    address
  }
}
    `;

/**
 * __useTrainersQuery__
 *
 * To run a query within a React component, call `useTrainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersQuery, TrainersQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainersQuery, TrainersQueryVariables>(TrainersDocument, baseOptions);
      }
export function useTrainersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersQuery, TrainersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainersQuery, TrainersQueryVariables>(TrainersDocument, baseOptions);
        }
export type TrainersQueryHookResult = ReturnType<typeof useTrainersQuery>;
export type TrainersLazyQueryHookResult = ReturnType<typeof useTrainersLazyQuery>;
export type TrainersQueryResult = ApolloReactCommon.QueryResult<TrainersQuery, TrainersQueryVariables>;
export function refetchTrainersQuery(variables?: TrainersQueryVariables) {
      return { query: TrainersDocument, variables: variables }
    }
export const TrainersStatsDocument = gql`
    query TrainersStats($startDate: String!, $endDate: String!, $departmentId: ID) {
  trainersForStats(departmentId: $departmentId) {
    id
    lastName
    firstName
    trainings(startDate: $startDate, endDate: $endDate, departmentId: $departmentId) {
      id
      training {
        title
        endDate
        city
        address
        id
        zipCode
        startDate
        eveHotel
        trainingDates {
          id
          date
          dinner
          endHour
          hotel
          lunch
          startHour
        }
        prices {
          id
          pricePerKm
          pricePerLunch
          pricePerDinner
          pricePerHotel
        }
      }
      informations
      eveHotel
      noKmsFees
      freeHosting
      trainingDateTrainers {
        id
        informations
        hotel
        dinner
        lunch
        role
        trainingDate {
          id
          startHour
          endHour
          date
        }
      }
      trainingTrainerRoute {
        id
        from
        to
      }
    }
    totalInvitationCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
    invitationWithoutResponseCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
  }
}
    `;

/**
 * __useTrainersStatsQuery__
 *
 * To run a query within a React component, call `useTrainersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainersStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useTrainersStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainersStatsQuery, TrainersStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainersStatsQuery, TrainersStatsQueryVariables>(TrainersStatsDocument, baseOptions);
      }
export function useTrainersStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainersStatsQuery, TrainersStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainersStatsQuery, TrainersStatsQueryVariables>(TrainersStatsDocument, baseOptions);
        }
export type TrainersStatsQueryHookResult = ReturnType<typeof useTrainersStatsQuery>;
export type TrainersStatsLazyQueryHookResult = ReturnType<typeof useTrainersStatsLazyQuery>;
export type TrainersStatsQueryResult = ApolloReactCommon.QueryResult<TrainersStatsQuery, TrainersStatsQueryVariables>;
export function refetchTrainersStatsQuery(variables?: TrainersStatsQueryVariables) {
      return { query: TrainersStatsDocument, variables: variables }
    }
export const TrainingResponsesDocument = gql`
    query TrainingResponses($trainingId: ID!) {
  training(trainingId: $trainingId) {
    eveHotel
    id
    trainingDates {
      id
      requiredCount
      startHour
      lunch
      hotel
      endHour
      dinner
      date
    }
    trainersRequest {
      id
      trainer {
        id
        firstName
        lastName
        address
        zipCode
        city
        dateOfBirth
      }
      createdAt
      lastResponse {
        id
        informations
        eveHotel
        noKmsFees
        freeHosting
        createdAt
        accepted
        trainingTrainerRoute {
          id
          from
          to
        }
        trainingDateResponses {
          id
          informations
          lunch
          hotel
          dinner
          trainingDate {
            id
            date
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTrainingResponsesQuery__
 *
 * To run a query within a React component, call `useTrainingResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingResponsesQuery({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useTrainingResponsesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingResponsesQuery, TrainingResponsesQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingResponsesQuery, TrainingResponsesQueryVariables>(TrainingResponsesDocument, baseOptions);
      }
export function useTrainingResponsesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingResponsesQuery, TrainingResponsesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingResponsesQuery, TrainingResponsesQueryVariables>(TrainingResponsesDocument, baseOptions);
        }
export type TrainingResponsesQueryHookResult = ReturnType<typeof useTrainingResponsesQuery>;
export type TrainingResponsesLazyQueryHookResult = ReturnType<typeof useTrainingResponsesLazyQuery>;
export type TrainingResponsesQueryResult = ApolloReactCommon.QueryResult<TrainingResponsesQuery, TrainingResponsesQueryVariables>;
export function refetchTrainingResponsesQuery(variables?: TrainingResponsesQueryVariables) {
      return { query: TrainingResponsesDocument, variables: variables }
    }
export const TrainingResumeDocument = gql`
    query TrainingResume($trainingId: ID!) {
  training(trainingId: $trainingId) {
    address
    city
    endDate
    eveHotel
    id
    level
    startDate
    title
    zipCode
    lat
    lng
    informations
    department {
      id
    }
    trainingDates {
      id
      requiredCount
      startHour
      lunch
      hotel
      endHour
      dinner
      date
    }
    prices {
      id
      pricePerKm
      pricePerLunch
      pricePerDinner
      pricePerHotel
    }
    trainingTrainers {
      eveHotel
      noKmsFees
      freeHosting
      id
      informations
      trainer {
        id
        firstName
        lastName
        address
        zipCode
        city
        dateOfBirth
      }
      trainingTrainerRoute {
        id
        to
        from
      }
      trainingDateTrainers {
        id
        trainingDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
    }
    trainingTrainees {
      eveHotel
      id
      informations
      trainee {
        id
        firstName
        lastName
        category {
          id
          name
        }
      }
      trainingDateTrainees {
        id
        trainingDate {
          id
          date
        }
        lunch
        informations
        hotel
        dinner
        role
      }
    }
  }
}
    `;

/**
 * __useTrainingResumeQuery__
 *
 * To run a query within a React component, call `useTrainingResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingResumeQuery({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useTrainingResumeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingResumeQuery, TrainingResumeQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingResumeQuery, TrainingResumeQueryVariables>(TrainingResumeDocument, baseOptions);
      }
export function useTrainingResumeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingResumeQuery, TrainingResumeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingResumeQuery, TrainingResumeQueryVariables>(TrainingResumeDocument, baseOptions);
        }
export type TrainingResumeQueryHookResult = ReturnType<typeof useTrainingResumeQuery>;
export type TrainingResumeLazyQueryHookResult = ReturnType<typeof useTrainingResumeLazyQuery>;
export type TrainingResumeQueryResult = ApolloReactCommon.QueryResult<TrainingResumeQuery, TrainingResumeQueryVariables>;
export function refetchTrainingResumeQuery(variables?: TrainingResumeQueryVariables) {
      return { query: TrainingResumeDocument, variables: variables }
    }
export const TrainingTraineeResponsesDocument = gql`
    query TrainingTraineeResponses($trainingId: ID!) {
  training(trainingId: $trainingId) {
    eveHotel
    id
    trainingDates {
      id
      requiredCount
      startHour
      lunch
      hotel
      endHour
      dinner
      date
    }
    traineesRequest {
      id
      trainee {
        id
        firstName
        lastName
        category {
          id
          name
        }
      }
      createdAt
      lastResponse {
        id
        informations
        eveHotel
        createdAt
        accepted
        trainingDateResponses {
          id
          informations
          lunch
          hotel
          dinner
          trainingDate {
            id
            date
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTrainingTraineeResponsesQuery__
 *
 * To run a query within a React component, call `useTrainingTraineeResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingTraineeResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingTraineeResponsesQuery({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useTrainingTraineeResponsesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingTraineeResponsesQuery, TrainingTraineeResponsesQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingTraineeResponsesQuery, TrainingTraineeResponsesQueryVariables>(TrainingTraineeResponsesDocument, baseOptions);
      }
export function useTrainingTraineeResponsesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingTraineeResponsesQuery, TrainingTraineeResponsesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingTraineeResponsesQuery, TrainingTraineeResponsesQueryVariables>(TrainingTraineeResponsesDocument, baseOptions);
        }
export type TrainingTraineeResponsesQueryHookResult = ReturnType<typeof useTrainingTraineeResponsesQuery>;
export type TrainingTraineeResponsesLazyQueryHookResult = ReturnType<typeof useTrainingTraineeResponsesLazyQuery>;
export type TrainingTraineeResponsesQueryResult = ApolloReactCommon.QueryResult<TrainingTraineeResponsesQuery, TrainingTraineeResponsesQueryVariables>;
export function refetchTrainingTraineeResponsesQuery(variables?: TrainingTraineeResponsesQueryVariables) {
      return { query: TrainingTraineeResponsesDocument, variables: variables }
    }
export const TrainingsDocument = gql`
    query Trainings($year: Int!, $departmentId: ID) {
  trainings(year: $year, departmentId: $departmentId) {
    address
    city
    createdAt
    endDate
    id
    level
    startDate
    title
    zipCode
  }
}
    `;

/**
 * __useTrainingsQuery__
 *
 * To run a query within a React component, call `useTrainingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useTrainingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingsQuery, TrainingsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingsQuery, TrainingsQueryVariables>(TrainingsDocument, baseOptions);
      }
export function useTrainingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingsQuery, TrainingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingsQuery, TrainingsQueryVariables>(TrainingsDocument, baseOptions);
        }
export type TrainingsQueryHookResult = ReturnType<typeof useTrainingsQuery>;
export type TrainingsLazyQueryHookResult = ReturnType<typeof useTrainingsLazyQuery>;
export type TrainingsQueryResult = ApolloReactCommon.QueryResult<TrainingsQuery, TrainingsQueryVariables>;
export function refetchTrainingsQuery(variables?: TrainingsQueryVariables) {
      return { query: TrainingsDocument, variables: variables }
    }
export const TrainingsForInvitationDocument = gql`
    query TrainingsForInvitation($trainingIds: [ID!]) {
  trainingsByIds(trainingIds: $trainingIds) {
    address
    city
    endDate
    eveHotel
    id
    informations
    trainingDates {
      date
      dinner
      endHour
      hotel
      id
      lunch
      startHour
    }
    startDate
    title
    zipCode
  }
}
    `;

/**
 * __useTrainingsForInvitationQuery__
 *
 * To run a query within a React component, call `useTrainingsForInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingsForInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingsForInvitationQuery({
 *   variables: {
 *      trainingIds: // value for 'trainingIds'
 *   },
 * });
 */
export function useTrainingsForInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingsForInvitationQuery, TrainingsForInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingsForInvitationQuery, TrainingsForInvitationQueryVariables>(TrainingsForInvitationDocument, baseOptions);
      }
export function useTrainingsForInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingsForInvitationQuery, TrainingsForInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingsForInvitationQuery, TrainingsForInvitationQueryVariables>(TrainingsForInvitationDocument, baseOptions);
        }
export type TrainingsForInvitationQueryHookResult = ReturnType<typeof useTrainingsForInvitationQuery>;
export type TrainingsForInvitationLazyQueryHookResult = ReturnType<typeof useTrainingsForInvitationLazyQuery>;
export type TrainingsForInvitationQueryResult = ApolloReactCommon.QueryResult<TrainingsForInvitationQuery, TrainingsForInvitationQueryVariables>;
export function refetchTrainingsForInvitationQuery(variables?: TrainingsForInvitationQueryVariables) {
      return { query: TrainingsForInvitationDocument, variables: variables }
    }
export const UserDocument = gql`
    query User($userId: ID!) {
  user(userId: $userId) {
    email
    firstName
    id
    lastName
    department {
      name
      id
    }
    permissions
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export function refetchUserQuery(variables?: UserQueryVariables) {
      return { query: UserDocument, variables: variables }
    }
export const UsersDocument = gql`
    query Users {
  users {
    email
    firstName
    id
    lastName
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export function refetchUsersQuery(variables?: UsersQueryVariables) {
      return { query: UsersDocument, variables: variables }
    }
export const VolunteerDocument = gql`
    query Volunteer($volunteerId: ID!) {
  volunteer(volunteerId: $volunteerId) {
    id
    lastName
    firstName
    email
    phone
    enabled
  }
}
    `;

/**
 * __useVolunteerQuery__
 *
 * To run a query within a React component, call `useVolunteerQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolunteerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolunteerQuery({
 *   variables: {
 *      volunteerId: // value for 'volunteerId'
 *   },
 * });
 */
export function useVolunteerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VolunteerQuery, VolunteerQueryVariables>) {
        return ApolloReactHooks.useQuery<VolunteerQuery, VolunteerQueryVariables>(VolunteerDocument, baseOptions);
      }
export function useVolunteerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VolunteerQuery, VolunteerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VolunteerQuery, VolunteerQueryVariables>(VolunteerDocument, baseOptions);
        }
export type VolunteerQueryHookResult = ReturnType<typeof useVolunteerQuery>;
export type VolunteerLazyQueryHookResult = ReturnType<typeof useVolunteerLazyQuery>;
export type VolunteerQueryResult = ApolloReactCommon.QueryResult<VolunteerQuery, VolunteerQueryVariables>;
export function refetchVolunteerQuery(variables?: VolunteerQueryVariables) {
      return { query: VolunteerDocument, variables: variables }
    }
export const VolunteerRequestDocument = gql`
    query VolunteerRequest($token: String!) {
  volunteerRequest(token: $token) {
    id
    responded
    volunteer {
      id
      lastName
      firstName
    }
    message {
      message
      id
    }
    requestMatches {
      id
      match {
        address
        city
        endDate
        eveHotel
        id
        level
        startDate
        title
        zipCode
        informations
        department {
          id
          name
        }
        league {
          id
          name
        }
        matchDates {
          date
          id
          lunch
          startHour
          hotel
          endHour
          dinner
        }
      }
      lastResponse {
        accepted
        createdAt
        eveHotel
        id
        informations
        matchDateResponses {
          matchDate {
            id
          }
          dinner
          hotel
          id
          informations
          lunch
        }
      }
    }
  }
}
    `;

/**
 * __useVolunteerRequestQuery__
 *
 * To run a query within a React component, call `useVolunteerRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolunteerRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolunteerRequestQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVolunteerRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VolunteerRequestQuery, VolunteerRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<VolunteerRequestQuery, VolunteerRequestQueryVariables>(VolunteerRequestDocument, baseOptions);
      }
export function useVolunteerRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VolunteerRequestQuery, VolunteerRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VolunteerRequestQuery, VolunteerRequestQueryVariables>(VolunteerRequestDocument, baseOptions);
        }
export type VolunteerRequestQueryHookResult = ReturnType<typeof useVolunteerRequestQuery>;
export type VolunteerRequestLazyQueryHookResult = ReturnType<typeof useVolunteerRequestLazyQuery>;
export type VolunteerRequestQueryResult = ApolloReactCommon.QueryResult<VolunteerRequestQuery, VolunteerRequestQueryVariables>;
export function refetchVolunteerRequestQuery(variables?: VolunteerRequestQueryVariables) {
      return { query: VolunteerRequestDocument, variables: variables }
    }
export const VolunteersDocument = gql`
    query Volunteers {
  volunteers {
    id
    lastName
    firstName
    email
    phone
    enabled
  }
}
    `;

/**
 * __useVolunteersQuery__
 *
 * To run a query within a React component, call `useVolunteersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolunteersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolunteersQuery({
 *   variables: {
 *   },
 * });
 */
export function useVolunteersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VolunteersQuery, VolunteersQueryVariables>) {
        return ApolloReactHooks.useQuery<VolunteersQuery, VolunteersQueryVariables>(VolunteersDocument, baseOptions);
      }
export function useVolunteersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VolunteersQuery, VolunteersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VolunteersQuery, VolunteersQueryVariables>(VolunteersDocument, baseOptions);
        }
export type VolunteersQueryHookResult = ReturnType<typeof useVolunteersQuery>;
export type VolunteersLazyQueryHookResult = ReturnType<typeof useVolunteersLazyQuery>;
export type VolunteersQueryResult = ApolloReactCommon.QueryResult<VolunteersQuery, VolunteersQueryVariables>;
export function refetchVolunteersQuery(variables?: VolunteersQueryVariables) {
      return { query: VolunteersDocument, variables: variables }
    }
export const VolunteersStatsDocument = gql`
    query VolunteersStats($startDate: String!, $endDate: String!, $departmentId: ID) {
  volunteersForStats(departmentId: $departmentId) {
    id
    lastName
    firstName
    matches(startDate: $startDate, endDate: $endDate, departmentId: $departmentId) {
      id
      match {
        title
        endDate
        city
        address
        id
        zipCode
        startDate
        eveHotel
        matchDates {
          id
          date
          dinner
          endHour
          hotel
          lunch
          startHour
        }
        prices {
          id
          pricePerKm
          pricePerLunch
          pricePerDinner
          pricePerHotel
        }
      }
      informations
      eveHotel
      matchDateVolunteers {
        id
        informations
        hotel
        dinner
        lunch
        role
        matchDate {
          id
          startHour
          endHour
          date
        }
      }
    }
    totalInvitationCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
    invitationWithoutResponseCount(startDate: $startDate, endDate: $endDate, departmentId: $departmentId)
  }
}
    `;

/**
 * __useVolunteersStatsQuery__
 *
 * To run a query within a React component, call `useVolunteersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolunteersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolunteersStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useVolunteersStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VolunteersStatsQuery, VolunteersStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<VolunteersStatsQuery, VolunteersStatsQueryVariables>(VolunteersStatsDocument, baseOptions);
      }
export function useVolunteersStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VolunteersStatsQuery, VolunteersStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VolunteersStatsQuery, VolunteersStatsQueryVariables>(VolunteersStatsDocument, baseOptions);
        }
export type VolunteersStatsQueryHookResult = ReturnType<typeof useVolunteersStatsQuery>;
export type VolunteersStatsLazyQueryHookResult = ReturnType<typeof useVolunteersStatsLazyQuery>;
export type VolunteersStatsQueryResult = ApolloReactCommon.QueryResult<VolunteersStatsQuery, VolunteersStatsQueryVariables>;
export function refetchVolunteersStatsQuery(variables?: VolunteersStatsQueryVariables) {
      return { query: VolunteersStatsDocument, variables: variables }
    }