import { InMemoryCache } from 'apollo-cache-inmemory';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const cache = new InMemoryCache({
    addTypename: true,
    freezeResults: true,
    /*cacheRedirects: {
        Query: {
            referee: (_, { refereeId }, { getCacheKey }) => {
                return getCacheKey({ __typename: 'Referee', id: refereeId })
            }
        }
    }*/
});
cache.writeData({
    data: {
        isLoggedIn: cookies.get('id') ? true : false
    }
});

export default cache;