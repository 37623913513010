import React from 'react';
import { FormControlLabel, Switch, Checkbox, Collapse, TextField, Box } from '@material-ui/core';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { TrainingDate } from 'generated/graphql';
import { TrainingFormData } from '.';

type TrainingDateFormData = {
    trainingDateId: string
    accepted: boolean
    lunch: boolean
    dinner: boolean
    hotel: boolean
    informations: string
}

type Props = {
    trainingDate: Pick<TrainingDate, "id" | "date" | "lunch" | "startHour" | "hotel" | "endHour" | "dinner">
    trainingResponses: Array<TrainingFormData>
    setTrainingResponses: React.Dispatch<React.SetStateAction<TrainingFormData[]>>
    indexTraining: number
    indexTrainingDate: number
}
const TrainingDateForm = ({ trainingDate, trainingResponses, setTrainingResponses, indexTraining, indexTrainingDate }: Props) => {
    const trainingDateResponse = trainingResponses[indexTraining].trainingDates[indexTrainingDate];

    const handleChangeChecked = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const lens = R.lensPath([indexTraining, 'trainingDates', indexTrainingDate, name]);
        setTrainingResponses(R.set(lens, e.target.checked, trainingResponses));
    }

    const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const lens = R.lensPath([indexTraining, 'trainingDates', indexTrainingDate, name]);
        setTrainingResponses(R.set(lens, e.target.value, trainingResponses));
    }

    return (
        <Box marginBottom={4}>
            <FormControlLabel
                control={
                    <Switch
                        checked={trainingDateResponse.accepted}
                        onChange={handleChangeChecked('accepted')}
                        color="primary"
                    />
                }
                label={DateTime.fromSQL(trainingDate.date).toLocaleString(DateTime.DATE_MED)}
            />
            <Collapse in={trainingDateResponse.accepted} timeout="auto" unmountOnExit>
                <Box paddingX={6}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={trainingDateResponse.lunch} disabled={!trainingDate.lunch}
                                onChange={handleChangeChecked('lunch')} />
                        }
                        label="Repas midi"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={trainingDateResponse.dinner} disabled={!trainingDate.dinner}
                                onChange={handleChangeChecked('dinner')} />
                        }
                        label="Repas soir"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={trainingDateResponse.hotel} disabled={!trainingDate.hotel}
                                onChange={handleChangeChecked('hotel')} />
                        }
                        label="Hotel"
                    />
                    <TextField fullWidth label="Informations pour ce jour uniquement" value={trainingDateResponse.informations} onChange={handleChange('informations')} />
                </Box>
            </Collapse>
        </Box>
    );
};

TrainingDateForm.whyDidYouRender = true

export default TrainingDateForm;
export type { TrainingDateFormData };