import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PasswordRecovery from './scenes/PasswordRecovery';
import EmailSent from './scenes/EmailSent';
import PasswordReset from './scenes/PasswordReset';

const Password = () => {
    return (
        <Switch>
            <Route exact path='/password/recovery'>
                <PasswordRecovery />
            </Route>
            <Route exact path='/password/emailSent'>
                <EmailSent />
            </Route>
            <Route exact path='/password/reset/:token'>
                <PasswordReset />
            </Route>
        </Switch>
    );
};

export default Password;